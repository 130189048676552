export const locationDataLocators = {
    location_landing_get_directions_cta: 'locationLandingPage_getDirectionsCTA',
    location_landing_store_name: 'locationLandingPage_storeName',
    location_landing_distance: 'locationLandingPage_distance',
    location_landing_static_text: 'locationLandingPage_staticText',
    location_landing_address: 'locationLandingPage_storeAddress',
    location_landing_email: 'locationLandingPage_storeContactEmail',
    location_landing_store_details: 'locationLandingPage_storeDetailsCTA',
    location_landing_show_more: 'locationLandingPage_showMoreCTA',
    location_landing_find_a_location: 'locationLandingPage_findALocationHeading',
    location_landing_location_search_box: 'locationLandingPage_locationSearchBox',
    location_landing_search_icon: 'locationLandingPage_searchIcon',
    location_landing_filter_text: 'locationLandingPage_filterText',
    location_landing_filter_icon: 'locationLandingPage_filterIcon',
    location_landing_current_location_cta: 'locationLandingPage_currentLocationCTA',
    location_landing_current_we_will_deliver_text: 'locationLandingPage_weWillDeliver_staticCopy',
    location_landing_current_call_us_text: 'locationLandingPage_pleaseCallUs_staticCopy',
    location_map_marker: 'location_map_marker',
    location_trainer_card: 'location_trainer_card',
    location_trainer_card_email: 'location_trainer_card_email',
    location_trainer_card_phone: 'location_trainer_card_phone',
    location_trainer_card_get_directions: 'location_trainer_card_get_directions',
    location_trainer_card_view_more: 'location_trainer_card_view_more',
    location_landing_card:'location_landing_card',
    location_landing_search_by_branch_text_box:'location_landing_search_by_branch_text_box',
    location_landing_search_by_branch_btn:'location_landing_search_by_branch_btn',
};
