import React, { useEffect, useRef, useState } from 'react';
import { array, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { LoadingIndicator } from '../../../aem-core-components';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { SET_ALL_STORES, SET_NUMBER_OF_FILTERS, SET_STORE_LOCATIONS } from '../../../contexts/location/actions';
import { useLocationState } from '../../../contexts/location/locationContext';
import useMedia from '../../../hooks/useMedia';
import InfoIcon24 from '../../../resources/images/info24.svg';
import FilterBody from '../../global/modules/globalCheckboxFilters/FilterBody';
import FilterFooter from '../../global/modules/globalCheckboxFilters/FilterFooter';
import FilterHeader from '../../global/modules/globalCheckboxFilters/FilterHeader';
import GlobalModal from '../../global/atoms/globalModal/GlobalModal';
import './filters.scss';

const Filters = props => {
    const [{ isLoading }] = useCartState();
    const {
        modalIsOpen,
        setModalIsOpen,
        data,
        setFilterData,
        title,
        filterTitleClass,
        filterBodyClasess,
        setClickedOption,
        clearAll = false,
        filterBodyRef,
        subfiltertitle,
        initFilterData,
        selectedOptions,
        filterOptionsObj,
        setIsNoStoreAvailable
    } = props;
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [popUpModalData, setPopUpModalData] = useState(false);
    const [isDescriptionPopUpOpen, setIsDescriptionPopUpOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [localFilterData, setLocalFilterData] = useState(initFilterData());
    const [{ storeLocationsBeforeFilters, allStoreLocationsBeforeFilters }, dispatch] = useLocationState();
    const customStyle = { overlay: { background: 'rgba(0,0,0,0.4)' }, content: { padding: '0px' } };
    const mobileFilterRef = useRef(null);
    const handleIsSticky = e => {
        const scrollTop = mobileFilterRef.current.scrollTop;
        scrollTop >= 10 ? setIsSticky(true) : setIsSticky(false);
    };
    const intl = useIntl();

    useEffect(() => {
        const filter = {};
        filter[subfiltertitle] = {
            selectedOptions: [...selectedOptions]
        };
        setLocalFilterData(filter);
    }, []);

    useEffect(() => {
        if (filterModalOpen) {
            mobileFilterRef.current?.addEventListener('scroll', handleIsSticky);
        } else {
            mobileFilterRef.current?.removeEventListener('scroll', handleIsSticky);
        }
    }, [filterModalOpen]);

    const setIsClearAll = () => {
        setFilterData(initFilterData());
        setLocalFilterData(initFilterData());
        if (storeLocationsBeforeFilters?.data?.pcList?.length > 0) {
            dispatch({
                type: SET_STORE_LOCATIONS,
                storeLocations: storeLocationsBeforeFilters
            });
        }
        dispatch({
            type: SET_ALL_STORES,
            allStoreLocations: allStoreLocationsBeforeFilters
        });
        dispatch({
            type: SET_NUMBER_OF_FILTERS,
            numberOfFilters: 0
        });
    };
    const afterOpenModal = () => {
        setFilterModalOpen(!filterModalOpen);
    };

    const handleModalClose = () => {
        setModalIsOpen(!modalIsOpen);
    };
    const filterLocationData = isAllStores => {
        let filteredStores;

        let unfilteredList = isAllStores
            ? allStoreLocationsBeforeFilters?.data?.pcList
            : storeLocationsBeforeFilters?.data?.pcList;

        let filterKeys = [];
        if (filterOptionsObj) {
            localFilterData?.[`${subfiltertitle}`]?.selectedOptions?.forEach(item => {
                if (filterOptionsObj[item]) {
                    filterKeys.push(...filterOptionsObj[item]?.searchKey);
                }
            });
        } else {
            filterKeys = localFilterData?.[`${subfiltertitle}`]?.selectedOptions;
        }
        filteredStores = unfilteredList?.map(item => {
            if (item?.pc >= 5000 && item?.pc <= 5099) {
                let newItem = item;
                newItem.specialties = ['Sunbelt Rentals Tool Rental'];
                return newItem;
            }
            return item;
        });

        filteredStores = filteredStores?.filter(item => {
            if (item?.specialties) {
                return filterKeys.includes(item?.specialties[0]?.trim());
            }
            return false;
        });

        if (localFilterData?.[`${subfiltertitle}`]?.selectedOptions.length === 0) {
            filteredStores = unfilteredList;
        }

        const data = {
            data: {
                pcList: filteredStores
            }
        };
        if (filteredStores?.length == 0) {
            setIsNoStoreAvailable(true);
        }
        if (isAllStores) {
            dispatch({
                type: SET_ALL_STORES,
                allStoreLocations: data
            });
        } else {
            dispatch({
                type: SET_STORE_LOCATIONS,
                storeLocations: data
            });
        }
    };
    const handleApplyFilters = () => {
        const filter = {};
        filter[subfiltertitle] = {
            selectedOptions: [...localFilterData?.[`${subfiltertitle}`]?.selectedOptions]
        };
        setFilterData(filter);
        if (storeLocationsBeforeFilters?.data?.pcList?.length > 0) {
            filterLocationData(false);
        } else {
            dispatch({
                type: SET_STORE_LOCATIONS,
                storeLocations: {}
            });
        }
        if (allStoreLocationsBeforeFilters?.data?.pcList?.length > 0) {
            filterLocationData(true);
        }
        dispatch({
            type: SET_NUMBER_OF_FILTERS,
            numberOfFilters: localFilterData?.[`${subfiltertitle}`]?.selectedOptions.length || 0
        });

        setModalIsOpen(!modalIsOpen);
    };
    const mediaType = useMedia();

    const handlePopUpModalClose = () => {
        setIsDescriptionPopUpOpen(false);
    };
    const handleInfoIconClick = option => {
        setPopUpModalData(option);
        setIsDescriptionPopUpOpen(true);
    };

    const handlePopUpModalContent = () => {
        const modalContent = popUpModalData?.specialtyDescription?.html;
        let learnMoreLink = popUpModalData?.learnMoreLink;
        learnMoreLink = learnMoreLink?.replace(/\/equipment-rental\/category-page\//, '/equipment-rental/'); // remove category page
        learnMoreLink = learnMoreLink?.replace(/\.html$/, '') + '/'; // remove .html from the end
        return (
            <>
                <div
                    dangerouslySetInnerHTML={{ __html: modalContent }}
                    tabIndex={'0'}
                    className="locationfiltermodal__content-in"
                />
                <a href={learnMoreLink} className="locationfiltermodal__link" tabIndex={'0'} target={"_blank"} aria-label={`${intl.formatMessage({ id: 'common:learn-more' })} ${intl.formatMessage({ id: 'location-info-about' })} ${popUpModalData?.name} ${intl.formatMessage({ id: 'open-new-window' })}`}>
                    {intl.formatMessage({ id: 'common:learn-more' })}
                </a>
            </>
        );
    };

    const handlePopUpModalTitle = () => {
        return (
            <>
                <InfoIcon24 aria-hidden="true" />
                <span>{popUpModalData?.name}</span>
            </>
        );
    };

    const renderFilter = isDesktop => {
        return (
            <>
                <FilterHeader
                    title={title}
                    filterTitleClass={`${filterTitleClass} mobile-filter__title ${
                        isSticky ? 'mobile-filter__title-sticky' : ''
                    }`}
                    onCloseHandler={handleModalClose}
                    showCloseButton={true}
                />

                <FilterBody
                    filterBodyClasess={filterBodyClasess}
                    data={data}
                    filterBodyRef={filterBodyRef}
                    setClickedOption={setClickedOption}
                    setIsClearAll={setIsClearAll}
                    clearAll={clearAll}
                    localFilterData={localFilterData}
                    setLocalFilterData={setLocalFilterData}
                    isShowMoreVisible={false}
                    arePillsVisible={false}
                    isListNested={false}
                    isDescriptionAvailable={true}
                    onInfoIconClick={handleInfoIconClick}
                />

                <FilterFooter
                    onClose={handleApplyFilters}
                    resultQty={localFilterData?.[`${subfiltertitle}`]?.selectedOptions?.length}
                />
            </>
        );
    };
    return (
        <>
            {mediaType !== MEDIA_TYPE.DESKTOP ? (
                <Modal
                    role="none"
                    isOpen={modalIsOpen}
                    style={customStyle}
                    className="mobile-filter mobile-filter-location"
                    onRequestClose={handleModalClose}
                    onAfterOpen={afterOpenModal}>
                    <div className="mobile-filter__wrap" ref={mobileFilterRef}>
                        {mediaType !== MEDIA_TYPE.DESKTOP
                            ? isLoading && (
                                  <div className={`locationLoader locationLoaderMobile`}>
                                      <LoadingIndicator />
                                  </div>
                              )
                            : ''}

                        {renderFilter(false)}
                    </div>
                </Modal>
            ) : (
                renderFilter(true)
            )}

            <GlobalModal
                portalClassName={'locationfiltermodal'}
                isOpen={isDescriptionPopUpOpen}
                title={handlePopUpModalTitle()}
                content={handlePopUpModalContent()}
                onRequestClose={handlePopUpModalClose}
                handleClose={handlePopUpModalClose}
                overlayClassName={'locationfiltermodal__overlay'}
                className={'locationfiltermodal__content'}
                titleClasses={'locationfiltermodal__title'}
                contentClasses={'locationfiltermodal__content-wrap'}
            />
        </>
    );
};

Filters.propTypes = {
    modalIsOpen: bool,
    setModalIsOpen: func,
    data: array,
    setFilterData: func,
    title: string,
    filterTitleClass: string,
    filterBodyClasess: string,
    setClickedOption: func,
    showMoreLimit: number,
    isShowMoreVisible: bool,
    onShowMoreClick: func,
    clearAll: bool,
    filterBodyRef: oneOfType([func, shape({ current: object })]),
    subfiltertitle: string,
    initFilterData: func,
    selectedOptions: array,
    filterOptionsObj: object,
    setIsNoStoreAvailable: func
};
Filters.defaultProps = {
    setClickedOption: () => {},
    filterTitleClass: '',
    subfiltertitle: 'specialties',
    title: 'Filter',
    filterBodyClasess: '',
    filterBodyRef: '',
    modalIsOpen: true,
    data: {
        specialties: { choices: Array(10), multi: true, title: 'specialties' }
    },
    selectedOptions: [],
    setFilterData: () => {},
    setModalIsOpen: () => {},
    isShowMoreVisible: true,
    onShowMoreClick: () => {},
    initFilterData: () => {},
    filterOptionsObj: {},
    setIsNoStoreAvailable: () => {}
};

export default Filters;
