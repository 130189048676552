import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { useDidMount } from '../../../../hooks/useDidMount';
import useMedia from '../../../../hooks/useMedia';
import ChevronDown from '../../../../resources/images/chevron-down-green.svg';
import InfoIcon16 from '../../../../resources/images/info16.svg';
import Plus from '../../../../resources/images/plus16.svg';
import Button from '../../atoms/button/button';
import Checkbox from '../../atoms/CheckboxInput';
import SelectedFilter from './SelectedFilter';
import './filters.scss';
import { globalCheckboxFiltersDataLocators } from './globalCheckboxFiltersDataLocators';

const FilterBody = props => {
    const {
        data,
        filterBodyClasess,
        setClickedOption,
        setIsClearAll,
        isShowMoreVisible,
        onShowMoreClick,
        filterBodyRef,
        localFilterData,
        setLocalFilterData,
        arePillsVisible,
        isListNested,
        isDescriptionAvailable,
        onInfoIconClick,
        ...restProps
    } = props;
    const mediaType = useMedia();
    const intl = useIntl();
    const didMount = useDidMount();
    const renderOption = (option, filterName) => {
        const optionName = option?.name;
        const currntFilters = localFilterData[filterName];
        const isChecked = currntFilters?.selectedOptions?.includes(optionName);
        var localFilterDataForBody = { ...localFilterData };
        const handleCheck = (label, currentVal) => {
            setClickedOption(optionName);
            //opposite condition because state is not set yet
            if (currentVal) {
                if (!isChecked) {
                    localFilterDataForBody[filterName].selectedOptions.push(optionName);
                }
            } else {
                if (isChecked) {
                    localFilterDataForBody[filterName].selectedOptions = localFilterDataForBody[
                        filterName
                    ].selectedOptions.filter(item => item !== optionName);
                }
            }
            setLocalFilterData(localFilterDataForBody);
        };

        return (
                <div className={`filter__item-option ${isChecked ? 'selectedOptionBackground' : ''}`}>
                    <Checkbox
                        id={optionName?.toLowerCase()?.replace(' ', '')}
                        label={optionName}
                        onClick={handleCheck}
                        isChecked={isChecked}
                        tabIndex={0}
                        dataTestId={globalCheckboxFiltersDataLocators.filter_body_checkbox_data_id}
                    />
                    {isDescriptionAvailable && (
                        <Button
                            className="infoIcon button button-pb0"
                            onClick={() => onInfoIconClick(option)}
                            tabIndex="0"
                            buttonAriaLabel={`${intl.formatMessage({
                                id: 'location-info-view'
                            })} ${intl.formatMessage({
                                id: 'commom:more-information'
                            })} ${optionName}`}
                            dataTestid={globalCheckboxFiltersDataLocators.filter_info_icon}>
                            <InfoIcon16 aria-hidden="true" />
                        </Button>
                    )}
                </div>
        );
    };
    const constructOptionBody = filterName => {
        const filterOptions = data[filterName].choices;
        return filterOptions.map((option, index) => {
            return <React.Fragment key={option.name}>{renderOption(option, filterName)}</React.Fragment>;
        });
    };

    const renderFilterOption = filterName => {
        const [isListVisible, setIsListVisible] = useState(ENV_CONFIG.META_FACETS_STATE);
        const calcSuffix = () => {
            return localFilterData[filterName]?.selectedOptions?.length || 0;
        };

        return (
            <React.Fragment key={filterName}>
                <div className={`filter__item ${isListVisible ? 'filter__item--open' : ''}`}>
                    {isListNested ? (
                        <div className={`filter__title`} onClick={() => setIsListVisible(!isListVisible)}>
                            {`${filterName} ${calcSuffix() > 0 ? `(${calcSuffix()})` : ''}`}

                            <button
                                aria-expanded={isListVisible}
                                className={`filter__title-expend ${isListVisible && 'expandedList'}`}
                                aria-label={
                                    isListVisible
                                        ? `${filterName} ${intl.formatMessage({ id: 'filter:expanded-filter' })}`
                                        : `${filterName} ${intl.formatMessage({ id: 'filter:collapsed-filter' })}`
                                }>
                                <ChevronDown />
                            </button>
                        </div>
                    ) : (
                        <div className={`filter__title`}>
                            {`${filterName} ${calcSuffix() > 0 ? `(${calcSuffix()})` : ''}`}
                        </div>
                    )}
                    <div
                        className={`filter__item-body ${filterName} ${
                            isListVisible || !isListNested ? 'filter__item-body--open' : ''
                        }`}>
                        {constructOptionBody(filterName)}
                        {isShowMoreVisible && (
                            <Button
                                type="button"
                                onClick={() => onShowMoreClick()}
                                buttonAriaLabel={`${intl.formatMessage({ id: 'location:show-more-cta' })}`}
                                className="filter__item-button">
                                {'Show more'}
                                <Plus />
                            </Button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const constructBody = () => {
        let filterOptions = [];
        for (let filter in data) {
            filterOptions.push(renderFilterOption(data[filter].title));
        }
        return filterOptions;
    };
    const onClearAll = () => {
        setClickedOption('');
        setIsClearAll(true);
    };
    const renderTopButtons = () => {
        let numberOfSelectedOptions = 0;
        for (let filter in data) {
            const filterName = data?.[filter]?.title;
            numberOfSelectedOptions += localFilterData?.[filterName]?.selectedOptions?.length;
        }
        if (arePillsVisible) {
            return (
                <div className={`filter__body-topfilters`}>
                    <SelectedFilter
                        localFilterData={localFilterData}
                        setLocalFilterData={setLocalFilterData}
                        setIsClearAll={setIsClearAll}
                        setClickedOption={setClickedOption}
                    />
                </div>
            );
        } else if (numberOfSelectedOptions > 0) {
            return (
                <Button
                    type="button"
                    className={'filter__body-clearAll'}
                    onClick={onClearAll}
                    customButtonAriaLabel={intl.formatMessage({ id: 'filter:clear-all-btn-text' })}
                    dataTestid={globalCheckboxFiltersDataLocators.filter_clear_all}>
                    {intl.formatMessage({ id: 'filter:clear-all-btn-text' })}
                </Button>
            );
        }
        return null;
    };
    return (
        <div
            className={`${mediaType === 'DESKTOP' ? 'filter__body' : 'mobile-filter__body'} ${filterBodyClasess}`}
            ref={filterBodyRef}>
            {renderTopButtons()}
            {constructBody()}
        </div>
    );
};

FilterBody.propTypes = {
    filterBodyClasess: string,
    setClickedOption: func,
    isShowMoreVisible: bool,
    onShowMoreClick: func,
    setIsClearAll: func,
    arePillsVisible: bool,
    isListNested: bool,
    isDescriptionAvailable: bool
};

FilterBody.defaultProps = {
    filterBodyClasess: '',
    setClickedOption: () => {},
    isShowMoreVisible: false,
    onShowMoreClick: () => {},
    setIsClearAll: () => {},
    arePillsVisible: true,
    isListNested: true,
    isDescriptionAvailable: false
};

export default FilterBody;
