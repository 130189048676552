import React from 'react';
import { number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { currencyToLocale } from '../../../components/global/utils/commonUtils';

const Price = props => {
    const { value, currencyCode, className, included = false } = props;
    const intl = useIntl();

    return (
        <b className={className} tabIndex={0}>
            {included ? intl.formatMessage({ id: 'addons:included-force-item-label' }) : currencyToLocale(value)}
        </b>
    );
};

Price.propTypes = {
    /**
     * The numeric price
     */
    value: number.isRequired,
    /**
     * A string with any of the currency code supported by Intl.NumberFormat
     */
    currencyCode: string.isRequired,
    /**
     * Class name to use when styling this component
     */
    className: string
};

Price.defaultProps = {
    className: '',
    currencyCode: 'USD'
};

export default Price;
