import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../aem-core-components/components/Minicart';
import isObjectEmpty from '../../aem-core-components/utils/isObjectEmpty';
import { useLocationState } from '../../contexts/location/locationContext';
import CrossIcon from '../../resources/images/closecircleblack.svg';
import Error from '../../resources/images/error.svg';
import SearchGreen from '../../resources/images/searchgreen.svg';
import Button from '../global/atoms/button/button';
import LocationAutocomplete from '../global/modules/location-autocomplete';
import {
    getLocationsDetailsByPlaceId,
    getlatLongByAddress
} from '../global/modules/location-autocomplete/api/getLocations';
import './searchform.scss';
import { setLocationInStorage } from './storesLocationFunctions';
import { locationDataLocators } from './dataLocators';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

const SearchForm = props => {
    const {
        placeholderText,
        wrapClass,
        getStores,
        isCurrentLocationAllowed,
        currentAddress,
        getCustomCountryCode,
        setIsUserLocationUsed,
        isUserLocationUsed,
        setIsCurrentLocationAllowed,
        setuserLatLong,
        userAddress,
        setUserAddress,
        locationDeniedError,
        setLocationDeniedError,
        onClearingAddress,
        isLocationAvailable,
        showSearchIcon,
        searchByBranch,
        isBranchSearchError,
        setIsBranchSearchError,
        isSearchByBranch,
        streetAddress,
        setStreetAddress
    } = props;
    const [branchValue, setBranchValue] = useState('');
    const [{}, cartDispatch] = useCartState();
    const [{}, dispatch] = useLocationState();
    const intl = useIntl();
    const [streetError, setStreetError] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [numberOnly, setNumberOnly] = useState(false);

    useEffect(() => {
        setBranchValue('');
        setShowCloseButton(false);
        setNumberOnly(false);
    }, [isSearchByBranch]);

    const handleSearchSubmit = event => {
        event.preventDefault();
    };
    const onAutoSelectAddress = async placeId => {
        setLocationDeniedError(false);
        const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(placeId);
        if (responseFromPlaceDetails?.error || isObjectEmpty(responseFromPlaceDetails?.data?.result)) {
            setStreetError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
            return;
        }
        const lat = responseFromPlaceDetails?.data?.result?.geometry?.location?.lat || '';
        const long = responseFromPlaceDetails?.data?.result?.geometry?.location?.lng || '';
        cartDispatch({ type: 'beginLoading' });
        getStores(lat, long, dispatch);
        setIsUserLocationUsed(true);
        setIsCurrentLocationAllowed(false);
        setuserLatLong({ lat: lat, long: long });
        setUserAddress(responseFromPlaceDetails?.data?.result?.formattedAddress);
        setLocationInStorage({ lat, long, formattedAddress: responseFromPlaceDetails?.data?.result?.formattedAddress });
    };

    const onSearchIconClick = async autocompleteText => {
        setLocationDeniedError(false);
        setStreetAddress(autocompleteText);
        if (autocompleteText === '') {
            setStreetAddress('');
            onClearingAddress();
        }
        if (autocompleteText !== '') {
            const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
            const responseFromGeocode = await getlatLongByAddress(companyID, autocompleteText, false, false);

            if (
                responseFromGeocode?.error ||
                responseFromGeocode?.data?.results?.length == 0 ||
                isObjectEmpty(responseFromGeocode?.data)
            ) {
                setStreetError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
                return false;
            } else {
                let lat = responseFromGeocode?.data?.results[0]?.geometry?.location?.lat || '';
                let long = responseFromGeocode?.data?.results[0]?.geometry?.location?.lng || '';
                if (!lat || !long) {
                    setStreetError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
                    return false;
                }
                cartDispatch({ type: 'beginLoading' });
                getStores(lat, long, dispatch);
                setIsUserLocationUsed(true);
                setIsCurrentLocationAllowed(false);
                setuserLatLong({ lat: lat, long: long });
                setUserAddress(responseFromGeocode?.data?.results[0]?.formattedAddress);
                setLocationInStorage({
                    lat,
                    long,
                    formattedAddress: responseFromGeocode?.data?.results[0]?.formattedAddress
                });
                return true;
            }
        }
    };

    const setPrefilledAddress = () => {
        if (isUserLocationUsed) {
            return userAddress;
        } else if (isCurrentLocationAllowed) {
            return currentAddress;
        }
        return streetAddress || '';
    };

    const handlebranchChange = event => {
        const { value } = event.target;
        if (value == '') {
            setShowCloseButton(false);
            setBranchValue('');
            setNumberOnly(false);
        } else if (value.length <= 4) {
            setShowCloseButton(true);
            setIsBranchSearchError(false);
            setBranchValue(value);
            setNumberOnly(isNaN(value));
        }
    };

    const handleBranchSubmit = e => {
        e.preventDefault();
        searchByBranch(branchValue);
    };
    const handleReset = () => {
        setBranchValue('');
        setShowCloseButton(false);
        setNumberOnly(false);
        onClearingAddress();
    };
    const onSubmit = e => {
        if (isSearchByBranch) {
            handleBranchSubmit(e);
        } else {
            handleSearchSubmit(e);
        }
    };

    const onCloseIconClickForAddress = () => {
        setStreetAddress('');
        setIsUserLocationUsed(false);
        onClearingAddress();
    };
    const errorMessages = {
        locationDeniedError: intl.formatMessage({ id: 'location-denied-error' }),
        numberOnly: intl.formatMessage({ id: 'location-branch-number-only' }),
        isBranchSearchError: 'location-branch-value-error'
    };
    const errorMessageId = locationDeniedError
        ? 'locationDeniedError'
        : numberOnly
        ? 'numberOnly'
        : isBranchSearchError
        ? 'isBranchSearchError'
        : null;

    return (
        <>
            <form role="search" className={`form-search ${wrapClass}`} onSubmit={onSubmit}>
                <div
                    className={
                        locationDeniedError
                            ? `input-group input-group--append`
                            : `input-group input-group--append location__search`
                    }>
                    <label className="location__search-label" htmlFor="locationAddressBranch">
                        {isSearchByBranch
                            ? intl.formatMessage({ id: 'llp-branch-number-text' })
                            : intl.formatMessage({ id: 'llp-address-text' })}
                    </label>
                    {isSearchByBranch ? (
                        <div className="input-group input-group--append input-group--branchpc ">
                            <div className="autocomplete__input-wrap">
                                <input
                                    aria-label={intl.formatMessage({ id: 'llp-branch-number-text' })}
                                    id="locationAddressBranch"
                                    value={branchValue}
                                    onChange={handlebranchChange}
                                    type="text"
                                    className={`cmp-Field__field__input input-group__field__input ${
                                        isBranchSearchError || numberOnly ? 'input-group__field__error' : ''
                                    }`}
                                    placeholder={intl.formatMessage({ id: 'llp-placeholder-enter-branch-number' })}
                                    data-testid={locationDataLocators.location_landing_search_by_branch_text_box}
                                />
                                {showCloseButton && (
                                    <Button
                                        className="crossIcon"
                                        onClick={handleReset}
                                        type={'button'}
                                        customButtonAriaLabel={intl.formatMessage({
                                            id: 'pdp:search-bar-cross-icon'
                                        })}>
                                        <CrossIcon tabIndex={'-1'} aria-hidden={'true'} />
                                    </Button>
                                )}
                            </div>
                            <Button
                                className="input-group-append"
                                customButtonAriaLabel={intl.formatMessage({ id: 'location:search-by-branchid-text' })}>
                                <SearchGreen tabIndex={'-1'} aria-hidden={'true'} />
                            </Button>
                        </div>
                    ) : (
                        <LocationAutocomplete
                            onSelectAddress={onAutoSelectAddress}
                            showSearchIcon={showSearchIcon}
                            prefilledAddress={setPrefilledAddress()}
                            onSearchIconClick={val => onSearchIconClick(val)}
                            // handleLocationChange={handleAddressChange}
                            selectedAddress={val => {
                                setStreetAddress(val);
                                setIsUserLocationUsed(false);
                            }}
                            onReset={() => {
                                onCloseIconClickForAddress();
                            }}
                            showResetBtn={true}
                            customParentClass={'location__search_div'}
                            placeholderText={placeholderText}
                            customCountryCode={getCustomCountryCode}
                            locationAutocompleteWrapper={'location-autocomplete--page'}
                            errorMessage={streetError}
                            onClearingAddress={onClearingAddress}
                            isLocationAvailable={false}
                            showAddressLineOneOnly={false}
                            ariaLabel={intl.formatMessage({ id: 'llp-address-text' })}
                            baseSuggesstionsByUserLocation={true}
                        />
                    )}
                </div>
            </form>

            {errorMessageId && (
                <div className="errorTextContainer" aria-live="assertive" role="alert">
                    <Error aria-hidden="true" tabIndex={'-1'} role="alert" />
                    <p>{intl.formatMessage({ id: errorMessages[errorMessageId] })}</p>
                </div>
            )}
        </>
    );
};

SearchForm.defaultProps = {
    showSearchIcon: true,
    wrapClass: '',
    isSearchByBranch: false,
    isBranchSearchError: false,
    placeholderText: '',
    getStores: () => {},
    isCurrentLocationAllowed: false,
    currentAddress: '',
    setZoomOutMax: () => {},
    getCustomCountryCode: () => {},
    setIsUserLocationUsed: () => {},
    isUserLocationUsed: false,
    setIsCurrentLocationAllowed: () => {},
    setuserLatLong: () => {},
    userAddress: '',
    setUserAddress: () => {},
    locationDeniedError: '',
    setLocationDeniedError: () => {},
    onClearingAddress: () => {},
    searchByBranch: () => {},
    isBranchSearchError: false,
    setIsBranchSearchError: () => {},
    isSearchByBranch: false,
    streetAddress: '',
    setStreetAddress: () => {}
};

SearchForm.propTypes = {
    showSearchIcon: bool,
    wrapClass: string,
    isSearchByBranch: bool,
    isBranchSearchError: bool,
    placeholderText: string,
    getStores: func,
    isCurrentLocationAllowed: bool,
    currentAddress: string,
    setZoomOutMax: func,
    getCustomCountryCode: func,
    setIsUserLocationUsed: func,
    isUserLocationUsed: bool,
    setIsCurrentLocationAllowed: func,
    setuserLatLong: func,
    userAddress: string,
    setUserAddress: func,
    locationDeniedError: string,
    setLocationDeniedError: func,
    onClearingAddress: func,
    searchByBranch: func,
    isBranchSearchError: bool,
    setIsBranchSearchError: func,
    isSearchByBranch: bool,
    streetAddress: string,
    setStreetAddress: func
};

export default SearchForm;
