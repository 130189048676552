import { bool, string } from 'prop-types';
import React from 'react';
import Button from '../../atoms/button/button';
import './filters.scss';
import { globalCheckboxFiltersDataLocators } from './globalCheckboxFiltersDataLocators';

const FilterFooter = props => {
    const { onClose, resultQty, filterFooterClasses } = props;
    return (
        <div className={`mobile-filter__footer ${filterFooterClasses}`}>
            <Button
                className="button button-primary button-block buttonStyling"
                onClick={onClose}
                customButtonAriaLabel={resultQty > 0 ? `Apply Filters` : 'Close filters'}
                data-testid={globalCheckboxFiltersDataLocators.filter_footer_apply_filter}>
                {resultQty > 0 ? `Apply Filters` : 'Close filters'}
            </Button>
        </div>
    );
};

FilterFooter.defaultProps = {
    selected: false,
    filterFooterClasses: ''
};

FilterFooter.propTypes = {
    selected: bool,
    filterFooterClasses: string
};

export default FilterFooter;
