import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Tab(props) {
    const { children, title, customButtonAriaLabel } = props;
    return <div aria-label={title} role="tabpanel">{children}</div>;
}

Tab.propTypes = {
    title: PropTypes.string,
    customButtonAriaLabel: PropTypes.string,
    children: PropTypes.node
};

Tab.defaultProps = {
    title: '',
    customButtonAriaLabel: '',
    children: null
};
export default memo(Tab);
