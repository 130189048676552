import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInView } from 'react-intersection-observer';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useFilterState } from '../filterContext';
import { usePdpState } from '../../../contexts/pdp/pdpContext';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { usePageType } from '../../../hooks/usePageType';
import { useCapUtils } from '../hooks/useCapUtils';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useCoachmark from '../../../hooks/useCoachmark';
import useMedia from '../../../hooks/useMedia';
import Button from '../../global/atoms/button/button';
import CapDatePicker from '../capDatePicker/CapDatePicker';
import PickupStore from '../pickupStore/PickupStore';
import RentalLocation from '../rentalLocation/RentalLocation';
import DrawerRentalLocation from '../drawerRentalLocation/DrawerRentalLocation';
import FulfillmentStatus from '../fulfillmentStatus/FulfillmentStatus';
import PersistantCapHeader from '../persistantCapHeader/PersistantHeader';
import Alert from '../../global/atoms/alert/alert';
import Tooltip from '../../global/atoms/Tooltip';
import { LoadingIndicator } from '../../../aem-core-components';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import {
    createFullAddress,
    getExcludedPCfromList,
    formatStoreDataToObject,
    isTier2Radius,
    formatNearbyPC
} from '../../global/utils/commonUtils';
import { updateSelectedStoreDetailsForRoundTrip } from '../../global/utils/computeAddressUtil';
import { isValidString, logError } from '../../global/utils/logger';
import { getStoreLocations, setGlobalLocationDetails } from '../../location/API/getStoreLocations';
import {
    EDIT_VIEW_CLOSE,
    EDIT_VIEW_OPEN,
    FULFILLMENT_TYPE,
    SET_END_DATE,
    SET_IS_CAP_DETAILS_UPDATING,
    SET_PICKUP_STORES,
    SET_SELECTED_STORE_DETAILS,
    SET_START_DATE,
    COACHMARK_TYPE
} from '../constants';
import config from '../../App/config';
import {
    RESET_GEOGRAPHY,
    RESET_LOCATION_DATA,
    RESET_PROJECT,
    SET_CREDIT_NEWADDR_FLAG,
    SET_LOCATION_DATA,
    SET_LOCATION_LOADING,
    SET_RATES_LOADING_FLAG,
    SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
    SET_SHOW_UNAVAILABLE_ITEMS,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../aem-core-components/components/Minicart/constants';
import { CATEGORY_PAGE_TEMPLATE_STRING, MEDIA_TYPE } from '../../../constants/screenConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import RentalChangeConfirmationModal from '../../global/modules/rentalChangeConfirmationModal/RentalChangeConfirmationModal';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../location/constants';
import { capDataLocator } from '../dataLocator';
import { AUTHORITY_TYPE } from '../../global/constants';
import './cap.scss';

const Cap = () => {
    const intl = useIntl();
    const userType = useCheckUser();
    const authorityType = useCheckAuthorityType();
    const { fetchLocationCoordinates, isSelectedLocationEmpty } = useCheckLocationEmpty();
    const [{ isCreditNewAddress, cart }, cartDispatch] = useCartState();
    const [
        {
            viewCart,
            projectDetails,
            showUnavailableItems,
            selectedStoreDetails,
            pickupStores,
            startDate,
            endDate,
            isEditView,
            isLocationsLoading
        },
        dispatch
    ] = useFilterState();
    const {
        getJobsiteWithLatLong,
        fetchLocalLocationCoordinates,
        getCapUserDetails,
        getCartUpdatedDetails,
        removeUnavailableItemsFromCart
    } = useCapUtils();
    const [, pdpDispatch] = usePdpState();
    const [{ isProfileLoaded }, { dispatch: userDispatch }] = useUserContext();
    const [addressText, setAddressText] = useState('');
    const [jobAndAddressState, setJobAndAddressState] = useState({
        chosenJobName: '',
        chosenAddress: ''
    });
    const fulfillmentData = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY;
    const [filterStartDate, setFilterStartDate] = useState(startDate);
    const [filterEndDate, setFilterEndDate] = useState(endDate);
    const [placeId, setPlaceId] = useState('');
    const [fulfillmentValue, setFulfillmentValue] = useState(fulfillmentData);
    const [jobsiteDetails, setJobsiteDetails] = useState({});
    const [isJobsiteSelected, setIsJobsiteSelected] = useState(false);
    const isCapDetailsSavedFromStorage =
        JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED)) || false;
    const [isCapInteracted, setIsCapInteracted] = useState(false);
    const [storesData, setStoresData] = useState(null);
    const [noLocationPC, setNoLocationPC] = useState(false);
    const [showPickupStoreAlert, setShowPickupStoreAlertModal] = useState(false);
    const [showAlertBanner, setShowAlertBanner] = useState(false);
    const [isStoreLocationFetching, setIsStoreLocationFetching] = useState(false);
    const [addressDetails, setAddressDetails] = useState({});
    const [isFormStartedEvent, setIsFormStartedEvent] = useState(true);
    const pageType = usePageType();
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const pageNameForAnalytics = isHomePage
        ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HERO
        : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HEADER;
    const [selectedPickupStore, setSelectedPickupStore] = useState({});
    const [updatedCartDetails, setUpdatedCartDetails] = useState([]);
    const [showRentalChangeModal, setShowRentalChangeModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [locationDetails, setLocationDetails] = useState({ lat: '', long: '' });
    const [selectedAddress, setSelectedAddress] = useState('');
    const {
        sendEventsForLocationFormSubmit,
        sendEventsForFormCompleted,
        sendEventsForDatePickerFormViewed,
        sendEventsForDatePickerFormInteraction,
        sendEventsForNoStoreNearby
    } = useAnalyticsContext();
    const [
        {
            getEcommerceData,
            payloadEcommerceLocationActionSubmitted,
            payloadEcommerceLocationActionAnalytics,
            payloadEcommerceActionAnalytics,
            payloadEcommerceNoStoreNearby,
            sendConfirmationModalAnalytics,
            sendConfirmationModalViewAnalytics
        }
    ] = useAnalytics();
    const [isCapDetailsSaved, setIsCapDetailsSaved] = useState(isCapDetailsSavedFromStorage);
    const [allStoresDataWithDistance, setAllStoresDataWithDistance] = useState({
        distance: ENV_CONFIG.Tier1SearchRadius,
        stores: []
    });
    const cidPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST) || '[]');
    const [ratesPCs, setRatesPCs] = useState(cidPcList);
    const atpPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]');
    const [localAtpPcs, setLocalAtpPcs] = useState(atpPcList);
    const localCompanyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const [companyID, setCompanyID] = useState(localCompanyId);
    const currentLocationDisabled =
        JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISABLE_CURRENT_LOCATION)) || false;
    const [isCurrentLocationDisabled, setIsCurrentLocationDisabled] = useState(currentLocationDisabled);
    const isDatePresent = Boolean(filterStartDate || filterEndDate);
    const isPickupStoreVisible = Boolean(
        !isHomePage && locationDetails?.lat && fulfillmentValue === FULFILLMENT_TYPE.PICKUP
    );
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const isEditPersistentModeOpen = !isHomePage && (isEditView || isCapInteracted);
    const coachmarkData = ENV_CONFIG.COACHMARK_STATUS;

    const [locationContainerRef, isLocationVisible] = useInView();
    const [isLocationTooltipOpen, setIsLocationTooltipOpen] = useState(false);
    const [pickupstoreContainerRef, isPickupStoreContainerVisible] = useInView();
    const [isPickupstoreTooltipOpen, setIsPickupstoreTooltipOpen] = useState(false);
    const { setCoachmarkCount, showCoachmark } = useCoachmark();
    const [showPickupCoachmark, setShowPickupCoachmark] = useState(false);
    const [showLocationCoachmark, setShowLocationCoachmark] = useState(false);
    const mediaType = useMedia();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const [isAddressChanged, setIsAddressChanged] = useState(false);
    // to make a note of which search string is used for fetching suggestions
    const [stringSearchedForSuggestion, setStringSearchedForSuggestion] = useState('');

    // check if the local address and context address are different
    const isLocationChanged = () => {
        const { localLat, localLong } = fetchLocationCoordinates();
        return localLat !== locationDetails?.lat || localLong !== locationDetails?.long;
    };

    const isDatesChanged = () => {
        return startDate !== filterStartDate || endDate !== filterEndDate;
    };

    const isFulfillmentTypeChanged = () => {
        return fulfillmentValue !== fulfillmentData;
    };

    const isStoreDetailsChanged = () => {
        return selectedStoreDetails?.pc !== selectedPickupStore?.pc;
    };

    useEffect(() => {
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED);
    }, []);

    useEffect(() => {
        setIsStoreLocationFetching(isLocationsLoading);
    }, [isLocationsLoading]);

    useEffect(() => {
        setFulfillmentValue(fulfillmentData);
    }, [fulfillmentData, overridePC?.pc]);

    useEffect(() => {
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        if (isProfileLoaded) {
            setCompanyID(parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1);
            setShowPickupCoachmark(showCoachmark(COACHMARK_TYPE.PICK_STORE));
            setShowLocationCoachmark(showCoachmark(COACHMARK_TYPE.LOCATION));
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT && isLocationVisible && showLocationCoachmark) {
            setCoachmarkCount(COACHMARK_TYPE.LOCATION);
            setIsLocationTooltipOpen(true);
        } else {
            setIsLocationTooltipOpen(false);
        }
    }, [showLocationCoachmark, isLocationVisible]);

    useEffect(() => {
        if (isPickupStoreContainerVisible && showPickupCoachmark) {
            if (isObjectWithKeys(selectedPickupStore)) {
                setCoachmarkCount(COACHMARK_TYPE.PICK_STORE);
                setIsPickupstoreTooltipOpen(true);
            }
        } else {
            setIsPickupstoreTooltipOpen(false);
        }
    }, [showPickupCoachmark, isPickupStoreContainerVisible, selectedPickupStore?.pc]);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            setAddressText(
                projectDetails?.projectName ? `${projectDetails?.projectName} ${createFullAddress(projectDetails)}` : ''
            );
            setJobAndAddressState({
                chosenJobName: projectDetails?.projectName,
                chosenAddress: createFullAddress(projectDetails)
            });
            setIsJobsiteSelected(true);
            setJobsiteDetails(projectDetails);
            isLocationChanged() &&
                setLocationDetails({
                    lat: projectDetails?.selectedProjectLatititude,
                    long: projectDetails?.selectedProjectLongitude
                });
        } else {
            setAddressText(viewCart?.location);
            setIsJobsiteSelected(false);
            isLocationChanged() && setLocationDetails({ lat: viewCart?.lat, long: viewCart?.long });
        }
    }, [isCreditNewAddress, projectDetails?.selectedProjectLatititude, viewCart?.lat, userType]);

    const handleLocationTooltipClose = () => {
        setIsLocationTooltipOpen(false);
        setShowLocationCoachmark(false);
    };

    const handlePickupstoreTooltipClose = () => {
        setIsPickupstoreTooltipOpen(false);
        setShowPickupCoachmark(false);
    };

    const setShowPickupStoreAlert = showModal => {
        if (!overridePC?.pc) {
            setShowPickupStoreAlertModal(showModal);
        }
    };

    const alertBanner = prop => {
        if (isSelectedLocationEmpty()) {
            setShowAlertBanner(false);
        } else if (isObjectEmpty(prop)) {
            setShowAlertBanner(true);
        } else {
            setShowAlertBanner(false);
        }
    };
    useEffect(() => {
        setSelectedPickupStore(selectedStoreDetails);
        alertBanner(selectedStoreDetails);
    }, [selectedStoreDetails?.pc]);

    useEffect(() => {
        onLoadPageTypeEventAnalytics();
    }, []);

    useEffect(() => {
        if (showRentalChangeModal) {
            sendConfirmationModalViewAnalytics(updatedCartDetails);
        }
    }, [showRentalChangeModal]);

    const onLoadPageTypeEventAnalytics = () => {
        sendEventsForFormCompleted(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED, pageNameForAnalytics, {
            ...payloadEcommerceActionAnalytics(false),
            ecommerce: getEcommerceData()
        });
    };

    /* This function set the default store for the location change */
    const handleStoreData = pcList => {
        setStoresData({ data: pcList?.slice(0, 200) });
        const specialities = pcList?.find(
            item =>
                !item?.specialties ||
                item?.specialties?.length === 0 ||
                item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
        );
        const storeData = specialities || pcList?.[0];
        if (fulfillmentValue === FULFILLMENT_TYPE.DELIVERY) {
            updateSelectedStoreDetailsForRoundTrip(storeData);
        }
        setSelectedPickupStore(storeData);
        setIsStoreLocationFetching(false);
    };

    const updateRatesPcs = (ratePcs, atpPcs) => {
        if (ratePcs) {
            const formattedPcs = ratePcs?.map(data => formatNearbyPC(data?.pc, `0${companyID}`));
            setRatesPCs(formattedPcs);
        } else {
            setRatesPCs(cidPcList);
        }
        if (atpPcs) {
            const formattedPcs = atpPcs?.map(data => formatNearbyPC(data?.pc, `0${companyID}`));
            setLocalAtpPcs(formattedPcs);
        } else {
            setLocalAtpPcs(atpPcList);
        }
    };

    /* This function is used for the locations an set the default store call when address or jobsite is changed form CAP location heaer*/
    const getStores = async (lat = '', long = '', companyID) => {
        try {
            if (lat && long) {
                const { data } = await getStoreLocations(lat, long, ENV_CONFIG.Tier1SearchRadius, companyID);
                const pcList = data?.data?.pcList;
                /* data for Tier1 radius */
                if (pcList?.length > 0) {
                    const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcList);
                    updateRatesPcs(pricingPcs, pickupStorePcs);
                    handleStoreData(pickupStorePcs);
                    setAllStoresDataWithDistance({ stores: pickupStorePcs, distance: ENV_CONFIG.Tier1SearchRadius });
                    setNoLocationPC(false);
                    dispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });

                    return;
                } else {
                    const { data } = await getStoreLocations(lat, long, ENV_CONFIG?.Tier2SearchRadius, companyID);
                    const pcListArr = data?.data?.pcList;
                    /* data for Tier2 radius */
                    if (pcListArr?.length > 0) {
                        const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcListArr);
                        updateRatesPcs(pricingPcs, pickupStorePcs);
                        handleStoreData([]);
                        setStoresData({ data: [] });
                        setNoLocationPC(true);
                        setAllStoresDataWithDistance({
                            stores: pickupStorePcs,
                            distance: ENV_CONFIG.Tier2SearchRadius
                        });
                        setSelectedPickupStore({});
                        dispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });

                        return;
                    } else {
                        updateRatesPcs([], []);
                        setAllStoresDataWithDistance({ stores: [], distance: ENV_CONFIG.Tier2SearchRadius });
                        setNoLocationPC(true);
                        dispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
                    }
                }
                /* Set empty store when there is no data from locations call */
                setAllStoresDataWithDistance({ stores: [], distance: ENV_CONFIG.Tier1SearchRadius });
                setStoresData({ data: [] });
                setSelectedPickupStore({});
                setIsStoreLocationFetching(false);
            } else {
                updateRatesPcs([], []);
                setAllStoresDataWithDistance({ stores: [], distance: ENV_CONFIG.Tier1SearchRadius });
                setStoresData({ data: [] });
                setSelectedPickupStore({});
                setIsStoreLocationFetching(false);
                setNoLocationPC(false);
            }
        } catch (error) {
            setIsStoreLocationFetching(false);
            logError(error, false, 'getStores');
        }
    };

    useEffect(() => {
        const pcList = storesData?.data;
        if (!isStoreLocationFetching && pcList?.length > 0) {
            handleStoreData(pcList);
        }
    }, [fulfillmentValue]);

    useEffect(() => {
        /* Set the stores data and banner for tier2 radius when page is reloaded */
        const stores = Object.values(pickupStores || {});
        const tier2Radius = isValidString(isTier2Radius()) && !overridePC?.pc;
        setStoresData({ data: tier2Radius ? [] : stores?.slice(0, 200) });
        setAllStoresDataWithDistance({
            stores,
            distance: tier2Radius ? ENV_CONFIG.Tier2SearchRadius : ENV_CONFIG.Tier1SearchRadius
        });
        updateRatesPcs();
        setNoLocationPC(tier2Radius);
        setShowAlertBanner(tier2Radius);
    }, [pickupStores]);

    const rentalLocationFocusHandler = () => {
        handleLocationTooltipClose();
        formInteractionStartedEventAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
        triggerFormInteractedEvent(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
    };

    /* Fetch lat long for selected address or jobsite and stores data from locations call*/
    const storeLocationDetails = async data => {
        setIsStoreLocationFetching(true);
        const { placeId = '', jobsiteDetails = {}, isJobsiteSelected = false } = data;
        const locationCoordinates =
            (await fetchLocalLocationCoordinates(placeId, jobsiteDetails, isJobsiteSelected)) || {};
        const { lat, long, companyId, addressDetails } = locationCoordinates || {};
        setCompanyID(companyId);
        setAddressDetails(addressDetails);
        setLocationDetails({ lat, long });
        getStores(lat, long, companyId);
    };
    const addressSelectHandler = (address, currentLocationDisabledState, placeId) => {
        if (address !== addressText) {
            setAddressText(address);
            setPlaceId(placeId);
            setIsJobsiteSelected(false);
            storeLocationDetails({ placeId });
        }
        setIsCurrentLocationDisabled(currentLocationDisabledState);
        setSelectedAddress(address);
    };

    const jobsiteSelectHandler = async (jobsite, fullAddress, currentLocationDisabledState) => {
        try {
            const jobSiteName = jobsite?.projectName ? `${jobsite?.projectName} ${fullAddress}` : '';
            if (jobSiteName !== addressText) {
                setAddressText(jobsite?.projectName ? `${jobsite?.projectName} ${fullAddress}` : '');
                const josbiteWithLatLong = await getJobsiteWithLatLong(jobsite);
                setJobsiteDetails(josbiteWithLatLong);
                setIsJobsiteSelected(true);
                setPlaceId('');
                storeLocationDetails({ jobsiteDetails: josbiteWithLatLong, isJobsiteSelected: true });
            }
            setIsCurrentLocationDisabled(currentLocationDisabledState);
            setSelectedAddress(fullAddress);
        } catch (error) {
            logError(error, false, 'jobsiteSelectHandler', [jobsite, fullAddress, currentLocationDisabledState]);
        }
    };

    const updateAllStoreDetails = () => {
        if (allStoresDataWithDistance?.stores?.length > 0) {
            dispatch({
                type: SET_PICKUP_STORES,
                pickupStores: formatStoreDataToObject(allStoresDataWithDistance?.stores)
            });
        }
    };

    const setAddressInContext = () => {
        dispatch({
            type: SET_LOCATION_DATA,
            ...addressDetails,
            ...locationDetails,
            location: addressText,
            pc: allStoresDataWithDistance.stores[0]?.pc || viewCart?.pc,
            pcLat: allStoresDataWithDistance.stores[0]?.latitude || viewCart?.pcLat,
            pcLong: allStoresDataWithDistance.stores[0]?.longitude || viewCart?.pcLong
        });
    };

    const resetCapFields = () => {
        try {
            let savedAddressText = '';
            const resetLocation = isLocationChanged();
            if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
                savedAddressText = projectDetails?.projectName
                    ? `${projectDetails?.projectName} ${createFullAddress(projectDetails)}`
                    : '';
                setJobsiteDetails(projectDetails);
                resetLocation &&
                    setLocationDetails({
                        lat: projectDetails?.selectedProjectLatititude,
                        long: projectDetails?.selectedProjectLongitude
                    });
                setIsJobsiteSelected(true);
            } else {
                setPlaceId('');
                savedAddressText = viewCart?.location;
                resetLocation && setLocationDetails({ lat: viewCart?.lat, long: viewCart?.long });
                setIsJobsiteSelected(false);
            }
            setAddressText(savedAddressText);
            !savedAddressText && setIsPickupstoreTooltipOpen(false);

            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE,
                isNaN(new Date(startDate).getDate()) ? '' : startDate.toString()
            );
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE,
                isNaN(new Date(endDate).getDate()) ? '' : endDate.toString()
            );

            setFilterStartDate(startDate);
            setFilterEndDate(endDate);

            setFulfillmentValue(viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY);
            setSelectedPickupStore(selectedStoreDetails);
            alertBanner(selectedStoreDetails);
            setCompanyID(parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1);
            updateRatesPcs();
            const tier2Radius = isValidString(isTier2Radius()) && !overridePC?.pc;
            const stores = Object.values(pickupStores || {});
            setStoresData({ data: tier2Radius ? [] : stores?.slice(0, 200) });
            setAllStoresDataWithDistance({
                stores,
                distance: tier2Radius ? ENV_CONFIG.Tier2SearchRadius : ENV_CONFIG.Tier1SearchRadius
            });
            setIsCurrentLocationDisabled(currentLocationDisabled);
            setIsAddressChanged(false);
        } catch (error) {
            logError(error, false, 'resetCapFields');
        }
    };

    const triggerFormSubmitEvent = localLocation => {
        try {
            const buttonName = isHomePage
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_START_RENTING
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_APPLY;
            sendEventsForLocationFormSubmit(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_EVENT,
                pageNameForAnalytics,
                buttonName,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                { ...payloadEcommerceLocationActionSubmitted(localLocation), ecommerce: getEcommerceData() }
            );
        } catch (error) {
            logError(error, false, 'triggerFormSubmitEvent', [localLocation]);
        }
    };

    const triggerFormInteractedEvent = type => {
        try {
            sendEventsForDatePickerFormViewed(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_INTERACTED,
                pageNameForAnalytics,
                type,
                payloadEcommerceLocationActionAnalytics(false)
            );
        } catch (error) {
            logError(error, false, 'triggerFormInteractedEvent', [type]);
        }
    };

    const sendDeliveryInteractedEvent = (method, value) => {
        try {
            sendEventsForDatePickerFormInteraction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_INTERACTED,
                pageNameForAnalytics,
                method,
                value,
                payloadEcommerceLocationActionAnalytics(false)
            );
        } catch (error) {
            logError(error, false, 'sendDeliveryInteractedEvent', [method, value]);
        }
    };

    const triggerFormCompleteEvent = localLocation => {
        try {
            sendEventsForFormCompleted(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_COMPLETED,
                pageNameForAnalytics,
                { ...payloadEcommerceLocationActionSubmitted(localLocation), ecommerce: getEcommerceData() }
            );
        } catch (error) {
            logError(error, false, 'triggerFormCompleteEvent', [localLocation]);
        }
    };

    const sendAnalyticsForNoStoreNearBy = () => {
        sendEventsForNoStoreNearby(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_NO_STORE_NEARBY,
            payloadEcommerceNoStoreNearby(addressDetails, selectedAddress)
        );
    };

    const startUpdateAndRatesLoading = () => {
        try {
            dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: true });
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: true
            });
            if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
                pdpDispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: true
                });
            }
        } catch (error) {
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            pdpDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'startUpdateAndRatesLoading');
        }
    };
    const isCartDetailsUpdated = async () => {
        try {
            if (isP2PUser && fulfillmentValue === FULFILLMENT_TYPE.DELIVERY) {
                setUpdatedCartDetails([]);
                return false;
            }
            if (isLocationChanged() || isDatesChanged() || isFulfillmentTypeChanged() || isStoreDetailsChanged()) {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST); // to refetch the bsr list
                if (
                    isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 ||
                    cart?.total_quantity > 0
                ) {
                    setShowLoader(true);
                    const cartDetails = await getCartUpdatedDetails({
                        isJobsiteSelected,
                        jobsiteDetails,
                        locationDetails,
                        localNearbyPcs: localAtpPcs,
                        companyID,
                        selectedPickupStore,
                        filterStartDate,
                        filterEndDate,
                        fulfillmentValue,
                        isSourceCallRequired: fulfillmentValue === FULFILLMENT_TYPE.DELIVERY,
                        localIsTier2Radius: allStoresDataWithDistance?.distance == ENV_CONFIG.Tier2SearchRadius
                    });
                    setUpdatedCartDetails(cartDetails);
                    setShowLoader(false);
                    return cartDetails?.length > 0;
                }
            }
            setUpdatedCartDetails([]);
            return false;
        } catch (error) {
            logError(error, false, 'isCartDetailsUpdated');
        }
    };
    const updateFilterHandler = async () => {
        let localLocation = {};
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, companyID);
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP,
            fulfillmentValue === FULFILLMENT_TYPE.PICKUP
        );
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.LOCATIONDETAILFORSELECTEDSTORE,
            JSON.stringify(locationDetails)
        );
        updateAllStoreDetails();
        if (isJobsiteSelected) {
            const jobsiteWithLatLong = await getJobsiteWithLatLong(jobsiteDetails);
            dispatch({ type: RESET_LOCATION_DATA });
            dispatch({ type: RESET_GEOGRAPHY });
            cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, false);
            dispatch({
                type: UPDATE_PROJECT,
                locationPC: jobsiteWithLatLong?.locationPC,
                projectName: jobsiteWithLatLong?.projectName,
                projectAddress1: jobsiteWithLatLong?.projectAddress1,
                projectAddress2: jobsiteWithLatLong?.projectAddress2,
                selectedProjectJobId: jobsiteWithLatLong?.selectedProjectJobId,
                selectedRMJobId: jobsiteWithLatLong?.selectedRMJobId,
                selectedProjectLatititude: jobsiteWithLatLong?.selectedProjectLatititude,
                selectedProjectLongitude: jobsiteWithLatLong?.selectedProjectLongitude,
                selectedProjectState: jobsiteWithLatLong?.selectedProjectState,
                selectedProjectCity: jobsiteWithLatLong?.selectedProjectCity,
                selectedProjectZip: jobsiteWithLatLong?.selectedProjectZip,
                primaryContactName: jobsiteWithLatLong?.primaryContactName,
                phoneNumber: jobsiteWithLatLong?.phoneNumber,
                accessNotes: jobsiteWithLatLong?.accessNotes,
                poNumber: jobsiteWithLatLong?.poNumber,
                CJDLVY: jobsiteWithLatLong?.CJDLVY,
                isValueUpdated: jobsiteWithLatLong?.isValueUpdated
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, jobsiteWithLatLong?.CJDLVY);
            localLocation = {
                address1: jobsiteWithLatLong?.projectAddress1,
                address2: jobsiteWithLatLong?.projectAddress2,
                city: jobsiteWithLatLong?.selectedProjectCity,
                state: jobsiteWithLatLong?.selectedProjectState,
                zip: jobsiteWithLatLong?.selectedProjectZip,
                projectName: jobsiteWithLatLong?.projectName
            };
        } else {
            if (placeId && addressText) {
                if (userType === USER_TYPE.CREDIT) {
                    setJobsiteDetails({});
                    setAddressInContext();
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, true);
                    dispatch({ type: RESET_PROJECT });
                } else {
                    setAddressInContext();
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, true);
                }
                localLocation = {
                    ...addressDetails,
                    location: addressText
                };
            } else {
                dispatch({
                    type: SET_LOCATION_DATA,
                    pc: viewCart?.pc,
                    location: viewCart?.location,
                    jobSiteCity: viewCart?.jobSiteCity,
                    jobSiteState: viewCart?.jobSiteState,
                    jobSiteZip: viewCart?.jobSiteZip,
                    lat: viewCart?.lat,
                    long: viewCart?.long,
                    jobSiteAddr2: viewCart?.jobSiteAddr2,
                    pcLat: viewCart?.pcLat,
                    pcLong: viewCart?.pcLong
                });
                if (viewCart?.pc) {
                    cartDispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: true });
                }
                localLocation = { ...viewCart };
            }
            dispatch({ type: RESET_PROJECT });
        }
        dispatch({
            type: SET_VIEW_CART_FIELDS,
            key: VIEW_CART.IN_STORE,
            value: fulfillmentValue === FULFILLMENT_TYPE.PICKUP
        });

        dispatch({
            type: SET_SELECTED_STORE_DETAILS,
            selectedStoreDetails: selectedPickupStore,
            localLat: locationDetails?.lat,
            localLong: locationDetails?.long,
            isNoStore:
                allStoresDataWithDistance?.distance == ENV_CONFIG.Tier2SearchRadius
                    ? allStoresDataWithDistance?.stores[0]
                    : {}
        });

        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, filterEndDate ? new Date(filterEndDate) : '');
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, filterStartDate ? new Date(filterStartDate) : '');

        dispatch({ type: SET_START_DATE, startDate: filterStartDate ? new Date(filterStartDate).toString() : '' });
        dispatch({ type: SET_END_DATE, endDate: filterEndDate ? new Date(filterEndDate).toString() : '' });

        dispatch({
            type: SET_VIEW_CART_FIELDS,
            key: 'showDeliveryEstimate',
            value: fulfillmentValue === FULFILLMENT_TYPE.DELIVERY
        });

        const storeDetails = isObjectWithKeys(selectedPickupStore)
            ? selectedPickupStore
            : allStoresDataWithDistance?.stores[0];
        setGlobalLocationDetails(
            locationDetails?.lat,
            locationDetails?.long,
            allStoresDataWithDistance?.distance,
            ratesPCs,
            localAtpPcs,
            cartDispatch,
            showUnavailableItems,
            storeDetails
        );
        dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: false });
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED, true);
        setIsCapDetailsSaved(true);
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.DISABLE_CURRENT_LOCATION, isCurrentLocationDisabled);
        triggerFormSubmitEvent(localLocation);
        triggerFormCompleteEvent(localLocation);
        setIsAddressChanged(false);
    };

    const updateRentalViewDetails = () => {
        try {
            if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                dispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                dispatch({ type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS, showNoResultsForUnavailableItems: false });
            }
            updateFilterHandler();
            /* To disable banner for invalid address based on locationdetails condition */
            if (!locationDetails?.lat || isObjectWithKeys(selectedPickupStore)) {
                setShowAlertBanner(false);
            }
            dispatch({ type: EDIT_VIEW_CLOSE });
            setIsCapInteracted(false);
        } catch (error) {
            logError(error, false, 'updateRentalViewDetails');
        }
    };

    const handleNavigationToEquipmentRentingPage = async () => {
        await updateFilterHandler();
        window.location.href = window.location.origin + config.pagePaths.equipmentRentalPage;
    };

    const saveCapDetailsHandler = async noLocationPC => {
        try {
            if (noLocationPC && !overridePC?.pc) {
                setShowPickupStoreAlert(true);
                return;
            }
            const isCartDataUpdated = await isCartDetailsUpdated();
            if (isCartDataUpdated) {
                setShowRentalChangeModal(true);
                return;
            }
            startUpdateAndRatesLoading();
            if (isHomePage) {
                handleNavigationToEquipmentRentingPage();
            } else {
                updateRentalViewDetails();
            }
        } catch (error) {
            logError(error, false, 'saveCapDetailsHandler', [noLocationPC]);
        }
    };

    const capHeaderOverlayHandler = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        setIsCapInteracted(false);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED, true);
        setIsCapDetailsSaved(true);
        resetCapFields();
    };

    const performPreUpdate = () => {
        try {
            startUpdateAndRatesLoading();
            if (!isHomePage) {
                if (ENV_CONFIG.PAGE_TEMPLATE_STRING === CATEGORY_PAGE_TEMPLATE_STRING) {
                    dispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                    dispatch({
                        type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
                        showNoResultsForUnavailableItems: false
                    });
                }
                /* To disable banner for invalid address based on locationdetails condition */
                if (!locationDetails?.lat || isObjectWithKeys(selectedPickupStore)) {
                    setShowAlertBanner(false);
                }
                dispatch({ type: EDIT_VIEW_CLOSE });
                setIsCapInteracted(false);
            }
        } catch (error) {
            logError(error, false, 'performPreUpdate');
        }
    };

    const onRentalChangeConfirmation = async (isAccepted, isCloseClicked = false, isBackClicked = false) => {
        try {
            setShowRentalChangeModal(false);
            if (isAccepted) {
                performPreUpdate();
                /* Remove the unavailable items form the cart when tries to proceed with rental details change */
                await removeUnavailableItemsFromCart(updatedCartDetails);
                if (isHomePage) {
                    handleNavigationToEquipmentRentingPage();
                } else {
                    updateFilterHandler();
                }
            } else {
                resetCapFields();
                dispatch({ type: EDIT_VIEW_CLOSE });
                setIsCapInteracted(false);
            }
            sendConfirmationModalAnalytics(isAccepted, isCloseClicked, isBackClicked, updatedCartDetails);
        } catch (error) {
            logError(error, false, 'onRentalChangeConfirmation', [isAccepted]);
        }
    };

    const renderUpdateBtn = () => {
        const showShimmer = isStoreLocationFetching && isEditView;
        if (isHomePage) {
            const startRentingBtnText =
                getCapUserDetails()?.rentingCTAText || intl.formatMessage({ id: 'cap:start-renting-button' });
            return (
                <Button
                    type="button"
                    className={`start-renting-btn ${showShimmer ? 'shimmer start-renting-btn__shimmer' : ''}`}
                    buttonAriaLabel={startRentingBtnText}
                    dataTestid={capDataLocator.renting_button_cta_testid}
                    isdisabled={showShimmer}
                    onClick={() => saveCapDetailsHandler(false)}>
                    {startRentingBtnText}
                </Button>
            );
        } else {
            return (
                <Button
                    type="button"
                    className={`start-renting-btn ${showShimmer ? 'shimmer start-renting-btn__shimmer' : ''}`}
                    buttonAriaLabel={intl.formatMessage({ id: 'common:apply' })}
                    dataTestid={capDataLocator.apply_button_cta_testid}
                    isdisabled={showShimmer}
                    onClick={() => saveCapDetailsHandler(noLocationPC)}>
                    {intl.formatMessage({ id: 'common:apply' })}
                </Button>
            );
        }
    };

    const renderRentalDetailsView = () => (
        <>
            <RentalLocation
                addressText={addressText}
                jobAndAddressState={jobAndAddressState}
                setJobAndAddressState={setJobAndAddressState}
                isCurrentLocationDisabled={isCurrentLocationDisabled}
                setIsCapInteracted={setIsCapInteracted}
                locationContainerRef={locationContainerRef}
                isAddressChanged={isAddressChanged}
                setIsAddressChanged={setIsAddressChanged}
                stringSearchedForSuggestion={stringSearchedForSuggestion}
                setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                onFocusRentalLocation={rentalLocationFocusHandler}
                onAddressSelect={addressSelectHandler}
                onJobsiteSelect={jobsiteSelectHandler}
            />
            <CapDatePicker
                startDate={filterStartDate}
                endDate={filterEndDate}
                setStartDate={setFilterStartDate}
                setEndDate={setFilterEndDate}
                userType={userType}
                className="cap-date-picker"
                isLabelVisibleInModal={!isDatePresent}
                showIconWithLabel
                dynamicWidth
                isWrapperClickable
                customCompanyId={companyID}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
            />
            <FulfillmentStatus
                fulfillmentValue={fulfillmentValue}
                setFulfillmentValue={setFulfillmentValue}
                showRadioOptions={isHomePage}
                className="fulfillment-type"
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
                sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
            />
            {isPickupStoreVisible && (
                <PickupStore
                    locationDetails={locationDetails}
                    selectedPickupStore={selectedPickupStore}
                    setSelectedPickupStore={setSelectedPickupStore}
                    storesData={storesData}
                    setIsCapInteracted={setIsCapInteracted}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    isStoresLoading={isStoreLocationFetching}
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    triggerFormInteractedEvent={triggerFormInteractedEvent}
                    localStartDate={filterStartDate}
                    pickupstoreContainerRef={pickupstoreContainerRef}
                    companyId={companyID}
                    handlePickupstoreTooltipClose={handlePickupstoreTooltipClose}
                />
            )}
        </>
    );

    const renderRentalDetailsDrawerContent = () => (
        <>
            <DrawerRentalLocation
                addressText={addressText}
                isJobsiteSelected={isJobsiteSelected}
                setIsCapInteracted={setIsCapInteracted}
                jobsiteDetails={jobsiteDetails}
                isCurrentLocationDisabled={isCurrentLocationDisabled}
                jobAndAddressState={jobAndAddressState}
                setJobAndAddressState={setJobAndAddressState}
                locationContainerRef={locationContainerRef}
                isAddressChanged={isAddressChanged}
                setIsAddressChanged={setIsAddressChanged}
                stringSearchedForSuggestion={stringSearchedForSuggestion}
                setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                onFocusRentalLocation={rentalLocationFocusHandler}
                onAddressSelect={addressSelectHandler}
                onJobsiteSelect={jobsiteSelectHandler}
            />
            <CapDatePicker
                startDate={filterStartDate}
                endDate={filterEndDate}
                setStartDate={setFilterStartDate}
                setEndDate={setFilterEndDate}
                userType={userType}
                customCompanyId={companyID}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                triggerFormInteractedEvent={triggerFormInteractedEvent}
            />
            <div>
                <label className="fulfillment-drawer-title">
                    {getCapUserDetails()?.fulfillmentText || intl.formatMessage({ id: 'cap:fulfillment-type' })}
                </label>
                <FulfillmentStatus
                    fulfillmentValue={fulfillmentValue}
                    setFulfillmentValue={setFulfillmentValue}
                    className="fulfillment-drawer-content"
                    legend={getCapUserDetails()?.fulfillmentText || intl.formatMessage({ id: 'cap:fulfillment-type' })}
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                />
            </div>
            {isPickupStoreVisible && (
                <PickupStore
                    locationDetails={locationDetails}
                    selectedPickupStore={selectedPickupStore}
                    setSelectedPickupStore={setSelectedPickupStore}
                    storesData={storesData}
                    setIsCapInteracted={setIsCapInteracted}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    isStoresLoading={isStoreLocationFetching}
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    triggerFormInteractedEvent={triggerFormInteractedEvent}
                    localStartDate={filterStartDate}
                    pickupstoreContainerRef={pickupstoreContainerRef}
                    companyId={companyID}
                    handlePickupstoreTooltipClose={handlePickupstoreTooltipClose}
                />
            )}
            {showAlertBanner && !overridePC?.pc && (
                <Alert
                    localStyle="pickupStore__alert"
                    type={'warning'}
                    message={
                        <div>
                            {intl.formatMessage({ id: 'cap:pickup-no-store-warning-text-prefix' })}
                            <a
                                href={intl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                                className="pickupStore__alert__link"
                                title={intl.formatMessage({ id: 'cap:no-store-help-text' })}>
                                {intl.formatMessage({ id: 'cap:no-address-helpline' })}
                            </a>
                            {intl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
                        </div>
                    }
                    dataTestId={capDataLocator.pickupStore_alert}
                />
            )}
            <Tooltip
                isOpen={isPickupstoreTooltipOpen}
                anchorSelect=".tooltip-pickupstore"
                handleTooltipClose={handlePickupstoreTooltipClose}>
                {coachmarkData?.pickupstoretooltip || intl.formatMessage({ id: 'coachmark:pickup-store' })}
            </Tooltip>
            {userType === USER_TYPE.CREDIT && (
                <Tooltip
                    isOpen={isLocationTooltipOpen}
                    anchorSelect=".rental-drawer-location"
                    handleTooltipClose={handleLocationTooltipClose}>
                    {coachmarkData?.creditlocationtooltip || intl.formatMessage({ id: 'coachmark:rental-location' })}
                </Tooltip>
            )}
        </>
    );

    const formInteractionStartedEventAnalytics = formFieldName => {
        if (isFormStartedEvent) {
            sendEventsForDatePickerFormViewed(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                pageNameForAnalytics,
                formFieldName,
                payloadEcommerceLocationActionAnalytics(false)
            );
        }
        setIsFormStartedEvent(false);
    };

    return (
        <div className="cap-container">
            <button
                tabIndex="-1"
                className={`modal__root ${isEditView ? 'modal__root-active' : 'modal__root-inactive'}`}
                onClick={capHeaderOverlayHandler}
            />
            {isHomePage ? (
                <div className={'cap-header'}>
                    {renderRentalDetailsView()}
                    <div className="start-renting-div">{renderUpdateBtn()}</div>
                </div>
            ) : (
                <PersistantCapHeader
                    fulfillmentValue={fulfillmentValue}
                    isCreditNewAddress={isCreditNewAddress}
                    isCapInteracted={isCapInteracted}
                    setIsCapInteracted={setIsCapInteracted}
                    renderRentalDetailsView={renderRentalDetailsView}
                    renderUpdateBtn={renderUpdateBtn}
                    renderRentalDetailsDrawerContent={renderRentalDetailsDrawerContent}
                    isCapDetailsSaved={isCapDetailsSaved}
                    setIsCapDetailsSaved={setIsCapDetailsSaved}
                    setShowPickupStoreAlert={setShowPickupStoreAlert}
                    showPickupStoreAlert={showPickupStoreAlert}
                    setNoLocationPC={setNoLocationPC}
                    setShowAlertBanner={setShowAlertBanner}
                    showAlertBanner={showAlertBanner}
                    updateRentalViewDetails={saveCapDetailsHandler}
                    addressText={addressText}
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    selectedPickupStore={selectedPickupStore}
                    jobsiteDetails={jobsiteDetails}
                    isJobsiteSelected={isJobsiteSelected}
                    resetCapFields={resetCapFields}
                    formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                    triggerFormInteractedEvent={triggerFormInteractedEvent}
                    sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                    pickupstoreContainerRef={pickupstoreContainerRef}
                    locationContainerRef={locationContainerRef}
                    sendAnalyticsForNoStoreNearBy={sendAnalyticsForNoStoreNearBy}
                    isStoreLocationFetching={isStoreLocationFetching}
                />
            )}
            {showRentalChangeModal && (
                <RentalChangeConfirmationModal
                    isOpen
                    productList={updatedCartDetails}
                    handleModalClose={() => onRentalChangeConfirmation(false, true)}
                    handleBackNavigation={() => onRentalChangeConfirmation(false, false, true)}
                    handleConfirmation={onRentalChangeConfirmation}
                />
            )}
            {(showLoader || isLocationsLoading) && <LoadingIndicator global />}
            {!(isEditPersistentModeOpen && mediaType !== MEDIA_TYPE.DESKTOP) && (
                <>
                    <Tooltip
                        isOpen={isPickupstoreTooltipOpen}
                        anchorSelect={isEditPersistentModeOpen ? '.tooltip-pickupstore' : '.tooltip-pickup-store'}
                        zIndex={isEditView ? 55 : 5}
                        offset={isHomePage || isEditPersistentModeOpen ? 7 : 22}
                        handleTooltipClose={handlePickupstoreTooltipClose}>
                        {coachmarkData?.pickupstoretooltip || intl.formatMessage({ id: 'coachmark:pickup-store' })}
                    </Tooltip>
                    {userType === USER_TYPE.CREDIT && (
                        <Tooltip
                            isOpen={isLocationTooltipOpen}
                            anchorSelect={
                                isHomePage || isEditPersistentModeOpen ? '.tooltip-location' : '.tooltip-address'
                            }
                            zIndex={isEditView ? 55 : 5}
                            offset={isHomePage || isEditPersistentModeOpen ? 7 : 22}
                            handleTooltipClose={handleLocationTooltipClose}>
                            {coachmarkData?.creditlocationtooltip ||
                                intl.formatMessage({ id: 'coachmark:rental-location' })}
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(Cap);
