import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { ENTER_KEY } from '../../../../constants/screenConstants';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import useAnalytics from '../../../../hooks/useAnalytics';
import Error from '../../../../resources/images/error.svg';
import { useFilterState } from '../../../cap';
import AutoCompleteDropdown from '../../atoms/autoComplete/AutoCompleteDropdown';
import { DELAY_TIME } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import { getLocations } from './api/getLocations';
import './locationAutocomplete.scss';
import { RESET_SUGGESTED_JOBSITE_ADDRESS} from '../../../cap/constants';

const LocationAutocomplete = props => {
    const intl = useIntl();
    const { sendEventsForPageUserError } = useAnalyticsContext();
    const {
        prefilledAddress,
        onSelectAddress,
        handleLocationChange,
        selectedAddress,
        placeholderText,
        buttonTestId,
        errorFromLocationAutocomplete,
        onReset,
        customCountryCode,
        clearField,
        showSearchIcon,
        locationAutocompleteWrapper,
        onSearchIconClick,
        errorMessage,
        onClearingAddress,
        isLocationAvailable,
        showAddressLineOneOnly,
        listItemTestId,
        isMinicart,
        source,
        showResetBtn,
        isManualAddress,
        ariaLabel,
        baseSuggesstionsByUserLocation,
        isFullAddress
    } = props;
    const [autoSuggestionList, setAutoSuggestionList] = useState([]);
    const [autocompleteText, setAutocompleteText] = useState(prefilledAddress || '');
    const [selectedPlaceId, setSelectedPlaceId] = useState('');
    const [streetError, setStreetError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [{ cart, cartOverlay, userInfo, userAccount }, dispatch] = useCartState();
    const { accountNumber } = userAccount || {};
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const [{ viewCart }] = useFilterState();
    const [{ handleAnalyticsOnFocus }] = useAnalytics();
    const location = cart?.location;
    const validStateList = ENV_CONFIG.VALID_STATE_LIST;
    const [
        { addressSuggestions },
        filterDispatch,
        { getJobsitesAddressSuggestion }
    ] = useFilterState();
    const suggestions = isFullAddress ? addressSuggestions : autoSuggestionList;
    let debouncedFetchLocation = null;

    const isStateValid = state => {
        if (validStateList?.includes(state)) {
            return true;
        } else {
            return false;
        }
    };

    const computeRestrictedList = list => {
        return list?.filter(prediction => {
            if (!isCAAddress(prediction)) {
                return true;
            }
            let isValid = prediction?.terms?.map(item => {
                return isStateValid(item?.value);
            });
            if (isValid?.includes(true)) {
                return true;
            } else {
                return false;
            }
        });
    };

    const isCAAddress = prediction => {
        const country = prediction?.terms?.slice(-1)?.[0].value?.toLowerCase();
        return country == VARIABLE_CONFIG.REGION.CANADA;
    };

    const getLocationCoordinates = () => {
        if (!baseSuggesstionsByUserLocation) {
            return {};
        }
        const { localLat = '', localLong = '' } = fetchLocationCoordinates();
        let lat = localLat;
        let long = localLong;
        if (!localLat) {
            lat = ENV_CONFIG.DEFAULT_AUTOSUGGEST_LAT;
            long = ENV_CONFIG.DEFAULT_AUTOSUGGEST_LONG;
        }
        const locationCoordinates = { lat, long };
        return locationCoordinates;
    };
    const fetchLocationAPI = async () => {
        const response = await getLocations(
            autocompleteText,
            customCountryCode,
            isLocationAvailable,
            clearField,
            getLocationCoordinates()
        );
        if (!response.error) {
            const res = response?.data;
            let computedSuggestionsList = res?.predictions;
            computedSuggestionsList = computeRestrictedList(res?.predictions);
            setAutoSuggestionList(computedSuggestionsList);
            errorFromLocationAutocomplete && errorFromLocationAutocomplete(false);
        } else {
            errorFromLocationAutocomplete && errorFromLocationAutocomplete(true);
            setStreetError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
            try {
                sendEventsForPageUserError(VARIABLE_CONFIG.MISCELLANEOUS.LOCATION_FIELD_ERROR, cartOverlay, [
                    response.error
                ]);
            } catch (error) {
                logError(error, false, 'fetchLocationAPI');
            }
        }
    };

    useEffect(() => {
        setAutocompleteText('');
        setAutoSuggestionList([]);
        filterDispatch({ type: RESET_SUGGESTED_JOBSITE_ADDRESS });
    }, [clearField]);

    useEffect(() => {
        setStreetError(errorMessage);
    }, [errorMessage]);

    const callLocationAPI = () => {
        if (isFullAddress) {
            getJobsitesAddressSuggestion(accountNumber, autocompleteText, autocompleteText, isLocationAvailable);
        } else {
            fetchLocationAPI();
        }
    };

    useEffect(() => {
        if (autocompleteText.length > 1 && (isManualAddress || autocompleteText !== prefilledAddress)) {
            if (autocompleteText.length === 2) {
                callLocationAPI();
            } else {
                debouncedFetchLocation = setTimeout(() => {
                    callLocationAPI();
                }, DELAY_TIME);
                return () => {
                    clearTimeout(debouncedFetchLocation);
                };
            }
        } else if (autocompleteText.length === 0) {
            setStreetError('');
        }
    }, [autocompleteText]);

    useEffect(() => {
        setAutocompleteText(prefilledAddress);
    }, [prefilledAddress]);

    useEffect(() => {
        if (selectedPlaceId !== '') {
            onSelectAddress && onSelectAddress(selectedPlaceId);
        }
    }, [selectedPlaceId]);

    const onChangeInput = e => {
        setSelectedPlaceId('');
        if (e.target.value.length > 0) {
            setIsOpen(true);
        } else if (e.target.value.length == 0) {
            setIsOpen(false);
        }
        setStreetError('');
        setAutocompleteText(e.target.value);
        handleLocationChange && handleLocationChange(e.target.value);
    };
    const onPressAutoCompleteList = async (e, val, placeId) => {
        setIsOpen(false);
        selectedAddress && selectedAddress(val);
        if (showAddressLineOneOnly) {
            setAutocompleteText(val?.split(',')[0]);
        } else {
            setAutocompleteText(val);
        }
        setSelectedPlaceId(placeId);
    };

    //function to switch between suggested addresses on up/down arrow key press
    const handleKeyboardNavigation = (e, placeId) => {
        const listItems = document.querySelectorAll('.listItems');
        if (e.keyCode === 40 && e.target.tagName.toLowerCase() === 'input') {
            listItems[0]?.focus();
        }
        if (e.keyCode === 38 || e.keyCode === 40) {
            const currentFocusedAddress = suggestions?.filter(item => item.placeId === placeId)[0];
            let indexOfCurrentFocusedAddress = suggestions?.indexOf(currentFocusedAddress);
            if (
                (indexOfCurrentFocusedAddress === 0 && e.keyCode === 38) ||
                (indexOfCurrentFocusedAddress === suggestions?.length - 1 && e.keyCode === 40)
            ) {
                //continue
            } else {
                if (e.keyCode === 38) {
                    document.getElementById(suggestions[indexOfCurrentFocusedAddress - 1]?.placeId).focus();
                } else {
                    document.getElementById(suggestions[indexOfCurrentFocusedAddress + 1]?.placeId).focus();
                }
            }
        }
    };
    const onClearingLocation = () => {
        if (onClearingAddress) {
            onReset();
            onClearingAddress();
        }
        if (isMinicart) {
            setAutocompleteText(prefilledAddress);
        }
    };

    const onResetBtnClick = e => {
        setStreetError('');
        onReset();
        setAutocompleteText('');
        handleLocationChange && handleLocationChange(e.target.value);
    };

    const onEnterKeyPressed = (e, item) => {
        if (e.key === ENTER_KEY) {
            onPressAutoCompleteList(e, item?.description, item?.placeId);
        }
    };

    const renderSuggestion = (item, index) => {
        return (
            isOpen && (
                <div
                    key={item?.placeId}
                    tabIndex={0}
                    className="listItems"
                    onClick={e => onPressAutoCompleteList(e, item?.description, item?.placeId)}
                    onKeyPress={e => onEnterKeyPressed(e, item)}
                    data-testid={listItemTestId}
                    onKeyDown={e => handleKeyboardNavigation(e, item?.placeId)}>
                    <li
                        tabIndex={-1}
                        key={item?.placeId}
                        id={item?.placeId}
                        onKeyPress={e => onEnterKeyPressed(e, item)}
                        data-testid={buttonTestId}
                        role="option"
                        aria-label={`${intl.formatMessage({
                            id: 'account:ac-common-suggested-msg'
                        })} ${item?.description}`}>
                        {item?.description}
                    </li>
                </div>
            )
        );
    };

    const setOpenDropDown = () => {
        setIsOpen(false);
    };

    const getPlaceholderText = ()=>{
        if (placeholderText) {
            return placeholderText;
        } else if (!isFullAddress) {
            return intl.formatMessage({ id: 'cart-header:location-autocomplete-placeholder' })
        } else {
            return '';
        }
    }

    return (
        <>
            <AutoCompleteDropdown
                inputId={'locationAddressBranch'}
                placeholderText={
                    getPlaceholderText()
                }
                onClearingText={onClearingLocation}
                showResetBtn={showResetBtn}
                onChange={onChangeInput}
                onSelect={setOpenDropDown}
                onReset={onResetBtnClick}
                showSearchIcon={showSearchIcon}
                isDropDownOpen={isOpen}
                renderSuggestion={renderSuggestion}
                suggestions={suggestions}
                value={autocompleteText}
                autoCompleteWrapperClass={`location-autocomplete ${locationAutocompleteWrapper}`}
                autoCompleteInputClass={'cmp-Field__field__input'}
                customOnSearchClick={val => {
                    onSearchIconClick && onSearchIconClick(val);
                }}
                source={source}
                searchButtonLabel={intl.formatMessage({
                    id: 'location:search-by-address-text'
                })}
                aria-label={ariaLabel || intl.formatMessage({ id: 'cart-header:location-autocomplete-placeholder' })}
                handleEcomLocationAnalyticsOnFocus={() => handleAnalyticsOnFocus(source)}
            />
            {streetError ? (
                <div className="location__errorContainer" tabIndex="0" aria-live="assertive" role="alert">
                    <Error tabIndex={'-1'} aria-hidden={true} />
                    <p className="location__errorText">{streetError}</p>
                </div>
            ) : null}
        </>
    );
};

LocationAutocomplete.propTypes = {
    locationAutocompleteWrapper: PropTypes.string,
    prefilledAddress: PropTypes.string,
    inputHeading: PropTypes.string,
    showResetBtn: PropTypes.bool,
    showSearchIcon: PropTypes.bool,
    handleLocationChange: PropTypes.func,
    onSearchIconClick: PropTypes.func,
    onReset: PropTypes.func,
    placeholderText: PropTypes.string,
    errorFromLocationAutocomplete: PropTypes.func,
    inputAriaRequired: PropTypes.bool,
    customParentClass: PropTypes.string,
    customCountryCode: PropTypes.string,
    clearField: PropTypes.bool,
    errorMessage: PropTypes.string,
    isLocationAvailable: PropTypes.bool,
    showAddressLineOneOnly: PropTypes.bool,
    listItemTestId: PropTypes.string,
    source: PropTypes.string,
    ariaLabel: PropTypes.string,
    baseSuggesstionsByUserLocation: PropTypes.bool,
    isFullAddress: PropTypes.bool
};

LocationAutocomplete.defaultProps = {
    locationAutocompleteWrapper: '',
    prefilledAddress: '',
    inputHeading: '',
    showResetBtn: false,
    showSearchIcon: false,
    handleLocationChange: () => {},
    onSearchIconClick: () => {},
    onReset: () => {},
    placeholderText: '',
    inputAriaRequired: false,
    customParentClass: '',
    customCountryCode: '',
    errorMessage: '',
    isLocationAvailable: true,
    showAddressLineOneOnly: true,
    listItemTestId: '',
    isMinicart: false,
    source: '',
    ariaLabel: '',
    baseSuggesstionsByUserLocation: false,
    isFullAddress: false
};

export default LocationAutocomplete;
