import { string, func, bool } from 'prop-types';
import React, { useState } from 'react';
import { useCartState } from '../../aem-core-components/components/Minicart';
import Button from '../global/atoms/button/button';
import SearchForm from './SearchForm';
import FilterIcon from '../../resources/images/filter.svg';
import LocationNavigation from '../../resources/images/location-navigation.svg';
import { locationDataLocators } from './dataLocators';
import { useIntl } from 'react-intl';
import { useLocationState } from '../../contexts/location/locationContext';
import { ENV_CONFIG } from '../../constants/envConfig';
import { SET_ALL_STORES } from '../../contexts/location/actions';

const LocationTop = props => {
    const {
        wrapClass,
        getCurrentLocation,
        getStores,
        isCurrentLocationAllowed,
        currentAddress,
        setOpenFilterHandle,
        currentCountry,
        setIsUserLocationUsed,
        isUserLocationUsed,
        setIsCurrentLocationAllowed,
        setuserLatLong,
        userAddress,
        setUserAddress,
        locationDeniedError,
        setLocationDeniedError,
        onClearingAddress,
        searchByBranch,
        isBranchSearchError,
        setIsBranchSearchError,
        isSearchByBranch,
        setIsSearchByBranch
    } = props;
    const [streetAddress, setStreetAddress] = useState('');
    const [{ userInfo }] = useCartState();
    const [{ numberOfFilters, allStoreLocationsBeforeFilters }, dispatch] = useLocationState();

    const intl = useIntl();
    const companyIdLocationPlaceholder = JSON?.parse(localStorage?.getItem('companyID'));
    const handleLocationPlaceholder = () => {
        if (
            companyIdLocationPlaceholder == 0 ||
            companyIdLocationPlaceholder === 1 ||
            companyIdLocationPlaceholder === null
        ) {
            return ENV_CONFIG.locationUSplaceholderText || intl.formatMessage({ id: 'location:search-placeholder' });
        } else {
            return (
                ENV_CONFIG.locationCAplaceholderText || intl.formatMessage({ id: 'location:canada-search-placeholder' })
            );
        }
    };

    const LocationTitle = () => {
        return (
            <h1
                className="location__title"
                data-testid={locationDataLocators.location_landing_find_a_location}
                tabIndex={'0'}>
                {ENV_CONFIG.locationSearchBoxTitle || intl.formatMessage({ id: 'location:find-a-location' })}
            </h1>
        );
    };
    const handleBranchSwitch = () => {
        setIsSearchByBranch(!isSearchByBranch);
        onClearingAddress();
        setStreetAddress('');
        dispatch({
            type: SET_ALL_STORES,
            allStoreLocations: allStoreLocationsBeforeFilters
        });
        setIsBranchSearchError(false);
    };
    return (
        <div className={`location__aside-top ${wrapClass}`}>
            <LocationTitle />
            <SearchForm
                placeholderText={handleLocationPlaceholder()}
                locationDeniedError={locationDeniedError}
                getStores={getStores}
                dispatch={dispatch}
                isCurrentLocationAllowed={isCurrentLocationAllowed}
                currentAddress={currentAddress}
                getCustomCountryCode={!userInfo?.isAuthenticatedUser && isCurrentLocationAllowed ? currentCountry : ''}
                setIsUserLocationUsed={setIsUserLocationUsed}
                isUserLocationUsed={isUserLocationUsed}
                setIsCurrentLocationAllowed={setIsCurrentLocationAllowed}
                setuserLatLong={setuserLatLong}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                setLocationDeniedError={setLocationDeniedError}
                onClearingAddress={onClearingAddress}
                searchByBranch={searchByBranch}
                isBranchSearchError={isBranchSearchError}
                setIsBranchSearchError={setIsBranchSearchError}
                isSearchByBranch={isSearchByBranch}
                streetAddress={streetAddress}
                setStreetAddress={setStreetAddress}
            />
            <span>
                <span>{intl.formatMessage({ id: 'or-text' })}</span>
                <Button
                    className="button button-outline-primary button-pb0"
                    onClick={handleBranchSwitch}
                    buttonAriaLabel={`${
                        isSearchByBranch
                            ? intl.formatMessage({ id: 'search-by-address' })
                            : intl.formatMessage({ id: 'search-by-branch-number' })
                    }`}
                    data-testid={locationDataLocators.location_landing_search_by_branch_btn}>
                    {isSearchByBranch
                        ? intl.formatMessage({ id: 'search-by-address' })
                        : intl.formatMessage({ id: 'search-by-branch-number' })}
                </Button>
            </span>
            {!isSearchByBranch && (
                <div className="location__filter-wrap">
                    <div className="filter_wrap">
                        <Button
                            className="button button-square button_iconleft button-medium"
                            onClick={setOpenFilterHandle}
                            buttonAriaLabel={`${intl.formatMessage({ id: 'location:filter-icon' })}`}>
                            <FilterIcon data-testid={locationDataLocators.location_landing_filter_icon} />
                        </Button>
                        {!numberOfFilters && (
                            <span 
                                onClick={setOpenFilterHandle}
                                data-testid={locationDataLocators.location_landing_filter_text}
                            >
                                {intl.formatMessage({ id: 'location:filter-text' })}
                            </span>
                        )}
                        {numberOfFilters > 0 && (
                            <span
                                aria-live="polite"
                                onClick={setOpenFilterHandle}
                                className="filterTextGreen"
                                data-testid={locationDataLocators.location_landing_filter_text}>
                                {intl.formatMessage({ id: 'location:filter-text' })}
                                {` (${numberOfFilters})`}
                            </span>
                        )}
                    </div>
                    <Button
                        onClick={getCurrentLocation}
                        className="button button-outline-primary button-medium location__current"
                        data-testid={locationDataLocators.location_landing_current_location_cta}
                        buttonAriaLabel={`${intl.formatMessage({ id: 'location:current-location-text' })}`}>
                        <LocationNavigation className="button_iconleft" />
                        {intl.formatMessage({ id: 'location:current-location-text' })}
                    </Button>
                </div>
            )}
        </div>
    );
};
LocationTop.defaultProps = {
    wrapClass: '',
    getCurrentLocation: () => {},
    getStores: () => {},
    isCurrentLocationAllowed: false,
    setOpenFilterHandle: () => {},
    currentAddress: '',
    setZoomOutMax: () => {},
    getCustomCountryCode: () => {},
    setIsUserLocationUsed: () => {},
    isUserLocationUsed: false,
    setIsCurrentLocationAllowed: () => {},
    setuserLatLong: () => {},
    userAddress: '',
    setUserAddress: () => {},
    locationDeniedError: false,
    setLocationDeniedError: () => {},
    onClearingAddress: () => {},
    searchByBranch: () => {},
    isBranchSearchError: false,
    setIsBranchSearchError: () => {},
    isSearchByBranch: false
};

LocationTop.propTypes = {
    wrapClass: string,
    getCurrentLocation: func,
    getStores: func,
    isCurrentLocationAllowed: bool,
    setOpenFilterHandle: func,
    currentAddress: string,
    setZoomOutMax: func,
    getCustomCountryCode: func,
    setIsUserLocationUsed: func,
    isUserLocationUsed: bool,
    setIsCurrentLocationAllowed: func,
    setuserLatLong: func,
    userAddress: string,
    setUserAddress: func,
    locationDeniedError: bool,
    setLocationDeniedError: func,
    onClearingAddress: func,
    searchByBranch: func,
    isBranchSearchError: bool,
    setIsBranchSearchError: func,
    isSearchByBranch: bool
};

export default LocationTop;
