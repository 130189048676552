import React from 'react';
import { string } from 'prop-types';
import { locationDataLocators } from './dataLocators';

const renderText = (description) => {
    return (
        <div
        tabIndex={'0'}
        className='info-desc'
            dangerouslySetInnerHTML={{
                __html: description || document.querySelector('.storeLocationsConfig')?.dataset?.supportText
            }}
            data-testid={locationDataLocators.location_landing_current_call_us_text}
        />
    );
};

const InfoMessage = ({ title,description,wrapperClasses }) => {
    return (
        <div className={`info-message ${wrapperClasses}`} aria-live='polite' tabIndex={'0'}>
            <span
                tabIndex={'0'}
                className="info-heading"
                data-testid={locationDataLocators.location_landing_current_we_will_deliver_text}>
                {title}
            </span>
            {renderText(description)}
            <small className="info-description"></small>
        </div>
    );
};

export default InfoMessage;

InfoMessage.defaultProps = {
    title: '',
    descriptionPrefix: '',
    descriptionSuffix: '',
    phonenumber: 0,
    description: '',
    wrapperClasses:''
};

InfoMessage.propsType = {
    title: string,
    descriptionPrefix: string,
    descriptionSuffix: string,
    phonenumber: string,
    description: string,
    wrapperClasses: string
};
