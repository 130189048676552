import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useFilterState } from '../../../cap';
import useMedia from '../../../../hooks/useMedia';
import SideDrawerModal from '../modals/SideDrawerModal';
import Button from '../../atoms/button/button';
import ProjectSearch from '../../../../aem-core-components/components/Minicart/locations/projectListing/ProjectSearch';
import Listing from '../../../../aem-core-components/components/Minicart/locations/projectListing/Listing';
import CardRadioButton from '../../atoms/cardRadioButton/cardRadioButton';
import { LoadingIndicator } from '../../../../aem-core-components';
import { getProjectsFromCookie } from '../../utils/commonUtils';
import ArrowLeftSvg from '../../../../resources/images/arrow-ios-left.svg';
import CloseIcon from '../../../../resources/images/close.svg';
import CloseCircleIcon from '../../../../resources/images/close-circle.svg';
import './jobsitesListingModal.scss';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';

const JobsitesListingModal = ({ isModalOpen, onModalClose, onJobsiteSelected, selectedProjectId }) => {
    const intl = useIntl();
    const [{ accountProjects, isProjectsLoading, isJobSiteLoaded }, { dispatch, getAccountProjectsDotCom }] = useUserContext();
    const projectInfoCookies = getProjectsFromCookie();
    const [{ projectDetails, recommendedJobsites }] = useFilterState();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedJobsite, setSelectedJobsite] = useState(null);
    const [searchedJobsite, setSearchedJobsite] = useState([]);
    const [showProjectSearch, setShowProjectSearch] = useState(false);
    const mediaType = useMedia();

    useEffect(() => {
        try {
            const searchedStoredKeyword = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.JOBSITE_SEARCH_KEYWORD);
            if (!sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED)) {
                dispatch({ type: 'beginProjectsLoading' });
                getAccountProjectsDotCom(projectInfoCookies?.CurrentWynneAccount);
            } else if (searchedStoredKeyword?.length > 2) {
                /** when jobsite search keyword is present in local storage, ProjectSearch calls getAccountProjectsDotCom api
                 *  and when jobsite search keyword length <= 2, the account project is not filtered
                 *  so setting loading as true when keyword is present and length > 2
                 */
                dispatch({ type: 'beginProjectsLoading' });
                dispatch({ type: 'setAccountProjects', accountProjects: [] });
            }
        } catch (err) {
            dispatch({ type: 'endProjectsLoading' });
            logError(err, false, 'JobsitesListingModal - useEffect', []);
        }
    }, []);

    useEffect(() => {
        const selectedjobsiteInList =
            accountProjects?.find(data => {
                return data?.jobNumber == projectDetails?.selectedProjectJobId;
            }) || null;
        if (!selectedjobsiteInList) {
            const projectInfoCookies = getProjectsFromCookie();
            const {
                CurrentWynneAccount,
                CurrentWynneAccountCID,
                SLLat,
                SLLong,
                SLA,
                SLC,
                SLS,
                SLZ,
                CurrentJobSite,
                locationPC,
                projectName,
                projectAddress2,
                primaryContactName,
                phoneNumber,
                accessNotes,
                poNumber
            } = projectInfoCookies || {};

            const selProjectData = {
                jobNumber: CurrentJobSite,
                jobName: projectName,
                account: CurrentWynneAccount,
                companyID: CurrentWynneAccountCID,
                address1: SLA,
                address2: projectAddress2,
                city: SLC,
                state: SLS,
                zip: SLZ,
                latitude: SLLat,
                longitude: SLLong,
                isGeoCoded: true, //TODO-unavailable
                locationPC,
                salesRepId: '', //TODO-unavailable
                isActive: true,
                jobKey: CurrentJobSite,
                contactName: primaryContactName,
                contactPhone: phoneNumber,
                hasEquipment: false, //TODO-unavailable
                equipmentCount: 0, //TODO-unavailables
                deliveryInstructions: accessNotes,
                customerPO: poNumber
            };
            if (searchQuery) {
                setSearchedJobsite(accountProjects);
            } else {
                setSearchedJobsite([selProjectData, ...accountProjects]);
            }
        } else {
            let removedItem;
            let tempProjectList;

            if (projectDetails?.selectedProjectJobId) {
                removedItem = accountProjects?.find(data => {
                    return data?.jobNumber == projectDetails?.selectedProjectJobId;
                });

                if (removedItem) {
                    tempProjectList = accountProjects?.filter(data => {
                        return data?.jobNumber != projectDetails?.selectedProjectJobId;
                    });
                    tempProjectList.unshift(removedItem);
                    setSearchedJobsite(tempProjectList);
                }
            }
        }
    }, [accountProjects]);

    useEffect(() => {
        setSelectedValue(selectedProjectId);
    }, [selectedProjectId]);

    useEffect(() => {
        if (
            accountProjects?.length > 10 ||
            searchQuery ||
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.JOBSITE_SEARCH_KEYWORD)
        ) {
            setShowProjectSearch(true);
        } else {
            setShowProjectSearch(false);
        }
    }, [accountProjects, searchQuery]);

    const onChooseJobsiteHandler = () => {
        onJobsiteSelected(selectedJobsite);
    };

    const jobsiteDrawerHeading = () => {
        return mediaType !== MEDIA_TYPE.DESKTOP ? (
            <>
                <Button
                    className="back-icon"
                    onClick={onModalClose}
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    tabindex="0">
                    <ArrowLeftSvg aria-hidden={true} />

                    {intl.formatMessage({ id: 'common:back' })}
                </Button>

                <Button
                    className="popup__closeicon"
                    onClick={onModalClose}
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    tabindex="0">
                    <CloseIcon aria-hidden={true} />
                </Button>
            </>
        ) : (
            <>
                <h5>{intl.formatMessage({ id: 'cap:choose-a-jobsite' })}</h5>
                <Button
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    onClick={onModalClose}>
                    <CloseIcon />
                </Button>
            </>
        );
    };

    const drawerFooter = () => {
        return (
            <Button
                className="modal-footer-button"
                buttonAriaLabel={intl.formatMessage({ id: 'cap:choose-this-jobsite' })}
                onClick={onChooseJobsiteHandler}>
                {intl.formatMessage({ id: 'cap:choose-this-jobsite' })}
            </Button>
        );
    };

    const cardStyle = data => {
        return (
            <div className="card">
                {selectedValue === data?.jobNumber && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <small className="card__title">{data?.jobName}</small>
                <small className="card__content">{data?.address1}</small>
                <small>
                    {data?.city}, {data?.state} {data?.zip}
                </small>
            </div>
        );
    };

    const displaySearchResults = data => {
        return (
            data?.jobName && (
                <div key={data?.jobName} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={item => setSelectedJobsite(item)}
                        // isAccount={false}
                        radioButtonAriaLabel={data?.jobName}
                    />
                </div>
            )
        );
    };

    const drawerContent = () => (
        <>
            {isProjectsLoading && !accountProjects?.length && !searchQuery && (
                <div className="jobsite-drawer-content__loader-wrapper">
                    <LoadingIndicator />
                </div>
            )}
            {mediaType !== MEDIA_TYPE.DESKTOP && (
                <h5 className="jobsites-list-title-text">{intl.formatMessage({ id: 'cap:choose-a-jobsite' })}</h5>
            )}
            {showProjectSearch && (
                <div className="search-jobsite">
                    <ProjectSearch
                        searchQuery={searchQuery}
                        onSearchQueryChange={setSearchQuery}
                        projectList={searchedJobsite}
                        placeholder={intl.formatMessage({ id: 'cap:search-jobsite' })}
                        closeIcon={<CloseCircleIcon />}
                        isSearchIconVisible
                    />
                </div>
            )}
            {!searchQuery && recommendedJobsites?.length > 0 && accountProjects?.length > 10 && (
                <section className="jobsites-list-section">
                    <h6>{intl.formatMessage({ id: 'cap:suggested-jobsites' })}</h6>
                    <Listing projectList={recommendedJobsites} displaySearchResults={displaySearchResults} />
                </section>
            )}
            {(accountProjects?.length > 0 || searchQuery) && (
                <section className="jobsites-list-section">
                    <h6>
                        {searchQuery
                            ? intl.formatMessage({ id: 'cap:matching-jobsites' })
                            : intl.formatMessage({ id: 'cap:all-jobsites' })}
                    </h6>
                    <Listing
                        projectList={searchedJobsite}
                        displaySearchResults={displaySearchResults}
                        isProjectsLoading={isProjectsLoading}
                    />
                    {searchedJobsite?.length === 0 && !isJobSiteLoaded && (
                        <p className="no-search">{intl.formatMessage({ id: 'cap:no-jobsite-found' })}</p>
                    )}
                </section>
            )}
            {accountProjects?.length === 0 && !showProjectSearch && !isProjectsLoading && (
                <section className="jobsites-list-section">
                    <p className="no-account-projects">
                        {intl.formatMessage({ id: 'cap:delinquent-account-no-jobsite-found' })}
                    </p>
                </section>
            )}
        </>
    );

    return (
        <SideDrawerModal
            modalHeaderClass={mediaType !== MEDIA_TYPE.DESKTOP && 'create-modal-header'}
            header={jobsiteDrawerHeading()}
            isModalOpen={isModalOpen}
            footer={drawerFooter()}
            content={drawerContent()}
            modalContentClass={`jobsite-drawer-content`}
            handleModalToggle={onModalClose}
        />
    );
};

JobsitesListingModal.propTypes = {
    isModalOpen: bool,
    onModalClose: func,
    onJobsiteSelected: func,
    selectedProjectId: string
};

JobsitesListingModal.defaultProps = {
    isModalOpen: true,
    onModalClose: () => {},
    onJobsiteSelected: () => {},
    selectedProjectId: ''
};

export default React.memo(JobsitesListingModal);
