import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useMedia from '../../../hooks/useMedia';
import { isValidString } from '../../global/utils/logger';
import { dynamicURLs, filterState } from '../constants';
import { useFilterState } from '../../cap';

const useLLP = () => {
    const [{ isCreditNewAddress }] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const [userState, { getProjectsFromCookie }] = useUserContext();
    const { userProfile } = userState;
    const userTypeCookie = useCheckUser();
    const intl = useIntl();
    const mediaType = useMedia();
    const projectInfoCookies = getProjectsFromCookie();

    const formatUrl = text => {
        return text?.replace(/\s+/g, '-')?.toLowerCase();
    };

    const getStoreNameFromFilterState = storeName => {
        // get the Store names from AEM obj based on search keys
        for (let key in filterState) {
            if (filterState[key]?.searchKey?.includes(storeName)) {
                return key;
            }
        }
        return intl.formatMessage({ id: 'location-card:general-equipText' }); // if it doesnt match any from the list, default title
    };
    const getDynamicURL = storeName => {
        // get the URLS from AEM obj
        let url = '';
        if (dynamicURLs[storeName?.trim()]) {
            url = dynamicURLs[storeName?.trim()];
        } else {
            url = getStoreNameFromFilterState(storeName?.trim()); // use existing logic if store isnt in AEM div
        }
        return encodeURIComponent(formatUrl(url?.split('&')?.join('')));
    };
    const formatSavedStoreDetailsData = (item, dow) => {
        let formattedDetails = {};
        let specialityForUrl = '';
        if (item?.pc >= 5000 && item?.pc <= 5099) {
            specialityForUrl = getDynamicURL(
                intl.formatMessage({ id: 'location-card:sunbelt-rentals-tool-rentalText' })
            );
        } else if (item?.specialties && item?.specialties?.length > 0) {
            specialityForUrl = getDynamicURL(item?.specialties[0]);
        } else {
            specialityForUrl = getDynamicURL(intl.formatMessage({ id: 'location-card:general-equipText' }));
        }
        if (item?.pc) {
            formattedDetails = {
                pc: item?.pc,
                name: getStoreName(item),
                street: item?.street,
                city: item?.city,
                state: item?.state,
                zip: item?.zip,
                distance: item?.distance,
                email: item?.email,
                phone: item?.phone,
                hours: item?.operatingHours[dow]?.hours ? item?.operatingHours[dow]?.hours : CLOSED,
                lat: item?.latitude,
                long: item?.longitude,
                companyId: item?.companyId || 1,
                specialtyUrl: specialityForUrl,
                branchText: `${intl.formatMessage({ id: 'location-card:branch-text' })}${item?.pc}`
            };
        }
        return formattedDetails;
    };
    const getTwoNearbyStores = (selectedPC, distance, storeLocations, dow) => {
        const list = storeLocations?.data?.pcList;
        let closestStore = {};
        let secondClosest = {};
        let distance1 = 200;
        let distance2 = 200;

        list?.map(item => {
            if (item?.pc !== selectedPC) {
                if (Math.abs(item?.distance - distance) < distance1) {
                    secondClosest = closestStore;
                    distance2 = distance1;
                    closestStore = item;
                    distance1 = Math.abs(item?.distance - distance);
                } else if (Math.abs(item?.distance - distance) < distance2) {
                    secondClosest = item;
                    distance2 = Math.abs(item?.distance - distance);
                }
            }
        });
        return {
            closestStore: formatSavedStoreDetailsData(closestStore, dow),
            secondClosest: formatSavedStoreDetailsData(secondClosest, dow)
        };
    };
    const setMinZoom = () => {
        if (mediaType === 'DESKTOP') {
            return 4;
        }
        if (mediaType === 'TABLET') {
            return 3;
        }
        return 2;
    };
    const setDefZoom = (zoomOutMax, zoom) => {
        if (zoomOutMax) {
            if (mediaType === 'DESKTOP') {
                return 4;
            }
            if (mediaType === 'TABLET') {
                return 3;
            }
            return 2;
        } else if (zoom < 7) {
            return 7;
        }
        return '';
    };
    const latSelection = () => {
        if (userTypeCookie === USER_TYPE.CREDIT && !isCreditNewAddress) {
            if (projectDetails?.selectedProjectLatititude && isValidString(projectDetails?.projectName)) {
                return projectDetails.selectedProjectLatititude;
            } else if (projectInfoCookies?.SLA) {
                return parseFloat(projectInfoCookies?.SLLat);
            } else {
                return '';
            }
        } else if (viewCart?.lat) {
            return viewCart?.lat;
        } else {
            return userProfile?.addressLatitude;
        }
    };

    const longSelection = () => {
        if (userTypeCookie === USER_TYPE.CREDIT && !isCreditNewAddress) {
            if (projectDetails?.selectedProjectLongitude && isValidString(projectDetails?.projectName)) {
                return projectDetails.selectedProjectLongitude;
            } else if (projectInfoCookies?.SLA) {
                return parseFloat(projectInfoCookies?.SLLong);
            } else {
                return '';
            }
        } else if (viewCart?.long) {
            return viewCart?.long;
        } else {
            return userProfile?.addressLongitude;
        }
    };
    const getStoreName = item => {
        if (item?.pc >= 5000 && item?.pc <= 5099) {
            return intl.formatMessage({ id: 'location-card:sunbelt-rentals-tool-rentalText' });
        } else if (item?.specialties) {
            return getStoreNameFromFilterState(item?.specialties[0]?.trim());
        } else {
            return intl.formatMessage({ id: 'location-card:general-equipText' }); // default title
        }
    };
    const isDistanceVisible = distance => {
        return distance && distance !== -1;
    };
    const distanceText = () => {
        const companyID = parseInt(localStorage.getItem('companyID')) || 1;
        if (companyID === 2) {
            return intl.formatMessage({ id: 'location:km-text' });
        }
        return intl.formatMessage({ id: 'location:miles-text' });
    };
    return {
        formatUrl,
        getStoreNameFromFilterState,
        getDynamicURL,
        formatSavedStoreDetailsData,
        getTwoNearbyStores,
        setMinZoom,
        setDefZoom,
        latSelection,
        longSelection,
        getStoreName,
        isDistanceVisible,
        distanceText
    };
};
export default useLLP;
