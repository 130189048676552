import moment from 'moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../aem-core-components';
import { SET_STORE_LOCATIONS } from '../../aem-core-components/actions/constants';
import { useCartState } from '../../aem-core-components/components/Minicart';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import isObjectEmpty from '../../aem-core-components/utils/isObjectEmpty';
import { ENV_CONFIG } from '../../constants/envConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import {
    SET_ALL_STORES,
    SET_ALL_STORE_LOCATIONS_BEFORE_FILTERS,
    SET_IS_ALL_STORES_DATA_PRESENT,
    SET_NUMBER_OF_FILTERS,
    SET_SPECIALTY_FILTER_DESCRIPTONS,
    SET_STORE_LOCATIONS_BEFORE_FILTERS
} from '../../contexts/location/actions';
import { useLocationState } from '../../contexts/location/locationContext';
import { useCheckUser } from '../../hooks/useCheckUser';
import useMedia from '../../hooks/useMedia';
import Plus from '../../resources/images/plus.svg';
import useDst from '../dstTrainer/hooks/useDst';
import { DENIED, GEOLOCATION } from '../global/atoms/GetGeoLocation/constants';
import Button from '../global/atoms/button';
import { MEDIA_TYPE } from '../global/constants';
import { getAddressBylatLong } from '../global/modules/location-autocomplete/api/getLocations';
import { isWebView, trapFocus } from '../global/utils/commonUtils';
import { logError } from '../global/utils/logger';
import { getFilterSpecialtyData, getStoreDetailsFromPC, getStoreLocations } from './API/getStoreLocations';
import InfoMessage from './InfoMessage';
import LocationCard from './LocationCard';
import LocationTop from './LocationTop';
import { DEFAULT_LAT, DEFAULT_LONG, SHOW_MORE_LIMIT, TIER_2_RADIUS } from './constants';
import useLLP from './hooks/useLLP';
import './location.scss';
import LocationDetailDrawer from './locationDetailDrawer/LocationDetailDrawer';
import { LocationFilter } from './locationFilter/LocationFilter';
import LocationLegend from './locationLegend/LocationLegend';
import { formatCreditAddress, setLocationInStorage } from './storesLocationFunctions';
import StoresLocationMap from './storesLocationMap/StoresLocationMap';
import { useFilterState } from '../cap';

function Location() {
    const drawerRef = useRef();
    const mediaType = useMedia();
    const intl = useIntl();
    const [{ isCreditNewAddress, isLoading, cashProfileLatLongUpdated }, cartDispatch] =
        useCartState();
    const [{viewCart, projectDetails, startDate, endDate}, filterDispatch] = useFilterState();

    const [
        {
            storeLocations,
            storeLocationsBeforeFilters,
            isAllStoresDataPresent,
            allStoreLocationsBeforeFilters,
            allStoreLocations,
            numberOfFilters
        },
        dispatch
    ] = useLocationState();
    const { latSelection, longSelection } = useLLP();
    const { getDistance } = useDst();
    const [isLlpModalOpen, setIsLlpModalOpen] = useState(false);
    const [modalData, setModalData] = useState();
    const [isSticky, setIsSticky] = useState(false);
    const [allStores, setAllStores] = useState([]);
    const [storesList, setStoresList] = useState([]);
    const [visibleStoreList, setVisibleStoreList] = useState([]);
    const [currentAddress, setCurrentAddress] = useState('');
    const [currentCountry, setCurrentCountry] = useState('');
    const [isCurrentLocationAllowed, setIsCurrentLocationAllowed] = useState(false);
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedStoreValue, setSelectedStoreValue] = useState();
    const [zoomOutMax, setZoomOutMax] = useState(true);
    const [openFilter, setOpenFilter] = useState(false);
    const [isNoStoreAvailable, setIsNoStoreAvailable] = useState(false);
    const [focusedLocation, setFocusedLocation] = useState({
        lat: DEFAULT_LAT,
        long: DEFAULT_LONG
    });
    const [isUserLocationUsed, setIsUserLocationUsed] = useState(false);
    const [userLatLong, setuserLatLong] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [filterData, setFilterData] = useState({});
    const [isSearchByBranch, setIsSearchByBranch] = useState(false);
    const [refs, setRefs] = useState(React.createRef());
    const userTypeCookie = useCheckUser();
    const [locationDeniedError, setLocationDeniedError] = useState(false);
    const [userState, { getProjectsFromCookie }] = useUserContext();
    const { isUserProfileLoading, userProfile } = userState;
    const [isBranchSearchError, setIsBranchSearchError] = useState(false);
    const subfiltertitle = ENV_CONFIG.locationFilterLabel || intl.formatMessage({ id: 'location-speciality-services' });
    const locationNoBranchErrorTitle =
        ENV_CONFIG.locationNoBranchErrorTitle || intl.formatMessage({ id: 'location:no-branch-message-title' });
    const locationNoBranchErrorText = ENV_CONFIG.locationNoBranchErrorText || '';
    const locationHelpArray = locationNoBranchErrorText?.split('#');
    const locationHelpLine1 = locationHelpArray?.[0] || '';
    const locationHelpLine2 = locationHelpArray?.[1] || '';
    const locationHelpLine = ENV_CONFIG.locationHelpLine || '';
    const noStoreDescription = `${locationHelpLine1} <a href="tel:${locationHelpLine}" class='footer__top-link'>${locationHelpLine}</a> ${locationHelpLine2}`;
    const projectInfoCookies = getProjectsFromCookie();
    const locationFilterBodyRef = useRef(null);
    const locationNoBranchInRadiusTitle =
        ENV_CONFIG.LLP_TEXT_FOR_DELIVERY_MESSAGE || intl.formatMessage({ id: 'location:info-message-title' });

    const handleIsSticky = e => {
        const scrollTop = locationFilterBodyRef.current?.scrollTop;
        scrollTop >= 10 ? setIsSticky(true) : setIsSticky(false);
    };

    useEffect(() => {
        const getHeaderHeight = document.querySelector('.globalheader')?.clientHeight;
        if (mediaType === MEDIA_TYPE.mobile && getHeaderHeight) {
            window.scroll(0, getHeaderHeight);
        }
    }, []);

    useEffect(() => {
        locationFilterBodyRef.current?.addEventListener('scroll', handleIsSticky);

        return () => {
            locationFilterBodyRef.current?.removeEventListener('scroll', handleIsSticky);
        };
    }, [openFilter]);

    const setOpenFilterHandle = () => {
        setOpenFilter(prev => !prev);
    };
    useEffect(() => {
        setFilterData(initFilterData());
        dispatch({
            type: SET_NUMBER_OF_FILTERS,
            numberOfFilters: 0
        });
    }, [storeLocationsBeforeFilters?.data?.pcList]);

    const initFilterData = () => {
        const filter = {};
        filter[subfiltertitle] = {
            selectedOptions: []
        };
        return filter;
    };

    //--- useEffect for assigning store array to storeList and visibleStore
    // when any of the getStores api is called ----

    useEffect(() => {
        if (storeLocations?.data?.pcList?.length > 0) {
            setSortedList();
        } else if (userAddress || isCurrentLocationAllowed) {
            setVisibleStoreList([]);
            setStoresList([]);
            zoomOutAndFocus();
        } else {
            setVisibleStoreList([]);
            setStoresList([]);
        }
    }, [storeLocations?.data?.pcList]);

    useEffect(() => {
        if (allStoreLocations?.data?.pcList?.length > 0) {
            setAllStores(allStoreLocations?.data?.pcList);
            setTimeout(function () {
                window.prerenderReady = true;
            }, 1000);
        }
    }, [allStoreLocations?.data?.pcList]);
    const setSortedList = async () => {
        const list = [...storeLocations?.data?.pcList];
        if (!isAllStoresDataPresent) {
            // updating all stores if all stores data is not present
            setAllStores(storeLocations?.data?.pcList);
        }
        if (!userAddress && !currentAddress) {
            setVisibleStoreList([]);
            setStoresList(list?.length > 0 ? list : []);
        } else {
            let visibleList = list?.length < 10 ? list?.splice(0, list?.length) : list?.splice(0, 10);
            setVisibleStoreList(visibleList);
            setStoresList(list?.length > 0 ? list : []);
            setSelectedStoreValue(visibleList[0]?.pc);
        }
        cartDispatch({ type: 'endLoading' });

        const cardRefs = storeLocations?.data?.pcList?.reduce((acc, value) => {
            acc[value.pc] = React.createRef();
            return acc;
        }, {});
        setRefs(cardRefs);
    };

    useEffect(() => {
        if (!isUserProfileLoading && isLocationAlreadySearched()) {
            return;
        }
        setIsNoStoreAvailable(false);
        if (!isUserProfileLoading && userTypeCookie === USER_TYPE.GUEST) {
            cartDispatch({ type: 'beginLoading' });
            setIsNoStoreAvailable(false);
            getSpecialtyFilterDescriptions();
            getAllStores(dispatch);
            zoomOutAndFocus();
            setIsUserLocationUsed(false);
        }
        if (!isUserProfileLoading && userTypeCookie !== USER_TYPE.GUEST) {
            let lat = latSelection();
            let long = longSelection();

            if (lat && long && lat !== '0' && long !== '0') {
                cartDispatch({ type: 'beginLoading' });
                setIsUserLocationUsed(true);
                getStores(lat, long, dispatch);
                getSpecialtyFilterDescriptions();
                getAllStores(dispatch);
                getAddressfromlatlong(lat, long, false, true);
                setuserLatLong({ lat, long });
            } else {
                cartDispatch({ type: 'beginLoading' });
                getSpecialtyFilterDescriptions();
                getAllStores(dispatch);
            }
        }
    }, [isUserProfileLoading, cashProfileLatLongUpdated, projectInfoCookies?.SLLat]);

    // --- getting nearest stores and current address when
    //  current location is allowed ---
    useEffect(() => {
        if (isCurrentLocationAllowed && !isObjectEmpty(currentLocation)) {
            if (currentCountry !== '' && (currentCountry === 'us' || currentCountry === 'ca')) {
                setIsNoStoreAvailable(false);
                cartDispatch({ type: 'beginLoading' });
                getStores(currentLocation?.lat, currentLocation?.long, dispatch);
                setZoomOutMax(false);
            } else {
                const data = {
                    data: {
                        pcList: []
                    }
                };
                dispatch({
                    type: SET_STORE_LOCATIONS,
                    storeLocations: data
                });
                dispatch({
                    type: SET_STORE_LOCATIONS_BEFORE_FILTERS,
                    storeLocationsBeforeFilters: data
                });
                dispatch({
                    type: SET_ALL_STORES,
                    allStoreLocations: allStoreLocationsBeforeFilters
                });
                setIsNoStoreAvailable(true);
                setuserLatLong('');
                zoomOutAndFocus();
                setUserAddress('');
                setCurrentAddress('');
            }
        }
    }, [isCurrentLocationAllowed]);

    useEffect(() => {
        const locationLeftDrawer = document?.querySelector('.location__left-drawer');
        if (locationLeftDrawer) {
            trapFocus(document?.querySelector('.location__left-drawer'));
            var drawerBackButton = drawerRef?.current;

            drawerBackButton?.querySelector('.location-side-drawer__content-title')?.focus();
        }
    }, [isLlpModalOpen, selectedStoreValue]);

    const isLocationAlreadySearched = () => {
        const locationSearchFields = sessionStorage.getItem('locationSearchFields')
            ? JSON.parse(sessionStorage.getItem('locationSearchFields'))
            : {};
        const { lat, long, formattedAddress } = locationSearchFields;
        if (lat && long && formattedAddress) {
            setIsUserLocationUsed(true);
            setIsNoStoreAvailable(false);
            getStores(lat, long, dispatch);
            getSpecialtyFilterDescriptions();
            getAllStores(dispatch);
            setuserLatLong({ lat, long });
            setUserAddress(formattedAddress);
            return true;
        }
        return false;
    };

    const setSortedListForShowMore = async () => {
        const list = storesList;
        let addedStores = list?.splice(0, SHOW_MORE_LIMIT);
        let visibleList = [...visibleStoreList, ...addedStores];
        setVisibleStoreList(visibleList);
        setStoresList(list);
    };
    const onClearingAddress = async () => {
        setVisibleStoreList([]);
        setStoresList([]);
        zoomOutAndFocus();
        setUserAddress('');
        setCurrentAddress('');
        setSelectedStoreValue('');
        if (numberOfFilters > 0) {
            dispatch({
                type: SET_ALL_STORES,
                allStoreLocations: allStoreLocationsBeforeFilters
            });
            setFilterData(initFilterData());
            dispatch({
                type: SET_NUMBER_OF_FILTERS,
                numberOfFilters: 0
            });
        }
        setuserLatLong('');
        setIsNoStoreAvailable(false);
        setIsUserLocationUsed(false);
        setLocationInStorage({
            DEFAULT_LAT,
            DEFAULT_LONG,
            formattedAddress: ''
        });
        setIsCurrentLocationAllowed(false);
        setLocationDeniedError(false);
    };

    const getStores = async (latitude, longitude, dispatch, distance = parseInt(ENV_CONFIG.LLP_INITIAL_RADIUS)) => {
        try {
            const { data, error } = await getStoreLocations(latitude, longitude, distance);
            if (data?.data?.pcList?.length > 0) {
                dispatch({
                    type: SET_STORE_LOCATIONS,
                    storeLocations: data
                });
                dispatch({
                    type: SET_STORE_LOCATIONS_BEFORE_FILTERS,
                    storeLocationsBeforeFilters: data
                });
                dispatch({
                    type: SET_ALL_STORES,
                    allStoreLocations: allStoreLocationsBeforeFilters
                });
                setFocusedLocation({ lat: Number(latitude), long: Number(longitude) });
                setZoomOutMax(false);
                setIsNoStoreAvailable(false);
            } else {
                if (distance >= TIER_2_RADIUS) {
                    cartDispatch({ type: 'endLoading' });
                    setFocusedLocation({
                        lat: Number(data?.data?.pcList[0].latitude),
                        long: Number(data?.data?.pcList[0].longitude)
                    });
                    setZoomOutMax(false);
                    return;
                }
                distance = distance + 100;
                getStores(latitude, longitude, dispatch, distance);
            }
        } catch (error) {
            cartDispatch({ type: 'endLoading' });
            return;
        }
    };

    const getAllStores = dispatch => {
        return getStoreLocations()
            .then(({ data, status }) => {
                dispatch({
                    type: SET_ALL_STORES,
                    allStoreLocations: data
                });
                dispatch({
                    type: SET_ALL_STORE_LOCATIONS_BEFORE_FILTERS,
                    allStoreLocationsBeforeFilters: data
                });
                dispatch({
                    type: SET_IS_ALL_STORES_DATA_PRESENT,
                    isAllStoresDataPresent: true
                });
                cartDispatch({ type: 'endLoading' });
            })
            .catch(error => {
                cartDispatch({ type: 'endLoading' });
            });
    };
    const getSpecialtyFilterDescriptions = async () => {
        try {
            const { data, error } = await getFilterSpecialtyData();
            if (data?.data?.specialties?.items?.length > 0) {
                dispatch({
                    type: SET_SPECIALTY_FILTER_DESCRIPTONS,
                    specialtyFilterDescriptions: data?.data?.specialties?.items
                });
            }
        } catch (error) {
            logError(error, false, 'getFilterSpecialtyDescriptions', false);
        }
    };
    const getStoreDetailsWithPC = async (pc, companyId) => {
        const storeData = await getStoreDetailsFromPC(pc, companyId);
        return storeData?.data?.data?.pcList[0];
    };
    const getAddressfromlatlong = async (lat, long, isCurrentAddress, isUserLocation = false) => {
        try {
            const data = await getAddressBylatLong(lat, long);
            const currentCountry = data?.data?.results[0]?.addressComponents.filter(
                address => address?.types[0] === 'country'
            );
            if (isUserLocation) {
                if (userTypeCookie === USER_TYPE.CREDIT) {
                    setUserAddress(
                        formatCreditAddress(userTypeCookie, viewCart, isCreditNewAddress, getProjectsFromCookie)
                    );
                    setLocationInStorage({
                        lat,
                        long,
                        formattedAddress: formatCreditAddress(
                            userTypeCookie,
                            viewCart,
                            isCreditNewAddress,
                            getProjectsFromCookie
                        )
                    });
                } else {
                    setLocationInStorage({
                        lat,
                        long,
                        formattedAddress: viewCart?.location || data?.data?.results[0]?.formattedAddress
                    });
                    setUserAddress(viewCart?.location || data?.data?.results[0]?.formattedAddress);
                }
            } else if (currentCountry[0]?.shortName === 'US' || currentCountry[0]?.shortName === 'CA') {
                setLocationInStorage({ lat, long, formattedAddress: data?.data?.results[0]?.formattedAddress });
                if (isCurrentAddress) {
                    localStorage.setItem('companyID', currentCountry[0]?.shortName === 'CA' ? '2' : '1');
                    setCurrentAddress(data?.data?.results[0]?.formattedAddress);
                    setCurrentCountry(currentCountry[0]?.shortName.toLowerCase());
                    setIsUserLocationUsed(false);
                    setIsCurrentLocationAllowed(true);
                } else {
                    setIsUserLocationUsed(false);
                    setUserAddress(data?.data?.results[0]?.formattedAddress);
                }
            } else {
                setCurrentAddress('');
                setCurrentCountry('us');
                setIsNoStoreAvailable(true);
                setuserLatLong('');
                zoomOutAndFocus();
                setUserAddress('');
                setCurrentAddress('');
            }
        } catch (err) {
            logError(err, 'getAddressfromlatlong', false, [lat, long, isCurrentAddress, isUserLocation]);
        }
    };
    const getCurrentLocation = () => {
        if (navigator.geolocation && !isWebView) {
            navigator?.permissions?.query({ name: GEOLOCATION })?.then(function (result) {
                if (result?.state == DENIED) {
                    setLocationDeniedError(true);
                }
            });

            navigator?.geolocation?.getCurrentPosition(position => {
                setLocationDeniedError(false);
                setCurrentLocation({ lat: position.coords.latitude, long: position.coords.longitude });
                setFocusedLocation({ lat: position.coords.latitude, long: position.coords.longitude });
                getAddressfromlatlong(position.coords.latitude, position.coords.longitude, true, false);
                //to mock current location
                // setCurrentLocation({ lat: 45.424721, long: -75.695 });
                // setFocusedLocation({ lat: 45.424721, long: -75.695 });
                // getAddressfromlatlong(45.424721, -75.695, true, false);
            });
        } else {
            setIsCurrentLocationAllowed(false);
            setCurrentCountry('us');
        }
    };

    const handleShowMore = () => {
        setSortedListForShowMore();
    };
    const isBodyVisible = () => {
        if (visibleStoreList?.length > 0 || (isNoStoreAvailable && (userAddress || isSearchByBranch))) {
            return true;
        }
        return false;
    };

    const checkIfStoreVisible = async pcId => {
        const item = visibleStoreList?.filter(i => i?.pc == pcId);
        let selectedItemIndex = -1;
        let selectedItem = [];
        if (item.length === 0) {
            if (userAddress) {
                for (let index in storesList) {
                    if (storesList[index]?.pc == pcId) {
                        selectedItemIndex = index;
                        selectedItem.push(storesList[index]);
                    }
                }
            }
            if (!userAddress || selectedItem?.length == 0) {
                for (let index in allStores) {
                    if (allStores[index]?.pc == pcId) {
                        selectedItemIndex = index;
                        selectedItem.push(allStores[index]);
                    }
                }
            }
            if (selectedItem?.length > 0) {
                if (!selectedItem[0]?.state) {
                    if (userLatLong) {
                        const [storedistance, storeData] = await Promise.all([
                            getDistance(
                                selectedItem[0]?.latitude,
                                selectedItem[0]?.longitude,
                                isCurrentLocationAllowed,
                                currentLocation,
                                userLatLong
                            ),
                            getStoreDetailsWithPC(pcId, selectedItem[0]?.companyId || 1)
                        ]);
                        selectedItem[0] = storeData;
                        selectedItem[0]['distance'] = storedistance;
                    } else {
                        selectedItem[0] = await getStoreDetailsWithPC(pcId, selectedItem[0]?.companyId || 1);
                    }
                }
                setVisibleStoreList([...selectedItem, ...visibleStoreList]);
                setModalData(selectedItem?.[0]);
                const list = storesList;
                list?.splice(selectedItemIndex, 1);
                setStoresList(list);
            }
        } else {
            setModalData(item?.[0]);
        }
        showLocationSideDrawer();
    };
    const handleMapClickMarker = item => {
        setSelectedStoreValue(item?.pc);
        setZoomOutMax(false);
        checkIfStoreVisible(item?.pc);
    };

    const displayOpenHours = item => {
        const dow = moment().day();
        let openHours;
        let operatingHoursData = item?.operatingHours?.sort((day1, day2) => day1.dayOfWeek - day2.dayOfWeek);
        if (operatingHoursData) {
            var hours = operatingHoursData[dow]?.hours;
            if (hours && hours !== 'Closed' && hours !== '-') {
                let time1 = hours?.substr(0, 7);
                let time2 = hours?.substr(9);
                time1 = moment(time1, 'hh:mm a');
                time1 = moment(time1)?.format('h:mm a');
                time2 = moment(time2, 'hh:mm a');
                time2 = moment(time2)?.format('h:mm a');
                hours = time1?.replace(/ /g, '') + ' — ' + time2?.replace(/ /g, '');
                openHours = hours;
            } else {
                openHours = '';
            }
        } else {
            openHours = '';
        }
        return openHours;
    };

    const handleSearchString = async value => {
        if (value === '') {
            setIsBranchSearchError(true);
        } else if (value !== '' && !isNaN(value)) {
            setIsBranchSearchError(false);
            let integerValue = parseInt(value);
            let selectedPcBranch = [];
            setVisibleStoreList([]);
            const branchFilterPc = allStoreLocationsBeforeFilters?.data?.pcList.filter(
                item => item?.pc == integerValue
            );
            let data = await getStoreDetailsWithPC(integerValue, branchFilterPc[0]?.companyId || 1);
            if (data?.pc) {
                selectedPcBranch.push(data);
                setIsNoStoreAvailable(false);
                setVisibleStoreList([...selectedPcBranch]);
                setZoomOutMax(false);
                setFocusedLocation({ lat: data?.latitude, long: data?.longitude });
                setSelectedStoreValue(data?.pc);
            } else {
                setIsNoStoreAvailable(true);
            }
        }
    };

    const zoomOutAndFocus = () => {
        setZoomOutMax(true);
        setFocusedLocation({ lat: DEFAULT_LAT, long: DEFAULT_LONG });
    };

    const showLocationSideDrawer = () => {
        setIsLlpModalOpen(true);
    };

    const hideLocationSideDrawer = () => {
        setIsLlpModalOpen(false);
    };
    const handleLocationCardClick = item => {
        setModalData(item);
        showLocationSideDrawer();
    };

    const renderLocationMap = () => {
        return (
            <StoresLocationMap
                showDefaultZoomcontrol={false}
                showCustomZoomControlDesktop={true}
                selectedLatLong={focusedLocation}
                defaultCenter={{
                    lat: storeLocations?.data?.pcList?.length === 0 ? null : focusedLocation.lat,
                    long: storeLocations?.data?.pcList?.length === 0 ? null : focusedLocation.long
                }}
                onClickMarker={item => {
                    handleMapClickMarker(item);
                }}
                selectedStoreValue={selectedStoreValue ? selectedStoreValue : visibleStoreList[0]?.pc || ''}
                storesList={allStores.length === 0 ? [] : allStores}
                isCurrentLocationAllowed={isCurrentLocationAllowed}
                currentLocation={currentLocation}
                setFocusedLocation={setFocusedLocation}
                zoomOutMax={zoomOutMax}
                userLatLong={userLatLong}
                isLlpModalOpen={isLlpModalOpen}
                isSearchByBranch={isSearchByBranch}
            />
        );
    };

    const renderLocationTop = isDesktop => {
        return (
            <LocationTop
                wrapClass={isDesktop ? 'location__aside-top--desktop' : 'location__aside-top--tablet'}
                getCurrentLocation={getCurrentLocation}
                getStores={getStores}
                isCurrentLocationAllowed={isCurrentLocationAllowed}
                currentAddress={currentAddress}
                setOpenFilterHandle={setOpenFilterHandle}
                currentCountry={currentCountry}
                setIsUserLocationUsed={setIsUserLocationUsed}
                isUserLocationUsed={isUserLocationUsed}
                setIsCurrentLocationAllowed={setIsCurrentLocationAllowed}
                setFocusedLocation={setFocusedLocation}
                setuserLatLong={setuserLatLong}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                locationDeniedError={locationDeniedError}
                setLocationDeniedError={setLocationDeniedError}
                onClearingAddress={onClearingAddress}
                searchByBranch={handleSearchString}
                isBranchSearchError={isBranchSearchError}
                setIsBranchSearchError={setIsBranchSearchError}
                isSearchByBranch={isSearchByBranch}
                setIsSearchByBranch={setIsSearchByBranch}
            />
        );
    };

    const renderLocationCard = item => {
        return (
            <LocationCard
                openHours={displayOpenHours(item)}
                item={item}
                setSelectedStoreValue={setSelectedStoreValue}
                selectedStoreValue={selectedStoreValue}
                startDate={startDate}
                cardRef={refs[item?.pc]}
                setFocusedLocation={setFocusedLocation}
                setZoomOutMax={setZoomOutMax}
                handleButtonClick={handleLocationCardClick}
            />
        );
    };
    const renderLocationFilter = isDesktop => {
        return (
            <LocationFilter
                filterTitleClass={isSticky && isDesktop ? 'location__filter-top--sticky' : ''}
                filterBodyRef={locationFilterBodyRef}
                wrapClass={isDesktop ? 'location__filter--desktop' : 'location__filter location__filter--mobile'}
                isFilterModalOpen={openFilter}
                setIsFilterModalOpen={setOpenFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                subfiltertitle={subfiltertitle}
                initFilterData={initFilterData}
                setIsNoStoreAvailable={setIsNoStoreAvailable}
            />
        );
    };

    const renderNoStoreMessage = () => {
        if (isNoStoreAvailable) {
            if (isSearchByBranch) {
                return <InfoMessage title={locationNoBranchErrorTitle} description={noStoreDescription} />;
            } else if (visibleStoreList?.length == 0 && !isLoading && userAddress) {
                return (
                    <InfoMessage
                        wrapperClasses={'info-message--nobranchfound'}
                        title={intl.formatMessage({ id: 'location:no-store-message-title' })}
                        description={noStoreDescription}
                    />
                );
            }
        }
        return null;
    };
    const renderDeliveryMessage = () => {
        const firstSearchedPc = storeLocations?.data?.pcList?.[0];
        if (
            userAddress &&
            firstSearchedPc?.distance > parseInt(ENV_CONFIG.LLP_RADIUS_FOR_DELIVERY_MESSAGE || 25) &&
            !isSearchByBranch &&
            !isLoading
        ) {
            return (
                <>
                    <InfoMessage
                        wrapperClasses={'info-message--delivermessage'}
                        title={locationNoBranchInRadiusTitle}
                        description={noStoreDescription}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <section
            className={`${isLoading ? 'block__location loadingState' : 'block__location'} ${
                isBodyVisible() ? 'block__location--showresults' : ''
            }`}>
            {isLoading && (
                <div className={`locationLoader`}>
                    <LoadingIndicator />
                </div>
            )}
            {mediaType === 'TABLET' || mediaType === 'MOBILE' ? (
                <>
                    {renderLocationTop(false)}
                    <div className="location__map-wrap">{renderLocationMap()}</div>
                    {mediaType === MEDIA_TYPE.mobile && <LocationLegend />}
                    {isBodyVisible() && (
                        <div className={`location__body`}>
                            {renderDeliveryMessage()}
                            {renderNoStoreMessage()}
                            {visibleStoreList?.length !== 0 &&
                                visibleStoreList?.map(item => (
                                    <React.Fragment key={item?.pc}>{renderLocationCard(item)}</React.Fragment>
                                ))}
                            {storesList?.length > 0 && visibleStoreList?.length > 9 && (
                                <Button
                                    className="button show-more-btn"
                                    buttonAriaLabel={`${intl.formatMessage({ id: 'location:show-more-cta' })}`}
                                    onClick={handleShowMore}>
                                    {intl.formatMessage({ id: 'location:show-more-cta' })} <Plus />
                                </Button>
                            )}
                        </div>
                    )}
                    {openFilter && renderLocationFilter(false)}
                </>
            ) : (
                <>
                    {renderLocationMap()}
                    <aside
                        className={`location__aside ${visibleStoreList.length <= 0 ? 'location__aside--nodata' : ''}`}>
                        {renderLocationTop(true)}
                        {isBodyVisible() && (
                            <div
                                className={`location__body ${
                                    visibleStoreList?.length !== 0 ? 'location__body--data' : ''
                                }`}>
                                {renderDeliveryMessage()}
                                {renderNoStoreMessage()}
                                {visibleStoreList?.length !== 0 &&
                                    visibleStoreList?.map(item => (
                                        <React.Fragment key={item?.pc}>{renderLocationCard(item)}</React.Fragment>
                                    ))}
                                {storesList?.length > 0 && visibleStoreList?.length > 9 && (
                                    <Button
                                        className="button show-more-btn"
                                        buttonAriaLabel={`${intl.formatMessage({ id: 'location:show-more-cta' })}`}
                                        onClick={handleShowMore}>
                                        {intl.formatMessage({ id: 'location:show-more-cta' })} <Plus />
                                    </Button>
                                )}
                            </div>
                        )}
                    </aside>
                    {openFilter && renderLocationFilter(true)}
                </>
            )}
            {mediaType === 'DESKTOP' && isLlpModalOpen ? (
                <aside
                    className={`location__left-drawer ${isLlpModalOpen ? 'location__left-drawer--open' : ''}`}
                    ref={drawerRef}>
                    {isLlpModalOpen && (
                        <LocationDetailDrawer
                            modalData={modalData}
                            handleBackButton={hideLocationSideDrawer}
                            userAddress={userAddress}
                        />
                    )}
                </aside>
            ) : (
                isLlpModalOpen && (
                    <LocationDetailDrawer
                        titleClasses={''}
                        settitleFocus={false}
                        isOpen={isLlpModalOpen}
                        handleClose={hideLocationSideDrawer}
                        modalData={modalData}
                        handleBackButton={hideLocationSideDrawer}
                        userAddress={userAddress}
                        showCloseButton={false}
                        className={'llpmodal__content'}
                    />
                )
            )}
        </section>
    );
}

export default memo(Location);
