import moment from 'moment';
import { useCartState } from '../aem-core-components/components/Minicart';
import { DELIVERY_CHARGE_FLAG } from '../aem-core-components/components/Minicart/constants';
import useCartEstimate from '../aem-core-components/components/Minicart/hooks/useCartEstimate';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useFilterState } from '../components/cap';
import {
    isCheckoutPage,
    formatDate,
    checkIsRequestQuote,
    getUniqueCategories
} from '../components/global/utils/commonUtils';
import { isValidString, logError } from '../components/global/utils/logger';
import useQuoteSummary from '../components/molecules/orderSummaryFactory/hooks/useQuoteSummary';
import { useQuotesState } from '../components/quotes/context';
import { useAnalyticsContext } from '../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../constants/analyticsConstants/Ecommerce';
import { EVENT_P2P_NAMES_CONFIG } from '../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { EMPLOYEE } from '../components/checkout/constants';
import { AUTHORITY_TYPE } from '../components/global/constants';
import { useCheckDeliveryCharge } from './useCheckDeliveryCharge';
import { useCheckAuthorityType, useCheckUser } from './useCheckUser';
import { usePageType } from './usePageType';
import { usePricing } from './usePricing';
import useUserData from './useUserData';
import { useAtp } from '../components/cap/hooks/useATP';
import { getDateDiffInHrs } from '../components/cap/utils/atputils';
import { TILE_STATES } from '../components/cap/constants';

const useAnalytics = () => {
    const userType = useCheckUser();
    const [{ userProfile }] = useUserContext();
    const [{ viewCart, projectDetails, showUnavailableItems, startDate }] = useFilterState();
    const [{ userInfo, cart, consumables, homeAssetsRates, orderSummaryDetails }] = useCartState();
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const authorityType = useCheckAuthorityType();
    const { getOverrideRatesPc } = usePricing();
    const [{ getProjectsFromCookie }] = useUserData();
    const [{ quoteDetailsData }] = useQuotesState();
    const { quoteSummary } = useQuoteSummary();
    const {
        sendEventsForDatePickerFormViewed,
        sendEventsForLocationFormSubmit,
        sendEventsForFormCompleted,
        sendEventsForDatePickerFormReset,
        sendEventsForLocationAction,
        sendEventsForCtaChange,
        sendEventsForAddToCart,
        sendEventsForAvailabilityToggle,
        sendEventsForEcommerceAction,
        sendEventsForUnavailableCta,
        sendEventsForProductCard
    } = useAnalyticsContext();
    const pageType = usePageType();
    const { handleATP } = useAtp();

    const getItemsForAnalytics = (itemsList = {}) => {
        let itemsCart = [];
        let assetsRates = sessionStorage.getItem('homeAssetsRates')
            ? JSON.parse(sessionStorage.getItem('homeAssetsRates'))
            : {};
        cart?.items?.forEach((item, index) => {
            const items = {
                item_id: item?.product?.sku,
                item_name: item?.product?.name,
                categories: item?.product?.category_path,
                price: item?.prices?.price?.value,
                quantity: item?.quantity,
                index: index + 1,
                daily:
                    assetsRates?.[item?.product?.sku]?.daily || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                weekly:
                    assetsRates?.[item?.product?.sku]?.weekly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                monthly:
                    assetsRates?.[item?.product?.sku]?.monthly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            itemsCart.push({ ...items, ...itemsList });
        });
        return itemsCart;
    };

    const getDeliveryFee = () => {
        let delivery_fee = 0;
        /* handling for minicart, if user clicks on get estimates pass delivery_fee if present */
        if (viewCart?.showDeliveryEstimate) {
            if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
                delivery_fee = DELIVERY_CHARGE_FLAG.TBD;
            } else {
                delivery_fee =
                    cart?.estimatesResponse?.estimate?.totals?.deliveryPickUpCharges ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            }
        }
        /* handling for checkout, if user opted round-trip delivery pass delivery_fee if present */
        if (isCheckoutPage()) {
            if (isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))) {
                delivery_fee = 0;
            } else {
                if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
                    delivery_fee = DELIVERY_CHARGE_FLAG.TBD;
                } else {
                    delivery_fee =
                        cart?.estimatesResponse?.estimate?.totals?.deliveryPickUpCharges ||
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
                }
            }
        }
        return delivery_fee;
    };

    const getFormattedConsumablesObj = consumablesObj => {
        let unDefinedString = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        let formattedObj = {};
        Object.keys(consumablesObj)?.forEach(itemkey => {
            let addons = [];
            if (Object.keys(consumablesObj[itemkey]?.addons).length > 0) {
                Object.keys(consumablesObj[itemkey]?.addons)?.forEach((elementKey, index) => {
                    const addOn = {
                        item_id: elementKey,
                        item_name: consumablesObj[itemkey]?.addons[elementKey]?.name,
                        categories: unDefinedString,
                        price: consumablesObj[itemkey]?.addons[elementKey]?.price,
                        quantity: consumablesObj[itemkey]?.addons[elementKey]?.qty,
                        index: index + 1,
                        daily: unDefinedString,
                        weekly: unDefinedString,
                        monthly: unDefinedString
                    };
                    addons.push(addOn);
                });
            }
            let rentals = [];
            if (Object.keys(consumablesObj[itemkey]?.rentals).length > 0) {
                Object.keys(consumablesObj[itemkey]?.rentals)?.forEach((elementKey, index) => {
                    const rental = {
                        item_id: elementKey,
                        item_name: consumablesObj[itemkey]?.rentals[elementKey]?.name,
                        categories: unDefinedString,
                        price: consumablesObj[itemkey]?.rentals[elementKey]?.price,
                        quantity: consumablesObj[itemkey]?.rentals[elementKey]?.qty,
                        index: index + 1,
                        daily: unDefinedString,
                        weekly: unDefinedString,
                        monthly: unDefinedString
                    };
                    rentals.push(rental);
                });
            }
            formattedObj = {
                ...formattedObj,
                [itemkey]: {
                    addons: addons,
                    rentals: rentals
                }
            };
        });
        return formattedObj;
    };

    const getStartingInStatus = () => {
        const dateDiff = getDateDiffInHrs(startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (startDate) {
            if (dateDiff >= 72) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.GRT72HRS;
            } else if (dateDiff >= 24 && dateDiff < 72) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.GRT24HRS;
            } else if (dateDiff >= 0 && dateDiff < 24) {
                return VARIABLE_CONFIG.STARTING_IN_STATUS.LESS24HRS;
            }
        }
        return VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
    };

    const getAvailabilityStatus = (sku, inventory, disableAtcOption, showonlinecatalog, pcAvailability) => {
        const tileStatus = handleATP({ catsku: sku, inventoryDataObj: inventory, pcAvailability });
        if (checkIsRequestQuote(showonlinecatalog, disableAtcOption)?.isRequestQuote) {
            return VARIABLE_CONFIG.TILE_STATUS.REQUEST_A_QUOTE;
        }
        switch (tileStatus) {
            case TILE_STATES.CHANGE_DATES_AND_STORE_GRT72HRS:
            case TILE_STATES.CHANGE_DATES_AND_STORE_GRT24HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE_AND_DATE;

            case TILE_STATES.CHANGE_DATES_OR_STORE_GRT72HRS:
            case TILE_STATES.CHANGE_DATES_OR_STORE_GRT24HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE_OR_DATE;

            case TILE_STATES.EMPTY_LOCATION_AND_EMPTY_DATES:
                return VARIABLE_CONFIG.TILE_STATUS.NO_LOCATION_AND_DATES;

            case TILE_STATES.EMPTY_LOCATION:
                return VARIABLE_CONFIG.TILE_STATUS.NO_LOCATION;

            case TILE_STATES.EMPTY_DATES:
                return VARIABLE_CONFIG.TILE_STATUS.NO_DATES;

            case TILE_STATES.CHANGE_DATES_GRT24HRS:
            case TILE_STATES.CHANGE_DATES_GRT72HRS:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_DATE;

            case TILE_STATES.UNAVAILABLE:
                return VARIABLE_CONFIG.TILE_STATUS.UNAVAILABLE;

            case TILE_STATES.AVAILABLE:
                return VARIABLE_CONFIG.TILE_STATUS.RENT;

            case TILE_STATES.CHANGE_STORE:
                return VARIABLE_CONFIG.TILE_STATUS.CHANGE_STORE;

            default:
                return VARIABLE_CONFIG.TILE_STATUS.DEFAULT;
        }
    };

    const payloadEcommerceActionAnalytics = (isConsumablesRequired = true, startDate = '', endDate = '') => {
        const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        const viewCartData =
            (localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART) &&
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))) ||
            {};
        let cookieAccount = getProjectsFromCookie();
        const address = {
            line_1:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLA : viewCartData?.location) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            line_2:
                (userType === USER_TYPE.CREDIT && !NewAddress
                    ? cookieAccount?.projectAddress2
                    : viewCartData?.jobSiteAddr2) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            city:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLC : viewCartData?.jobSiteCity) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            state:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLS : viewCartData?.jobSiteState) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            zipcode:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLZ : viewCartData?.jobSiteZip) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
        const user = {
            account: cookieAccount?.CurrentWynneAccount || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: cookieAccount?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            address: address
        };
        const consumablesObj = { ...consumables?.selected };
        const formattedConsumablesObj =
            Object.keys(consumablesObj).length > 0
                ? getFormattedConsumablesObj(consumablesObj)
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        let ecommerce = getEcommerceData(startDate, endDate);
        if (isConsumablesRequired) {
            ecommerce = { ...ecommerce, consumables: formattedConsumablesObj };
        }
        return { user, ecommerce };
    };

    const payloadEcommerceStepActionAnalytics = (isConsumablesRequired = true, startDate = '', endDate = '') => {
        const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        const viewCartData =
            (localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART) &&
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))) ||
            {};
        let cookieAccount = getProjectsFromCookie();
        const address = {
            line_1:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLA : viewCartData?.location) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            line_2:
                (userType === USER_TYPE.CREDIT && !NewAddress
                    ? cookieAccount?.projectAddress2
                    : viewCartData?.jobSiteAddr2) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            city:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLC : viewCartData?.jobSiteCity) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            state:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLS : viewCartData?.jobSiteState) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            zipcode:
                (userType === USER_TYPE.CREDIT && !NewAddress ? cookieAccount?.SLZ : viewCartData?.jobSiteZip) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
        const user = {
            account: cookieAccount?.CurrentWynneAccount || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: cookieAccount?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            address: address
        };
        const consumablesObj = { ...consumables?.selected };
        const formattedConsumablesObj =
            Object.keys(consumablesObj).length > 0
                ? getFormattedConsumablesObj(consumablesObj)
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        let ecommerce = getEcommerceData(startDate, endDate);
        if (authorityType === AUTHORITY_TYPE.P2P) {
            ecommerce.type = 'quote';
        }
        if (isConsumablesRequired) {
            ecommerce = { ...ecommerce, consumables: formattedConsumablesObj };
        }
        return { user, ecommerce };
    };

    const payloadEditQuoteActionAnalytics = quoteData => {
        const ecommerce = {
            type: 'quote',
            currency: quoteData?.data?.companyId == 2 ? 'CAD' : 'USD',
            rent_value: quoteSummary?.rentalSubtotal,
            purchase_value: quoteSummary?.purchaseSubtotal,
            delivery_method: quoteData?.deliveryMethod?.toLowerCase(),
            delivery_fee: quoteSummary?.deliveryPickUpCharges,
            value: quoteSummary?.estimatedSubtotal
        };
        return { quote_id: quoteData?.displayQuoteId, ecommerce };
    };

    const getEcommerceData = (startDate, endDate) => {
        return {
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            value:
                isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ORDER_ESTIMATES_FINAL_TOTAL)) ||
                (isEstimatesWithOwnedPc()
                    ? cart?.prices?.grand_total?.value
                    : cart?.estimatesResponse?.estimate?.totals?.rentalAmount) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            delivery_method: isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
                ? VARIABLE_CONFIG.EVENT_LABEL.INSTORE_PICKUP
                : VARIABLE_CONFIG.EVENT_LABEL.ROUNDTRIP_DELIVERY,
            delivery_fee: getDeliveryFee(),
            start_date:
                startDate ||
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            end_date:
                endDate ||
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            items: getItemsForAnalytics()
        };
    };

    const payloadEcommercePageData = (categories, totalQuantity) => {
        const ecommerce = {
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            type: usePageType(),
            categories: categories,
            number_of_products: totalQuantity
        };
        return { ecommerce };
    };

    const payloadEcommerceLocationActionAnalytics = (error = false, userDataProvided) => {
        const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        let address = {};
        let nearest_pc;
        let override_pc;

        const bussinessSize = () => {
            if (authorityType === AUTHORITY_TYPE.P2P) {
                return VARIABLE_CONFIG.BUSINESS_SIZE.MIDDLE;
            } else if (userType === USER_TYPE.CREDIT) {
                return VARIABLE_CONFIG.BUSINESS_SIZE.MIDDLE;
            } else if (userType === USER_TYPE.CASH) {
                return VARIABLE_CONFIG.BUSINESS_SIZE.SMALL;
            }
        };

        if (error) {
            address = {
                line_1: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        } else {
            const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

            address = {
                line_1:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.projectAddress1
                        : viewCart?.location) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.projectAddress2
                        : viewCart?.jobSiteAddr2) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectState
                        : viewCart?.jobSiteCity) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectCity
                        : viewCart?.jobSiteState) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectZip
                        : viewCart?.jobSiteZip) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
                ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))?.distance
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = overridePc?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        }
        const user = {
            id: userProfile?.userGuid,
            account_payment_type: authorityType === AUTHORITY_TYPE.P2P ? authorityType?.toLowerCase() : userType,
            authentication: userType !== USER_TYPE.GUEST ? true : false,
            account: userInfo?.AccountNumber || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: projectDetails?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            location_set: address?.line_1 ? true : false,
            nearest_pc: nearest_pc,
            override_pc: override_pc,
            address: address
        };
        if (userDataProvided) {
            user.business_size = bussinessSize() || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            user.is_employee = userProfile?.Type === EMPLOYEE; // would always be false unless SBR employees can use this interface
            user.platform = authorityType === AUTHORITY_TYPE.P2P ? authorityType?.toLowerCase() : 'aem';
            if (authorityType === AUTHORITY_TYPE.P2P) {
                user.paperless = true; // set when checked in the user profile section of the my account area
                user.is_impersonating = false; // set when checked in the user profile section of the my account area
            }
        }
        return { user };
    };
    const payloadEcommerceNoStoreNearby = (addressDetails, selectedAddress, nearestPcDistance) => {
        const extractLocation = address => {
            const location = address?.split(',')?.[0]?.trim();
            return location;
        };
        const { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = addressDetails || {};
        const selectedAddressObj = {
            line_1: extractLocation(selectedAddress) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            line_2: jobSiteAddr2 || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            city: jobSiteCity || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            state: jobSiteState || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            zipcode: jobSiteZip || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
        const userData = payloadEcommerceLocationActionAnalytics(false);
        const { override_pc } = userData?.user || {};
        const user = {
            nearest_pc: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            override_pc,
            address: selectedAddressObj
        };
        return { user };
    };

    const payloadEcommerceLocationActionSubmitted = (localLocation, error = false) => {
        const NewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        let address = {};
        let nearest_pc;
        let override_pc;
        if (error) {
            address = {
                line_1: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        } else {
            const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

            address = {
                line_1:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? localLocation?.address1
                        : localLocation?.location) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? localLocation?.address2
                        : localLocation?.jobSiteAddr2) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city:
                    (userType === USER_TYPE.CREDIT && !NewAddress ? localLocation?.city : localLocation?.jobSiteCity) ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state:
                    (userType === USER_TYPE.CREDIT && !NewAddress
                        ? localLocation?.state
                        : localLocation?.jobSiteState) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode:
                    (userType === USER_TYPE.CREDIT && !NewAddress ? localLocation?.zip : localLocation?.jobSiteZip) ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
                ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))?.distance
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = overridePc?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        }
        const user = {
            id: userProfile?.userGuid,
            account_payment_type: userType,
            authentication: userType !== USER_TYPE.GUEST ? true : false,
            account: userInfo?.AccountNumber || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: localLocation?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            location_set: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))
                ? true
                : false,
            nearest_pc: nearest_pc,
            override_pc: override_pc,
            address: address
        };
        return { user };
    };

    const createInventoryItemObject = (data, index) => {
        const item = {
            index: index,
            item_id: data?.catclass || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            item_name: data?.producttitle || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            item_list_id: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR,
            item_list_name: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_PRODUCTS,
            categories: data?.category || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            price: isNaN(homeAssetsRates[data?.catclass]?.daily)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[data?.catclass]?.daily,
            quantity: 1,
            daily: isNaN(homeAssetsRates[data?.catclass]?.daily)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[data?.catclass]?.daily,
            weekly: isNaN(homeAssetsRates[data?.catclass]?.weekly)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[data?.catclass]?.weekly,
            monthly: isNaN(homeAssetsRates[data?.catclass]?.monthly)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[data?.catclass]?.monthly
        };
        return item;
    };

    const createListingItemObject = (products, isProduct, isSimilarItems, rates, dataset) => {
        let items = [];
        const assetsRates = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        products?.forEach((product, index) => {
            const sku = product?.additionalFields?.ec_skus?.[0] || product?.raw?.ec_skus?.[0] || product?.catclass;
            const inventory =
                product?.additionalFields?.ec_pc_inventory ||
                product?.raw?.ec_pc_inventory ||
                product?.ec_pc_inventory ||
                product?.inventory;
            const disableAtcOption =
                product?.additionalFields?.ec_disable_addtocartoption ||
                product?.raw?.ec_disable_addtocartoption ||
                product?.disableaddtocartoption;
            const showOnlineCatalog =
                product?.additionalFields?.ec_show_onlinecatalog ||
                product?.raw?.ec_show_onlinecatalog ||
                product?.showonlinecatalog;

            const item = {
                item_id: sku || dataset?.catsku || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                item_name:
                    product?.ec_name?.trim() ||
                    product?.raw?.ec_name?.trim() ||
                    product?.name?.trim() ||
                    product?.producttitle?.trim() ||
                    dataset?.productname?.trim() ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                item_list_id: isProduct
                    ? VARIABLE_CONFIG.PRODUCT.LIST_ID
                    : isSimilarItems
                    ? VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR
                    : pageType === VARIABLE_CONFIG.PAGE_TYPE.PLP
                    ? VARIABLE_CONFIG.ECOMMERCE_PAGE.PLP
                    : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                    ? VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_ID
                    : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                    ? VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_ID
                    : VARIABLE_CONFIG.ECOMMERCE_PAGE.SRP,
                item_list_name: isProduct
                    ? VARIABLE_CONFIG.PRODUCT.LIST_NAME
                    : isSimilarItems
                    ? VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT
                    : pageType === VARIABLE_CONFIG.PAGE_TYPE.PLP
                    ? VARIABLE_CONFIG.PRODUCT_CARD.PLP_LIST_NAME
                    : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                    ? VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                    : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                    ? VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                    : VARIABLE_CONFIG.PRODUCT_CARD.SRP_LIST_NAME,
                categories: getUniqueCategories(
                    product?.ec_category ||
                        product?.raw?.ec_category ||
                        product?.category_path || [
                            product?.categoryname || dataset?.categoryname,
                            product?.parentCategory || dataset?.parentcategory,
                            product?.subcategoryname || dataset?.supercategory
                        ]
                ),
                price: assetsRates?.[sku]?.daily || rates[0]?.rates?.suggestedRates?.daily,
                quantity: 1,
                daily: assetsRates?.[sku]?.daily || rates[0]?.rates?.suggestedRates?.daily,
                weekly: assetsRates?.[sku]?.weekly || rates[0]?.rates?.suggestedRates?.weekly,
                monthly: assetsRates?.[sku]?.monthly || rates[0]?.rates?.suggestedRates?.monthly,
                availability: getAvailabilityStatus(
                    sku,
                    inventory,
                    disableAtcOption,
                    showOnlineCatalog,
                    product?.pc_availability
                ),
                location_id:
                    overridePC?.pc ||
                    assetsRates?.[sku]?.ownedPc ||
                    rates[0]?.location ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };

            if (isProduct) {
                item.index = index;
            } else {
                item.position = index + 1;
            }

            items.push(item);
        });
        return items;
    };

    const payloadEcommerceViewItemList = (products, isProduct = false, isSimilarItems = false, rates = [], dataset) => {
        const { currency, start_date, end_date, delivery_fee } = getEcommerceData();
        const payloadEcommerce = payloadEcommerceActionAnalytics(false);
        const address = payloadEcommerce?.user?.address || {};
        const locationPCObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)) || {};
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        const deliveryPc = locationPCObj?.pc;

        const ecommerceData = {
            currency,
            delivery_method: isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
                ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
                : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY,
            delivery_fee,
            delivery_pc: deliveryPc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            override_pc: overridePC?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
            starting_in: getStartingInStatus(),
            address,
            items: createListingItemObject(products, isProduct, isSimilarItems, rates, dataset)
        };

        if (!isProduct && !isSimilarItems && pageType !== VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE) {
            ecommerceData.availability_toggle = showUnavailableItems
                ? VARIABLE_CONFIG.AVAILABILITY_TOGGLE.HIDE
                : VARIABLE_CONFIG.AVAILABILITY_TOGGLE.SHOW;
        }

        return {
            ecommerce: ecommerceData
        };
    };

    const payloadEcommerceInventoryListingAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const item = createInventoryItemObject(data, data?.index);
            const overridePc = getOverrideRatesPc();
            item.location_id = overridePc ? overridePc : homeAssetsRates[data?.catclass]?.ownedPc;
            item.item_category =
                data?.category?.[0] ||
                data?.category_path?.[0] ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            item.item_category2 =
                data?.category?.[1] ||
                data?.category_path?.[1] ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            item.item_category3 =
                data?.category?.[2] ||
                data?.category_path?.[2] ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            return item;
        });
        return { items };
    };

    const payloadEcommerceInventoryAddToCartAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const item = createInventoryItemObject(data, data?.index);
            return item;
        });
        let ecommerce = {
            items,
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            value:
                isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ORDER_ESTIMATES_FINAL_TOTAL)) ||
                (isEstimatesWithOwnedPc()
                    ? cart?.prices?.grand_total?.value
                    : cart?.estimatesResponse?.estimate?.totals?.rentalAmount) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            delivery_method: isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
                ? VARIABLE_CONFIG.EVENT_LABEL.INSTORE_PICKUP
                : VARIABLE_CONFIG.EVENT_LABEL.ROUNDTRIP_DELIVERY,
            delivery_fee: getDeliveryFee(),
            start_date:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            end_date:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) ||
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
        };
        return { ecommerce };
    };

    const payloadEcommerceInventoryViewedAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const item = createInventoryItemObject(data, data?.index);
            return item;
        });

        return { items };
    };

    const sendAnalyticsDatePickerViewEvent = isDateClick => {
        try {
            if (isDateClick === VARIABLE_CONFIG.EVENT.START_DATE_CLICK) {
                sendEventsForDatePickerFormViewed(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD,
                    payloadEcommerceActionAnalytics(false)
                );
            } else {
                sendEventsForDatePickerFormViewed(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_END_DATE_FORM_FIELD,
                    payloadEcommerceActionAnalytics(false)
                );
            }
        } catch (error) {
            logError(error, false, 'sendAnalyticsDatePickerViewEvent', [isDateClick]);
        }
    };

    const sendAnalyticsDatePickerModalEvents = (eventType, startDate, endDate) => {
        try {
            switch (eventType) {
                case VARIABLE_CONFIG.EVENT.SUBMIT: {
                    sendEventsForLocationFormSubmit(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_LINK_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_LINK_TYPE,
                        payloadEcommerceActionAnalytics(false, startDate, endDate)
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.RESET: {
                    sendEventsForDatePickerFormReset(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_DATES,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE,
                        pageType
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.COMPLETE: {
                    sendEventsForFormCompleted(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_COMPLETED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        payloadEcommerceActionAnalytics(false, startDate, endDate)
                    );
                    break;
                }
                case VARIABLE_CONFIG.EVENT.CLOSED: {
                    sendEventsForDatePickerFormReset(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE,
                        pageType
                    );
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            logError(error, false, 'sendAnalyticsDatePickerModalEvents', [eventType]);
        }
    };

    const handleAnalyticsOnFocus = source => {
        if (
            source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY &&
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
            !isCheckoutPage()
        ) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ADDRESS,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };
    const payloadQuoteDetailsPageLoadActionAnalytics = () => {
        const quoteData = {
            quote_id: quoteDetailsData?.data?.displayQuoteId,
            ecommerce: {
                type: 'quote',
                currency: quoteDetailsData?.data?.companyId === '01' ? 'USD' : 'CAD',
                rent_value: quoteSummary?.rentalSubtotal,
                purchase_value: quoteSummary?.purchaseSubtotal,
                delivery_method: quoteDetailsData?.data?.deliveryMethod,
                delivery_fee: quoteSummary?.deliveryPickUpCharges,
                value: quoteSummary?.estimatedSubtotal // total value
            }
        };
        return { ...quoteData };
    };
    const createPayloadTransmitQuotePageLoadActionAnalytics = (action, quoteId) => {
        const deliveryMethod = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
            ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
            : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY;
        const transmitQuoteData = {
            ecommerce: {
                type: 'quote',
                currency:
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                        ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                        : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                rent_value: orderSummaryDetails?.rentalSubtotal,
                purchase_value: orderSummaryDetails?.purchaseSubtotal,
                delivery_method: deliveryMethod,
                delivery_fee:
                    deliveryMethod === VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY
                        ? orderSummaryDetails?.deliveryPickUpCharges
                        : 0,
                value: orderSummaryDetails?.estimatedSubtotal // total value
            }
        };
        if (quoteId) {
            transmitQuoteData.quote_id = quoteId;
        }
        if (action) {
            transmitQuoteData.action = action;
        }
        return transmitQuoteData;
    };

    const payloadSearchAndViewEventsForPurchaseOrderList = (searchQuery, numberOfResults, pOFilterData) => {
        const filters = pOFilterData.map(item => [item.key, item.values]);
        const searchData = {
            search_term: searchQuery,
            number_of_results: numberOfResults,
            filters: Object.fromEntries(filters)
        };
        return { ...searchData };
    };

    const payloadSearchAndViewEventsForQuoteList = (searchQuery, numberOfResults, quotesFilterData) => {
        const searchData = {
            search_term: searchQuery,
            number_of_results: numberOfResults,
            filters: quotesFilterData
        };
        return { ...searchData };
    };

    const getListIdAndName = listName => {
        switch (pageType) {
            case VARIABLE_CONFIG.PAGE_TYPE.PLP:
                return {
                    list_id: VARIABLE_CONFIG.ECOMMERCE_PAGE.PLP,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.PLP_LIST_NAME
                };

            case VARIABLE_CONFIG.PAGE_TYPE.SEARCH_PAGE:
                return {
                    list_id: VARIABLE_CONFIG.ECOMMERCE_PAGE.SRP,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.SRP_LIST_NAME
                };

            case VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL:
                return {
                    list_id: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR,
                    list_name: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT
                };

            case VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE:
                if (listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                    };
                } else {
                    return {
                        list_id: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_ID,
                        list_name: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                    };
                }

            default:
                return {};
        }
    };

    const sendAnalyticsForProductCardEvent = (product, event) => {
        const { list_id, list_name } = getListIdAndName(product?.listName);
        const type =
            event?.target?.tagName === 'IMG'
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE_IMAGE
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE;
        sendEventsForProductCard(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            product?.producttitle?.trim(),
            type,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_LOCATION,
            product?.producturl,
            product?.catclass,
            list_id,
            list_name,
            product?.position || product?.index + 1
        );
    };

    const handleChangeCtaAnalytics = (linkName, linkDestination, sku, position, index, listName) => {
        const getPayload = () => {
            if (listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                return {
                    list_id: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_ID,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                };
            } else if (listName === VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME) {
                return {
                    list_id: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_ID,
                    list_name: VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                };
            } else if (index !== undefined) {
                return {
                    list_id: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR,
                    list_name: VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT
                };
            }
        };
        sendEventsForCtaChange(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            linkName,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_LOCATION,
            linkDestination,
            sku,
            position || index + 1,
            getPayload()
        );
    };

    const payloadP2PAddToCartActionAnalytics = (id, name) => {
        const { estimatedSubtotal, deliveryPickUpCharges } = orderSummaryDetails;
        const deliveryMethod = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
            ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
            : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY;
        let itemsList = {
            item_list_id: VARIABLE_CONFIG.P2P.ITEM_FAVORITES,
            item_list_name: VARIABLE_CONFIG.P2P.ITEM_FAVORITES_LIST
        };

        let addToCartPayload = {
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            value: estimatedSubtotal,
            delivery_method: deliveryMethod,
            delivery_fee: deliveryMethod === VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY ? deliveryPickUpCharges : 0,
            start_date: moment(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).format(
                EVENT_P2P_NAMES_CONFIG.P2P_DATE_FORMAT
            ),
            end_date: moment(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)).format(
                EVENT_P2P_NAMES_CONFIG.P2P_DATE_FORMAT
            ),
            items: getItemsForAnalytics(itemsList)
        };
        return { ...addToCartPayload };
    };

    const sendAddToCartAnalytics = (product, isSimilarItems = false, rates = []) => {
        const productArr = payloadEcommerceInventoryAddToCartAnalytics([product]);
        const { currency } = productArr?.ecommerce || {};
        const itemsArr = payloadEcommerceInventoryListingAnalytics([product]);
        const { item_id, item_category, item_category2, item_category3, quantity } = itemsArr?.items[0] || {};
        const price =
            rates[0]?.rates?.suggestedRates?.daily ||
            (isNaN(homeAssetsRates[item_id]?.daily)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[item_id]?.daily);
        let items = [];
        const item = {
            item_id,
            item_name: product?.name?.trim() || product?.producttitle?.trim(),
            item_list_id: isSimilarItems
                ? VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR
                : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                ? VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_ID
                : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                ? VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_ID
                : VARIABLE_CONFIG.PRODUCT.LIST_ID,
            item_list_name: isSimilarItems
                ? VARIABLE_CONFIG.ALTERNATE_INVENTORY.SIMILAR_EQUIPMENT
                : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                ? VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME
                : product?.listName === VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                ? VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME
                : VARIABLE_CONFIG.PRODUCT.LIST_NAME,
            categories: getUniqueCategories([
                item_category || product?.categoryname,
                item_category2 || product?.parentCategory,
                item_category3 || product?.subcategoryname
            ]),
            price: price,
            quantity
        };
        if (isSimilarItems || product?.listName) {
            item.position = product?.index + 1;
        } else {
            item.index = 0;
        }
        items.push(item);
        sendEventsForAddToCart(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ADD_TO_CART, currency, price, items);
    };

    const handleAvailabilityToggleEvents = checked => {
        sendEventsForAvailabilityToggle(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_TOGGLE_CHANGED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_TOGGLE_NAME,
            checked ? VARIABLE_CONFIG.AVAILABILITY_TOGGLE.HIDE : VARIABLE_CONFIG.AVAILABILITY_TOGGLE.SHOW
        );
    };

    const getItemStatusDetails = items => {
        const unavailable_items = items?.some?.(item => item?.tileStatus !== TILE_STATES.AVAILABLE) || false;
        return { unavailable_items };
    };

    const sendConfirmationModalAnalytics = (isConfirmed, isCloseClicked, isBackClicked, items) => {
        sendEventsForUnavailableCta(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CONFIRM_CHANGED_DETAILS,
            isCloseClicked
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE
                : isBackClicked
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE
                : isConfirmed
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_YES_CHANGE_DETAILS
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_NO_DONT_CHANGE_DETAILS,
            isCloseClicked
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_MODAL_ICON
                : isBackClicked
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_BACK_LINK_TYPE
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_LINK_LOCATION,
            '',
            getItemStatusDetails(items)
        );
    };

    const sendConfirmationModalViewAnalytics = items => {
        sendEventsForEcommerceAction(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED, {
            form_name: EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CONFIRM_CHANGED_DETAILS,
            ...getItemStatusDetails(items)
        });
    };

    return [
        {
            payloadEcommerceActionAnalytics,
            payloadEcommerceStepActionAnalytics,
            payloadEditQuoteActionAnalytics,
            payloadEcommerceLocationActionAnalytics,
            payloadEcommerceLocationActionSubmitted,
            payloadEcommercePageData,
            payloadEcommerceInventoryListingAnalytics,
            payloadEcommerceInventoryAddToCartAnalytics,
            payloadEcommerceInventoryViewedAnalytics,
            sendAnalyticsDatePickerViewEvent,
            sendAnalyticsDatePickerModalEvents,
            handleAnalyticsOnFocus,
            getEcommerceData,
            payloadQuoteDetailsPageLoadActionAnalytics,
            payloadSearchAndViewEventsForPurchaseOrderList,
            createPayloadTransmitQuotePageLoadActionAnalytics,
            payloadSearchAndViewEventsForQuoteList,
            handleChangeCtaAnalytics,
            payloadEcommerceNoStoreNearby,
            payloadP2PAddToCartActionAnalytics,
            sendAddToCartAnalytics,
            payloadEcommerceViewItemList,
            handleAvailabilityToggleEvents,
            sendConfirmationModalAnalytics,
            sendConfirmationModalViewAnalytics
        }
    ];
};

export default useAnalytics;
