export const locationDetailDrawerDataLocators = {
    location_Detail_Drawer_heading_testid: 'location_Detail_Drawer_heading',
    location_Detail_Drawer_branchid: 'location_Detail_Drawer_branchid',
    location_Detail_Drawer_distance: 'location_Detail_Drawer_distance',
    location_Detail_Drawer_phonenumber: 'location_Detail_Drawer_phonenumber',
    location_Detail_Drawer_email: 'location_Detail_Drawer_email',
    location_Detail_Drawer_address: 'location_Detail_Drawer_address',
    location_Detail_Drawer_getdirection_cta: 'location_Detail_Drawer_getdirection_cta',
    location_Detail_Drawer_openinghours: 'location_Detail_Drawer_openinghours',
    location_Detail_Drawer_storedetails: 'location_Detail_Drawer_storedetails',
    location_Detail_Drawer_backcta: 'location_Detail_Drawer_backcta'
};
