import React, { memo, useEffect, useRef } from 'react';
import { bool, element, func, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import DefaultClose from '../../../resources/images/close.svg';
import FilterModalClose from '../../../resources/images/filter-modal-close.svg';
import Button from '../../global/atoms/button/button';
import './locationLegendModal.scss';

const LocationLegendModal = props => {
    const { isOpen, onRequestClose, title, content, footer, handleClose, isFilterModal } = props;
    const intl = useIntl();
    const modalTitleRef = useRef(null);
    const handleOnAfterOpen = () => {
        modalTitleRef.current.focus();
    };
    useEffect(() =>{
        if(isOpen){
            ReactModal.setAppElement('.root');
        }
    },[isOpen])


    return (
        <ReactModal
            isOpen={isOpen}
            overlayClassName={'location-legend-modal__overlay'}
            onRequestClose={onRequestClose}
            portalClassName={'location-legend-modal'}
            className={'location-legend-modal__content'}
            onAfterOpen={handleOnAfterOpen}>
            {title && (
                <h6
                    className="location-legend-modal__title"
                    tabIndex={'0'}
                    ref={modalTitleRef}
                    data-testid={'legendmodal-title'}>
                    {title}
                </h6>
            )}
            {content && <div className="location-legend-modal__content-wrap">{content}</div>}
            {footer && <div className="location-legend-modal__footer">{footer}</div>}
            <Button
                dataTestid={'legend-modal-close'}
                onClick={handleClose}
                className={'location-legend-modal__close'}
                buttonAriaLabel={intl.formatMessage({ id: 'llp-modal-close' })}
                tabIndex={'0'}>
                {isFilterModal ? <FilterModalClose /> : <DefaultClose />}
            </Button>
        </ReactModal>
    );
};

LocationLegendModal.defaultProps = {
    title: '',
    content: '',
    footer: '',
    isOpen: false,
    onRequestClose: () => {},
    handleClose: () => {},
    isFilterModal: false
};

LocationLegendModal.prototype = {
    title: oneOfType([string, element]),
    content: oneOfType([string, element]),
    footer: oneOfType([string, element]),
    onRequestClose: func,
    handleClose: func,
    isOpen: bool,
    isFilterModal: bool
};

export default memo(LocationLegendModal);
