import GoogleMapReact from 'google-map-react';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ENV_CONFIG } from '../../../constants/envConfig';
import useMedia from '../../../hooks/useMedia';
import CurrentLocationMarker from '../../../resources/images/current_location_green.svg';
import { MEDIA_TYPE } from '../../global/constants';
import { googleApiKey } from '../../global/utils/commonUtils';
import { GOOGLE_MAP_STYLES, MAP_LAT_LONG_BOUNDS, MAX_ZOOM } from '../constants';
import useLLP from '../hooks/useLLP';
import LocationLegend from '../locationLegend';
import LocationZoomControl from '../locationZoomControl/LocationZoomControl';
import LocationMapMarker from './locationMapMarker/LocationMapMarker';
import './storesLocationMap.scss';

//google map style


const StoresLocationMap = props => {
    const {
        selectedLatLong,
        storesList,
        selectedStoreValue,
        onClickMarker,
        defaultCenter,
        isCurrentLocationAllowed,
        currentLocation,
        setFocusedLocation,
        zoomOutMax,
        userLatLong,
        showDefaultZoomcontrol,
        showCustomZoomControlDesktop,
        isLlpModalOpen,
        isSearchByBranch
    } = props;
    const { setDefZoom, setMinZoom } = useLLP();
    const [zoom, setZoom] = useState(setDefZoom(zoomOutMax, zoom));
    const [center, setCenter] = useState({
        lat: selectedLatLong.lat,
        lng: selectedLatLong.long
    });

    const defaultcenter = {
        lat: defaultCenter.lat,
        lng: defaultCenter.long
    };
    const mediaType = useMedia();

    useEffect(() => {
        setZoom(setDefZoom(zoomOutMax, zoom));
    }, [userLatLong?.lat, userLatLong?.long]);

    useEffect(() => {
        setZoom(setDefZoom(zoomOutMax, zoom));
    }, [zoomOutMax, selectedLatLong?.lat, selectedLatLong?.long]);

    useEffect(() => {
        if (zoom > 5) {
            setCenter({ lat: selectedLatLong?.lat, lng: selectedLatLong?.long });
        }
    }, [zoom, selectedLatLong?.lat, selectedLatLong?.long]);

    const onChange = ({ zoom }) => {
        setZoom(zoom);
    };

    const handleZoomIn = () => {
        if (zoom < MAX_ZOOM) {
            setZoom(prevZoom => prevZoom + 1);
        }
    };

    const handleZoomOut = () => {
        if (zoom > setMinZoom()) {
            setZoom(prevZoom => prevZoom - 1);
        }
    };

    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: googleApiKey }}
                // bootstrapURLKeys={{ key: 'AIzaSyDY6u8g5tPsfyZ_NST8Ne08OcA7IPlhJlw' }}
                defaultCenter={defaultcenter}
                center={center}
                zoom={zoom}
                // onGoogleApiLoaded={({ map }) => getMapBounds(map)}
                options={maps => ({
                    zoomControl: showDefaultZoomcontrol,
                    styles: GOOGLE_MAP_STYLES,
                    maxZoom: MAX_ZOOM,
                    minZoom: setMinZoom(),
                    fullscreenControl: false,
                    zoomControlOptions: {
                        position: maps.ControlPosition.RIGHT_TOP
                    },
                    restriction: {
                        latLngBounds: MAP_LAT_LONG_BOUNDS
                    }
                })}
                onChange={onChange}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={setDefZoom(zoomOutMax, zoom)}>
                {storesList?.map((item, index) => {
                    return (
                        <LocationMapMarker
                            key={index}
                            lat={item?.latitude}
                            itemData={item}
                            pc={item.pc}
                            onClickMarker={(item) => {
                                onClickMarker(item);
                            }}
                            selectedStoreValue={selectedStoreValue}
                            lng={item?.longitude}
                            setFocusedLocation={setFocusedLocation}
                            specialty={item?.specialties?.[0]}
                            isLlpModalOpen={isLlpModalOpen}
                            isSearchByBranch={isSearchByBranch}
                        />
                    );
                })}
                {(isCurrentLocationAllowed || userLatLong) && (
                    <div
                        lat={currentLocation?.lat || userLatLong?.lat}
                        lng={currentLocation?.long || userLatLong?.long}>
                        <CurrentLocationMarker className="mapIcon" />
                    </div>
                )}
            </GoogleMapReact>
            {mediaType !== MEDIA_TYPE.mobile && <LocationLegend title={ENV_CONFIG.LLP_LEGEND_TITLE} />}
            {showCustomZoomControlDesktop && mediaType === MEDIA_TYPE.desktop && (
                <LocationZoomControl
                    onZoomOut={handleZoomOut}
                    onZoomIn={handleZoomIn}
                    minZoom={setMinZoom()}
                    zoom={zoom}
                    maxZoom={MAX_ZOOM}
                />
            )}
        </>
    );
};

StoresLocationMap.defaultProps = {
    showDefaultZoomcontrol: true,
    showCustomZoomControlDesktop: false,
    isLlpModalOpen: false,
    isSearchByBranch: false
};

StoresLocationMap.propTypes = {
    showDefaultZoomcontrol: bool,
    showCustomZoomControlDesktop: bool,
    isLlpModalOpen: bool,
    isSearchByBranch: bool
};

export default StoresLocationMap;
