import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    RESET_GEOGRAPHY,
    RESET_LOCATION_DATA,
    RESET_PROJECT,
    SET_CAP_ACCOUNT_SELECTED,
    SET_SHOW_CHOOSE_ACCOUNT_DRAWER,
    SET_SHOW_JOB_SITE_DRAWER,
    UPDATE_ACCOUNT,
    UPDATE_USER_TYPE,
    SET_CREDIT_NEWADDR_FLAG,
    SET_LAST_USED_JOBSITE,
    SET_LOCATION_LOADING
} from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart/cartContext';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import useUserData from '../../../../hooks/useUserData';
import AlertIconRed from '../../../../resources/images/alert-circle-red.svg';
import {
    MIN_SEARCH_QUERY_LENGTH,
    MIN_ERROR_MSG_LENGTH,
    DEFAULT_ACCOUNT_ROW_HEIGHT,
    DEFAULT_ACCOUNT_ROW_HEIGHT_P2P,
    isAccountNotActive
} from '../../utils/commonUtils';
import CloseIcon from '../../../../resources/images/close.svg';
import CrossIcon from '../../../../resources/images/closecircleblack.svg';
import SearchGreen from '../../../../resources/images/searchgreen.svg';
import { useFilterState } from '../../../cap';
import { updateDefaultAccount } from '../../api/CommonResponseHandler';
import Button from '../../atoms/button/button';
import CardRadioButton from '../../atoms/cardRadioButton/cardRadioButton';
import Input from '../../atoms/input/Input';
import { AUTHORITY_TYPE } from '../../constants';
import LongList from '../LongList/LongList';
import SideDrawerModal from '../modals/SideDrawerModal';
import './ChooseAccountDrawer.scss';
import { SET_IS_JOBSITE_RECOMMENDATION_LOADING, SET_SELECTED_STORE_DETAILS } from '../../../cap/constants';
import { ENTER_KEY } from '../../../../constants/screenConstants';

const ChooseAccountDrawer = () => {
    const intl = useIntl();
    const [userState, { getAccountProjectsDotCom, dispatch: userDispatch }] = useUserContext();
    const [, filterDispatch] = useFilterState();
    const [{ userInfo, userAccount }, dispatch] = useCartState();
    const { userProfile, showChooseAccountDrawer, isCapAccountSelected, isUserProfileLoading } = userState;
    const authorityType = useCheckAuthorityType();
    /** For P2P user no need to select first account by default in account drawer */
    const defaultAccount =
        userAccount?.accountNumber || (authorityType === AUTHORITY_TYPE.P2P ? '' : userProfile?.accounts[0]?.account);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedValue, setSelectedValue] = useState(defaultAccount);
    const [accountData, setAccountData] = useState(userAccount || userProfile?.accounts[0] || {});
    const [selectedAccountOnTop, setSelectedAccountOnTop] = useState(true);
    const [accountList, setAccountList] = useState(userProfile?.accounts);
    const defaultCompanyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
    const isCanada = defaultCompanyId !== 1;
    const [{ clearProjectDetailCookies }] = useUserData();
    const [errorMessage, setErrorMessage] = useState('');
    const searchCriteria = ['name', 'account', 'address1', 'address2', 'state', 'city', 'zip'];
    const notFoundErrorMessage = intl.formatMessage({ id: 'no-account-found' });

    useEffect(() => {
        if (accountList?.length === 0 && searchQuery?.length > MIN_ERROR_MSG_LENGTH) {
            setErrorMessage(notFoundErrorMessage);
        } else {
            setErrorMessage('');
        }
    }, [accountList?.length, searchQuery]);

    useEffect(() => {
        updateAccountList();
    }, [searchQuery]);

    useEffect(() => {
        if (selectedAccountOnTop) {
            const sortedAccountList = sortSelectedAccounts(selectedValue, accountList);
            setAccountList(sortedAccountList);
        }
    }, [selectedAccountOnTop, searchQuery]);

    const getAccountList = () => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            return accountList.filter(account => !isAccountNotActive(account.accountStatus)); //filter inactive accounts
        }
        return accountList;
    };

    const sortSelectedAccounts = (selectedAccount, accountList = []) => {
        const clonedAccountList = [...accountList];

        return clonedAccountList.sort((a, b) => {
            const isSelectedA = selectedAccount === a?.account;
            const isSelectedB = selectedAccount === b?.account;

            if (isSelectedA) return -1;
            if (isSelectedB) return 1;
            return 0;
        });
    };

    const updateAccountDetailsHandler = data => {
        setSelectedValue(data?.account);
        setAccountData(data);
    };

    const updateDispatchAccount = accountData => {
        dispatch({
            type: UPDATE_ACCOUNT,
            accountName: accountData?.accountName || accountData?.name,
            accountNumber: accountData?.accountNumber || accountData?.account,
            accountStatus: accountData?.accountStatus,
            isCorpLinkAccount: accountData?.isCorpLinkAccount
        });
    };

    const userDetailHandler = async data => {
        if (!data || !data?.name) {
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(userAccount?.accountNumber, isCanada, userAccount?.accountName);
            }
            updateDispatchAccount(userAccount);
            dispatch({
                type: UPDATE_USER_TYPE,
                userInfo: {
                    ...userInfo,
                    AccountName: userAccount?.accountName,
                    AccountNumber: userAccount?.accountNumber
                }
            });
        } else if (userAccount?.accountNumber !== data?.account) {
            userDispatch({ type: 'setHasAccountChanged', value: true });
            filterDispatch({ type: RESET_LOCATION_DATA });
            filterDispatch({ type: RESET_GEOGRAPHY });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID, data?.account);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(data?.account, isCanada, data?.name);
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ALL_JOBSITE_FETCHED);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: true });
            updateDispatchAccount(data);
            dispatch({
                type: UPDATE_USER_TYPE,
                userInfo: { ...userInfo, AccountName: data?.name, AccountNumber: data?.account }
            });
            clearProjectDetailCookies();
            filterDispatch({ type: RESET_PROJECT });
            filterDispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: true });
            filterDispatch({
                type: SET_LAST_USED_JOBSITE,
                lastUsedJobsite: {}
            });
            filterDispatch({ type: SET_SELECTED_STORE_DETAILS, selectedStoreDetails: {} });
            dispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID, data?.companyID);
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED);
        }

        userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: false });
        if (isCapAccountSelected) {
            userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: false });
        }
    };

    const onSearchInputChange = e => {
        setSearchQuery(e?.target?.value);
    };

    const onEnterKeyPressed = e => {
        if (e.key === ENTER_KEY) {
            updateAccountList();
        }
    };

    const onModalClose = () => {
        userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: false });
        userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: false });
    };

    const updateAccountList = () => {
        if (searchQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            setAccountList(userProfile?.accounts);
            setSelectedAccountOnTop(true);
        } else {
            setAccountList(filterData(searchQuery));
            setSelectedAccountOnTop(false);
        }
    };

    const onResetClick = () => {
        setSearchQuery('');
        setAccountList(userProfile?.accounts);
        setSelectedAccountOnTop(true);
    };

    const onSearchIconClick = () => {
        updateAccountList();
    };

    const handleIconInactive = status => {
        if (isAccountNotActive(status)) {
            return <AlertIconRed tabIndex={'-1'} aria-hidden={true} />;
        }
    };

    const handleTextInactive = status => {
        if (isAccountNotActive(status)) {
            return intl.formatMessage({ id: 'delinquent-account-text' });
        }
        return '';
    };

    const filterData = (query = '') => {
        const loweredQuery = query?.toLowerCase();
        if (loweredQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            return accountList;
        }
        return userProfile?.accounts?.filter(data => {
            return Object.keys(data)?.some(key => {
                if (key === 'address') {
                    return Object.keys(data?.address)?.some(addressKey =>
                        searchCriteria?.includes(addressKey)
                            ? data?.address[addressKey]?.toString()?.toLowerCase()?.includes(loweredQuery)
                            : false
                    );
                }
                return searchCriteria?.includes(key)
                    ? data[key]?.toString()?.toLowerCase()?.includes(loweredQuery)
                    : false;
            });
        });
    };

    const accountDrawerHeading = () => {
        return (
            <>
                <h5>{intl.formatMessage({ id: 'choose-an-account' })}</h5>
                <Button
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    onClick={onModalClose}>
                    <CloseIcon aria-hidden={true} tabIndex={'-1'} />
                </Button>
            </>
        );
    };

    const drawerFooter = () => {
        return (
            <Button
                className="modal-footer-button"
                buttonAriaLabel={intl.formatMessage({ id: 'select-account' })}
                onClick={() => userDetailHandler(accountData)}>
                {intl.formatMessage({ id: 'select-account' })}
            </Button>
        );
    };

    const cardStyle = data => {
        const { address } = data;
        return (
            <div className="card">
                {selectedValue === data?.account && (
                    <div className="card__tag">{intl.formatMessage({ id: 'common:selected' })}</div>
                )}
                <small className="card__title">{data?.name}</small>
                <small className="card__content">
                    <span>
                        {intl.formatMessage({ id: 'account-number-label' })}
                        {data?.account}
                    </span>
                    {handleIconInactive(data?.accountStatus)}
                </small>
                {authorityType === AUTHORITY_TYPE.P2P && (
                    <>
                        <small className="card__content">
                            {address?.address1} {address?.address2}
                        </small>
                        <small className="card__content">
                            {address?.city}, {address?.state} {address?.zip}
                        </small>
                    </>
                )}
            </div>
        );
    };

    const displaySearchResults = data => {
        return (
            data?.name && (
                <div key={data?.name} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={updateAccountDetailsHandler}
                        isAccount={true}
                        radioButtonAriaLabel={`${data?.name} ${intl.formatMessage({
                            id: 'account:ac-minicart-account-number-text'
                        })}${data?.account} ${handleTextInactive(data?.accountStatus)}`}
                    />
                </div>
            )
        );
    };

    const drawerContent = () => (
        <>
            {userProfile?.accounts?.length >= 20 && (
                <div className="search-account">
                    <Input
                        type="text"
                        name="account_search"
                        placeholder={intl.formatMessage({ id: 'search-account' })}
                        value={searchQuery}
                        dataTestId="choose_account_search_input"
                        handleInputChange={onSearchInputChange}
                        handleKeyDown={onEnterKeyPressed}
                        inputAriaLabel={`${intl.formatMessage({
                            id: 'account:ac-common-search-text'
                        })} ${intl.formatMessage({ id: 'search-account' })}`}
                    />
                    {searchQuery && (
                        <Button
                            tabIndex={0}
                            className="crossIcon"
                            onClick={onResetClick}
                            dataTestid="choose_account_cross_icon"
                            customButtonAriaLabel={intl.formatMessage({
                                id: 'pdp:search-bar-cross-icon'
                            })}>
                            <CrossIcon aria-hidden={true} tabIndex={'-1'} />
                        </Button>
                    )}
                    <Button
                        tabIndex={0}
                        className="searchIconClass input-group-append"
                        onClick={onSearchIconClick}
                        customButtonAriaLabel={intl.formatMessage({
                            id: 'search-bar-search-icon'
                        })}>
                        <SearchGreen tabIndex={'-1'} aria-hidden={'true'} />
                    </Button>
                </div>
            )}

            {(accountList?.length > 0 || searchQuery) && (
                <section className="accounts-list-section">
                    {errorMessage ? (
                        <p className="no-account">{errorMessage}</p>
                    ) : (
                        <LongList
                            longList={getAccountList()}
                            rowRendererContent={displaySearchResults}
                            defaultHeight={
                                authorityType === AUTHORITY_TYPE.P2P
                                    ? DEFAULT_ACCOUNT_ROW_HEIGHT_P2P
                                    : DEFAULT_ACCOUNT_ROW_HEIGHT
                            }
                            selectedValue={selectedValue}
                            isLongListLoading={isUserProfileLoading}
                        />
                    )}
                </section>
            )}
        </>
    );

    return (
        <SideDrawerModal
            header={accountDrawerHeading()}
            isModalOpen={showChooseAccountDrawer}
            footer={drawerFooter()}
            content={drawerContent()}
            modalContentClass={`account-drawer-content`}
            handleModalToggle={onModalClose}
            showSliderTransition={true}
        />
    );
};

export default React.memo(ChooseAccountDrawer);
