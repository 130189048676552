import { ENV_CONFIG } from '../../constants/envConfig';

export const DEFAULT_LAT = 40.7608;
export const DEFAULT_LONG = -111.891;
export const DEFAULT_PHONENUMBER = 8006679328;
export const MAP_LAT_LONG_BOUNDS = {
    north: 85,
    south: 5,
    east: -15,
    west: -185
};

const getDynamicFilters = () => {
    const filterStatediv = ENV_CONFIG.SPECIALITY_TYPE_CONFIG;
    let filterStateString = '';
    let filterStateArray = [];
    let filterStateDynamic = {};
    if (filterStatediv?.length > 0) {
        filterStateString = filterStatediv?.[0]?.getAttribute('content');
        filterStateString = filterStateString?.substring(1, filterStateString.length - 1);
        filterStateArray = filterStateString?.split(',');
        filterStateArray.forEach(element => {
            const objArr = element.split('=');
            const searchKey = objArr[0]?.trim();
            const displayValue = objArr[1]?.trim();
            if (filterStateDynamic[displayValue]) {
                filterStateDynamic[displayValue]['searchKey'].push(searchKey);
            } else {
                filterStateDynamic[displayValue] = {
                    searchKey: [searchKey]
                };
            }
        });
    }
    return filterStateString?.length > 0
        ? filterStateDynamic
        : {
              'Industrial Tool': {
                  searchKey: ['Industrial Tool']
              },
              'Flooring Solutions': {
                  searchKey: ['Flooring Solutions']
              },
              'General Equipment & Tools': {
                  searchKey: ['General Equipment & Tools']
              },
              'Ground Protection Solutions': {
                  searchKey: ['Ground Protection']
              },
              'Power & HVAC Services': {
                  searchKey: ['Power & HVAC']
              },
              'Pump Solutions': {
                  searchKey: ['Pump Solutions']
              },
              'Scaffold Services': {
                  searchKey: ['Scaffold Services']
              },
              'Aerial Work Equipment': {
                  searchKey: ['Aerial Work Equipment']
              },
              'Climate Control Services': {
                  searchKey: ['HVAC & Dehumidification']
              },
              'HVAC & Drilling': {
                  searchKey: ['HVAC & Drilling']
              },
              'Industrial Heat Solutions': {
                  searchKey: ['Industrial Heat']
              },
              'Climate Control Services': {
                  searchKey: ['HVAC & Dehumidification']
              },
              'Industrial Tool': {
                  searchKey: ['Industrial Services']
              },
              'Ground Protection Solutions': {
                  searchKey: ['Ground Protection']
              },
              'Oil & Gas Services': {
                  searchKey: ['Oil & Gas Services']
              },
              'Sunbelt Rentals Tool Rental': {
                  searchKey: ['Sunbelt Rentals Tool Rental']
              }
          };
};

const getDynamicURL = () => {
    const urlStatediv = ENV_CONFIG.SPECIALITY_TYPE_URL_CONFIG;
    let urlStateString = '';
    let urlStateArray = [];
    let urlStateDynamic = {};
    if (urlStatediv?.length > 0) {
        urlStateString = urlStatediv?.[0]?.getAttribute('content');
        urlStateString = urlStateString?.substring(1, urlStateString.length - 1);
        urlStateArray = urlStateString?.split(',');
        urlStateArray.forEach(element => {
            const objArr = element.split('=');
            const urlKey = objArr[0]?.trim();
            const urlValue = objArr[1]?.trim();
            urlStateDynamic[urlKey] = urlValue;
        });
    }
    return urlStateString?.length > 0 ? urlStateDynamic : {};
};

export const filterState = getDynamicFilters();
export const dynamicURLs = getDynamicURL();

export const TIER_2_RADIUS = 1000;
export const SCROLL_TO_TOP = 10;
export const GENERAL_EQUIPMENT_AND_TOOLS = "General Equipment & Tools";
export const SHOW_MORE_LIMIT = 10;
export const GOOGLE_MAP_STYLES = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            }
        ]
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161'
            }
        ]
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5'
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5'
            }
        ]
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c9c9c9'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    }
];
export const MAX_ZOOM = 15;

export const mockGetStoreLocations = {
    "data": {
      "pcList": [
        {
          "pc": 7021,
          "name": "BRAMPTON ON PC7021",
          "street": "36 RUTHERFORD RD S",
          "city": "BRAMPTON",
          "state": "ON",
          "zip": "L6W 3J5",
          "companyId": 2,
          "latitude": 59.6649000000000,
          "longitude": -133.1803000000000,
          "phone": "(905) 456-8540",
          "email": "PCM7021@SUNBELTRENTALS.COM                                            ",
          "fax": "",
          "distance": 138.75675601924095,
          "analysisRegion": 920,
          "specialties": [
            "General Equipment & Tools"
          ],
          "specialtyTypes": [
            1
          ],
          "operatingHours": [
            {
              "day": "Sunday",
              "hours": "7:00 am - 10:30 pm",
              "dayOfWeek": 0,
              "isOpen": true
            },
            {
              "day": "Monday",
              "hours": "7:00 am - 11:00 pm",
              "dayOfWeek": 1,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T23:00:00",
              "isOpen": true
            },
            {
              "day": "Tuesday",
              "hours": "7:00 am - 11:00 pm",
              "dayOfWeek": 2,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T23:00:00",
              "isOpen": true
            },
            {
              "day": "Wednesday",
              "hours": "7:00 am - 11:00 pm",
              "dayOfWeek": 3,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T23:00:00",
              "isOpen": true
            },
            {
              "day": "Thursday",
              "hours": "7:00 am - 11:00 pm",
              "dayOfWeek": 4,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T23:00:00",
              "isOpen": true
            },
            {
              "day": "Friday",
              "hours": "7:00 am - 11:00 pm",
              "dayOfWeek": 5,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T23:00:00",
              "isOpen": true
            },
            {
              "day": "Saturday",
              "hours": "7:00 am - 10:00 pm",
              "dayOfWeek": 6,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T22:00:00",
              "isOpen": true
            }
          ]
        },
        {
          "pc": 7033,
          "name": "WOODSTOCK ON PC7033",
          "street": "1050 PARKINSON RD",
          "city": "WOODSTOCK",
          "state": "ON",
          "zip": "N4S 7W3",
          "companyId": 2,
          "latitude": 60.1009000000000,
          "longitude": -132.9304000000000,
          "phone": "(519) 421-7219",
          "email": "PCM7033@SUNBELTRENTALS.COM                                            ",
          "fax": "(519) 421-9761",
          "distance": 148.97647022310045,
          "analysisRegion": 920,
          "specialties": [
            "General Equipment & Tools"
          ],
          "specialtyTypes": [
            1
          ],
          "operatingHours": [
            {
              "day": "Sunday",
              "hours": "Closed",
              "dayOfWeek": 0,
              "isOpen": false
            },
            {
              "day": "Monday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 1,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Tuesday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 2,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Wednesday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 3,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Thursday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 4,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Friday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 5,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Saturday",
              "hours": "Closed",
              "dayOfWeek": 6,
              "isOpen": false
            }
          ]
        }
      ]
    }
  }

export const mockGetStoreDetailsFromPC = {
    "data": {
      "pcList": [
        {
          "pc": 123,
          "name": "PINELLAS PARK PC123",
          "street": "6390 US HIGHWAY 19 N",
          "city": "PINELLAS PARK",
          "state": "FL",
          "zip": "33781 6234",
          "companyId": 1,
          "latitude": 27.8298540000000,
          "longitude": -82.6801130000000,
          "phone": "(727) 526-9133",
          "email": "pcm123@sunbeltrentals.com                                             ",
          "fax": "(727) 362-0390",
          "analysisRegion": 920,
          "specialties": [
            "General Equipment & Tools"
          ],
          "specialtyTypes": [
            1
          ],
          "operatingHours": [
            {
              "day": "Sunday",
              "hours": "Closed",
              "dayOfWeek": 0,
              "isOpen": false
            },
            {
              "day": "Monday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 1,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Tuesday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 2,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Wednesday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 3,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Thursday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 4,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Friday",
              "hours": "7:00 am - 5:00 pm",
              "dayOfWeek": 5,
              "open": "1900-01-01T07:00:00",
              "close": "1900-01-01T17:00:00",
              "isOpen": true
            },
            {
              "day": "Saturday",
              "hours": "Closed",
              "dayOfWeek": 6,
              "isOpen": false
            }
          ]
        }
      ]
    }
  };