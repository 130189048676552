import React from 'react';
import { bool, func, node, string } from 'prop-types';
import CheckedRadio from '../../../../resources/images/checked-square.svg';
import UncheckedRadio from '../../../../resources/images/unchecked-square.svg';
import './checkbox.scss';

const Checkbox = props => {
    const { id, isChecked, customClassName, onClick, label, dataTestId, customAriaLabel, name } = props;
    const handleCheckboxClick = () => {
        onClick(label, !isChecked, id);
    };
    return (
        <div className={`checkbox ${customClassName}`}>
            <input
                className="checkbox__input"
                type="checkbox"
                id={id}
                data-testid={dataTestId}
                onClick={handleCheckboxClick}
                aria-checked={isChecked ? true : false}
            />

            <label htmlFor={id} className="checkbox__label">
                <span className='checkbox__icon-wrap'>
                    {isChecked ? (
                        <CheckedRadio tabIndex={'-1'} aria-hidden={true} className="checkbox__img" />
                    ) : (
                        <UncheckedRadio tabIndex={'-1'} aria-hidden={true} className="checkbox__img" />
                    )}
                </span>

                {label}
            </label>
        </div>
    );
};

export default Checkbox;
Checkbox.propTypes = {
    isChecked: bool,
    onClick: func,
    label: node,
    customClassName: string,
    dataTestId: string,
    customAriaLabel: string,
    id: string
};

Checkbox.defaultProps = {
    isChecked: false,
    onClick: () => {},
    customClassName: '',
    dataTestId: '',
    label: '',
    customAriaLabel: '',
    id: ''
};
