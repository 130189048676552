import { formatAccountProjects } from '../global/utils/commonUtils';
import { isValidString, logError } from '../global/utils/logger';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { SET_LAST_USED_JOBSITE } from '../../aem-core-components/actions/constants';
import {
    SET_IS_JOBSITE_RECOMMENDATION_LOADING,
    SET_IS_SUGGESTIONS_LOADING,
    SET_RECOMMENDED_JOBSITES,
    SET_SUGGESTED_JOBSITE_ADDRESS
} from './constants';

let controller;

export const getJobsitesAddressSuggestionAction = async ({
    getAllJobsitesAndAddress,
    accountId,
    search,
    searchString,
    isLocationAvailable,
    limit,
    location,
    radius,
    includeActiveEquipment,
    invalidateCache,
    dispatch
}) => {
    try {
        if (controller && isValidString(sessionStorage.getItem('isAddressLoading'))) {
            controller.abort();
        }
        controller = new AbortController();
        sessionStorage.setItem('isAddressLoading', true);
        dispatch({ type: SET_IS_SUGGESTIONS_LOADING, isLoading: true });
        let countryId = isLocationAvailable
            ? parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
            : 0;
        let payload = { searchString, limit, includeActiveEquipment, invalidateCache, location, radius };
        if (accountId) {
            payload = { ...payload, accountId, search };
        }
        const { data } = await getAllJobsitesAndAddress({
            variables: payload,
            fetchPolicy: 'network-only',
            context: {
                headers: { 'company-id': countryId },
                fetchOptions: { signal: controller?.signal }
            }
        });
        const { jobSites, autoSuggest } = data?.getSbrJobSitesAndAutoSuggest || {};
        dispatch({
            type: SET_SUGGESTED_JOBSITE_ADDRESS,
            jobsitesAndAddresses: {
                jobsiteSuggestions: formatAccountProjects(jobSites?.data) || [],
                addressSuggestions: autoSuggest?.data?.predictions || []
            }
        });
        dispatch({ type: SET_IS_SUGGESTIONS_LOADING, isLoading: false });
        sessionStorage.setItem('isAddressLoading', false);
    } catch (error) {
        logError(error, false, 'getJobsitesAddressSuggestionAction', [accountId, search]);

        if (error.name !== 'ApolloError' && error?.message !== 'The user aborted a request.') {
            dispatch({ type: SET_IS_SUGGESTIONS_LOADING, isLoading: false });

            sessionStorage.setItem('isAddressLoading', false);
        }
    }
};

export const getRecommendedJobSitesAction = async ({ getRecommendedJobSites, accountId, recommended, dispatch }) => {
    try {
        const filterSuggestions = (suggestions, rType) => {
            const filteredData = suggestions?.filter(item => item?.Rtype === rType)?.slice(0, 2);
            return filteredData || [];
        };
        dispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: true });
        let recommendations = [];
        let lastUsedJobsite = [];
        const { data } = await getRecommendedJobSites({
            variables: {
                accountId,
                recommended
            }
        });
        const { recommededJobsites: recommendedJobsites, lastusedjobsites } = data?.getRecommendedJobSites || {};
        if (recommendedJobsites?.data?.[0]?.Rtype) {
            recommendations.push(...filterSuggestions(recommendedJobsites?.data, 'Recently Created'));
            recommendations.push(...filterSuggestions(recommendedJobsites?.data, 'Recently Rented'));
            recommendations.push(...filterSuggestions(recommendedJobsites?.data, 'Most Rented'));
        } else {
            recommendations = recommendedJobsites?.data?.slice(0, 6);
        }
        const formattedRecommededJobsites = formatAccountProjects(recommendations) || [];
        if (lastusedjobsites?.data) {
            lastUsedJobsite = formatAccountProjects(lastusedjobsites?.data);
        }
        dispatch({
            type: SET_LAST_USED_JOBSITE,
            lastUsedJobsite: lastUsedJobsite?.[0] || {}
        });
        dispatch({
            type: SET_RECOMMENDED_JOBSITES,
            recommendedJobsites: formattedRecommededJobsites
        });
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE,
            JSON.stringify(formattedRecommededJobsites)
        );
        dispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: false });
    } catch (error) {
        logError(error, false, 'getRecommendedJobSitesAction', [accountId]);
        dispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: false });
    }
};

export const updateOldEstimatesAction = async ({ updateOldEstimatesMutation, cartId }) => {
    try {
        const { data, error } = await updateOldEstimatesMutation({
            variables: {
                cartId
            }
        });
        if (error) {
            logError(error, false, 'updateOldEstimatesAction', [cartId]);
        }
        return { data };
    } catch (error) {
        logError(error, false, 'updateOldEstimatesAction', [cartId]);
    }
};

export const updateCoachmarkAction = async ({ updateCoachmarkMutation, pickstore, location, producttile }) => {
    try {
        const { data, errors } = await updateCoachmarkMutation({
            variables: {
                pickstore,
                location,
                producttile
            }
        });
        if (errors) {
            logError(errors[0].message, false, 'updateCoachmarkAction', [pickstore, location, producttile]);
        }
        return { data };
    } catch (error) {
        logError(error, false, 'updateCoachmarkAction', [pickstore, location, producttile]);
    }
};
