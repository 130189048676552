import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../atoms/button/button';
import SelectedFilterLabel from './SelectedFilterLabel';

export default function SelectedFilter({ setIsClearAll, setClickedOption, localFilterData, setLocalFilterData }) {
    const intl = useIntl();

    const displayLabel = () => {
        return Object.keys(localFilterData).map((item, index) => {
            return localFilterData[item]?.selectedOptions?.map(label => (
                <SelectedFilterLabel key={label} labelText={label} onClose={() => removeLabel(item, label)} />
            ));
        });
    };

    const labels = displayLabel();
    const clearAll = () => {
        setClickedOption('');
        setIsClearAll(true);
    };
    const removeLabel = (filterName, label) => {
        setClickedOption(label);
        const currntFilters = localFilterData[filterName];
        const isChecked = currntFilters?.selectedOptions?.includes(label);
        var localFilter = { ...localFilterData };
        if (isChecked) {
            localFilter[filterName].selectedOptions = localFilter[filterName].selectedOptions.filter(
                item => item !== label
            );
        }
        setLocalFilterData(localFilter);
    };
    return (
        <>
            {labels[0]?.length > 0 && (
                <div className={`filter__body-badges ${labels[0]?.length > 1 ? 'filter__body-badgesmore' : ''}`}>
                    {labels}
                </div>
            )}

            {labels[0]?.length > 1 && (
                <div className="filter__body-topclear">
                    <Button
                        type="button"
                        className={'filter__body-topbutton'}
                        onClick={clearAll}
                        customButtonAriaLabel={intl.formatMessage({ id: 'filter:clear-all-btn-text' })}>
                        {intl.formatMessage({ id: 'filter:clear-all-btn-text' })}
                    </Button>
                </div>
            )}
        </>
    );
}
