import { gql } from '@apollo/client';

const MUTATION_MERGE_CARTS_ON_EDIT_QUOTE = gql`
    mutation mergeQuoteForEdit($sfGuiQuoteId: String!, $cart_id: String!, $merge: Boolean) {
        mergeQuoteForEdit(input: { sfGuiQuoteId: $sfGuiQuoteId, cart_id: $cart_id, merge: $merge }) {
            status
            message
            cart {
                id
            }
            token
        }
    }
`;
export default MUTATION_MERGE_CARTS_ON_EDIT_QUOTE;
