import { cookieValue } from '../../aem-core-components/utils/cookieUtils';
const userCookieVal = cookieValue('userCookie');
const isSignedInFunc = () => !!userCookieVal;
export const userInitialState = {
    currentUser: {
        firstname: '',
        lastname: '',
        email: '',
        addresses: []
    },
    token: userCookieVal,
    isSignedIn: isSignedInFunc(),
    isAccountDropdownOpen: false,
    isShowAddressForm: false,
    addressFormError: null,
    updateAddress: null,
    updateAddressError: null,
    deleteAddress: null,
    deleteAddressError: null,
    signInError: null,
    inProgress: false,
    createAccountError: null,
    createAccountEmail: null,
    cartId: null,
    accountDropdownView: null,
    userProfile: null,
    accountProjects: [],
    userType: null,
    wishlist: {},
    overridePCList: [],
    isUserProfileLoading: true,
    isCheckoutLoading: localStorage.getItem('isCheckoutLoading')
        ? JSON.parse(localStorage.getItem('isCheckoutLoading'))
        : false,
    showInactivityNotification: false,
    isOverRidePCLoading: false,
    isProjectsLoading: false,
    isJobSiteLoaded: '',
    hasAccountChanged: false,
    isProfileLoaded: false,
    isUserSigningIn: false,
    showSignInModal: false,
    showChooseAccountDrawer: false,
    showJobSiteDrawer: false,
    isCapAccountSelected: false
};
