import { USER_TYPE } from '../../constants/userDetailsConstants';

export const getStorelatLong = (storesList, selectedStoreValue) => {
    const data = storesList?.find(item => {
        return item?.pc === selectedStoreValue;
    });
    const obj = {};
    obj.lat = data?.latitude || storesList[0].latitude;
    obj.long = data?.longitude || storesList[0].longitude;
    return obj;
};
export const formatCreditAddress = (userTypeCookie, viewCart, isCreditNewAddress, getProjectsFromCookie) => {
    const projectInfoCookies = getProjectsFromCookie();
    let location;
    if (isCreditNewAddress || userTypeCookie !== USER_TYPE.CREDIT) {
        location = viewCart?.location;
    } else {
        location = `${projectInfoCookies?.SLA} ${
            projectInfoCookies?.projectAddress2 ? projectInfoCookies?.projectAddress2 : ''
        } ${projectInfoCookies?.SLC} ${projectInfoCookies?.SLS} ${projectInfoCookies?.SLZ}`;
    }
    return location;
};

export const setLocationInStorage = ({ lat, long, formattedAddress }) => {
    const locationSearchFields = {
        lat,
        long,
        formattedAddress
    };
    sessionStorage.setItem('locationSearchFields', JSON.stringify(locationSearchFields));
};
