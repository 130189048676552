import React, { useEffect, useRef } from 'react';
import { bool, func, object, string } from 'prop-types';
import { trapFocus } from '../../global/utils/commonUtils';
import { filterState } from '../constants';
import '../location.scss';
import Filters from './Filters';
import { useLocationState } from '../../../contexts/location/locationContext';

export const LocationFilter = props => {
    const {
        wrapClass,
        isFilterModalOpen,
        setIsFilterModalOpen,
        filterData,
        setFilterData,
        subfiltertitle,
        initFilterData,
        filterBodyRef,
        filterBodyClasess,
        filterTitleClass,
        setIsNoStoreAvailable
    } = props;

    const [{ specialtyFilterDescriptions }] = useLocationState();

    const formattedFilterObj = () => {
        const filter = {};
        let choices = [];
        for (let key in filterState) {
            const specialtyDetails = specialtyFilterDescriptions?.find(
                specialty => specialty?.overrideName == key || specialty?.specialtyName == key
            );
            choices.push({
                name: key,
                specialtyDescription: specialtyDetails?.specialtyDescription,
                learnMoreLink: specialtyDetails?.learnMoreLink?.url
            });
        }
        filter[subfiltertitle] = {
            title: subfiltertitle,
            multi: true,
            choices: choices
        };
        return filter;
    };
    const formattedFilter = formattedFilterObj();

    const handleIndividualfilters = currentFilterVal => {};
    const locationFilterRef = useRef();

    useEffect(() => {
        const locationFilterDrawerDesktop = document?.querySelector('.location__filter--desktop');
        if (locationFilterDrawerDesktop) {
            trapFocus(locationFilterDrawerDesktop);
            const locationFilterDrawerCurrent = locationFilterRef?.current;
            locationFilterDrawerCurrent?.querySelector('.location__filter-top')?.focus();
        }
    }, [isFilterModalOpen]);

    return (
        <aside className={`location__filter ${wrapClass}`} ref={locationFilterRef}>
            <Filters
                setClickedOption={handleIndividualfilters} //for setting currently clicked option
                filterTitleClass={`location__filter-top ${filterTitleClass}`}
                subfiltertitle={subfiltertitle}
                title={'Filter'}
                filterBodyClasess={`location__filter-body ${filterBodyClasess}`}
                filterBodyRef={filterBodyRef}
                modalIsOpen={isFilterModalOpen}
                data={formattedFilter}
                selectedOptions={filterData?.[`${subfiltertitle}`]?.selectedOptions} //contains all checked options
                setFilterData={setFilterData} //for setting all clicked option
                setModalIsOpen={setIsFilterModalOpen}
                initFilterData={initFilterData}
                filterOptionsObj={filterState}
                setIsNoStoreAvailable={setIsNoStoreAvailable}
            />
        </aside>
    );
};
LocationFilter.defaultProps = {
    wrapClass: '',
    isFilterModalOpen: true,
    filterData: {},
    setFilterData: () => {},
    subfiltertitle: '',
    initFilterData: () => {},
    filterBodyRef: null,
    filterBodyClasess: '',
    filterTitleClass: '',
    showMoreClicked: false,
    setShowMoreClicked: () => {},
    setIsNoStoreAvailable: () => {}
};

LocationFilter.propTypes = {
    wrapClass: string,
    isFilterModalOpen: bool,
    setIsFilterModalOpen: func,
    filterData: object,
    setFilterData: func,
    subfiltertitle: string,
    initFilterData: func,
    filterBodyRef: object,
    filterBodyClasess: string,
    filterTitleClass: string,
    showMoreClicked: bool,
    setShowMoreClicked: func,
    setIsNoStoreAvailable: func
};
