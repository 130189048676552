import React, { memo, useState } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import Close from '../../../../resources/images/close.svg';
import Logo from '../../../../resources/images/logo52.svg';
import config from '../../../App/config';
import Button from '../../atoms/button/button';
import './oktasigin.scss';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { logError } from '../../utils/logger';

const OktaModal = ({ isOpen, handleRequestClose }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const handleIframeLoad = () => {
        setIsLoading(!isLoading);
    };
    const handleOktaClose = () => {
        setIsLoading(!isLoading);
        handleRequestClose();
    };

    window.onmessage = function (e) {
        try {
            if (e?.data == 'redirecting') {
                setIsLoading(true);
            }
        } catch (e) {
            logError(e, false, 'onMessage', [e?.data]);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleOktaClose}
            overlayClassName={'okta__overlay'}
            className={'okta__content-wrap'}>
            <div className="okta__header">
                {ENV_CONFIG?.CAP_LOGO_SOURCE ? (
                    <img src={ENV_CONFIG?.CAP_LOGO_SOURCE} alt="logo" className="okta__logo-img" />
                ) : (
                    <Logo className="okta__logo" />
                )}

                <Button
                    dataTestid={'oktaCloseButton'}
                    className="okta__header-close"
                    onClick={handleOktaClose}
                    buttonAriaLabel={intl.formatMessage({ id: 'time-out-session-modal:clickToClose' })}>
                    <Close />
                </Button>
            </div>
            <div className="okta__content">
                {isLoading && (
                    <div className="okta__signin-iframe" data-testid="oktaLoader">
                        <div className="shimmer okta__content--title-shimmer"></div>
                        <div className="shimmer okta__content--label-shimmer"></div>
                        <div className="shimmer okta__content--input-shimmer"></div>
                        <div className="shimmer okta__content--label-shimmer"></div>
                        <div className="shimmer okta__content--input-shimmer"></div>
                        <div className="shimmer okta__content--checkbox-shimmer"></div>
                        <div className="shimmer okta__content--button-shimmer"></div>
                        <div className="shimmer okta__content--action-shimmer"></div>
                        <div className="shimmer okta__content--action-shimmer"></div>
                        <div className="okta__content--bottom">
                            <div className="shimmer okta__content--bottom-shimmer"></div>
                        </div>
                    </div>
                )}
                <iframe
                    aria-label="use this window to sign in"
                    data-testid="oktaIframe"
                    src={config.pagePaths.signInIframe}
                    className={`okta__signin-iframe ${
                        isLoading ? 'okta__signin-iframe--hide' : 'okta__signin-iframe--show'
                    }`}
                    onLoad={handleIframeLoad}
                />
            </div>
        </Modal>
    );
};

OktaModal.propTypes = {
    handleRequestClose: func,
    isOpen: bool
};

OktaModal.defaultProps = {
    isOpen: false,
    handleRequestClose: () => {}
};
export default memo(OktaModal);
