import React from 'react';
import { useIntl } from 'react-intl';
import CloseIcon from '../../../../resources/images/smallCross.svg';
import Button from '../../atoms/button/';
import './filters.scss';

const SelectedFilterLabel = props => {
    const { labelText, onClose } = props;
    const intl = useIntl();

    return (
        <div className="filter__item-label">
            <span className="filter__item-labeltext">{labelText}</span>
            <Button
                tabIndex={0}
                className="filter__item-clear"
                type="button"
                customButtonAriaLabel={intl.formatMessage({ id: 'search:remove-filter' }) + labelText}
                onClick={onClose}
                >
                <CloseIcon aria-hidden="true" tabIndex={'-1'} />
            </Button>
        </div>
    );
};

export default SelectedFilterLabel;
