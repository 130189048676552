import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { App as CommerceApp, ConfigContextProvider, Portal } from '../../aem-core-components';
import MultiPortal from '../../aem-core-components/components/Portal/MultiPortal';
import Wires from '../global/atoms/wires/Wires';
import RentalsCombineTab from '../global/modules/rentalsCombineTab/RentalsCombineTab';
import { initSentry } from '../global/utils/logger';
import LocationPage from '../location';
import ContactUsMarketo from '../marketoforms/contactUs';
import AddToCartProductTile from '../global/modules/productTile/addToCartProductTile';
import Punchout from '../punchout/Punchout';
import PopularRentals from '../search/popularrentals/PopularRentals';
import { CapHeader, CapSignIn } from '../cap';
import partialConfig from './config';
import Global from './Global';
//const Global = React.lazy(() => import(/* webpackMode: "eager" */'./Global'));
import loadLocaleData, { locale, messages } from './i18n';

const App = props => {
    const {
        storeView,
        graphqlEndpoint,
        graphqlMethod = 'POST',
        headers = '{}'
    } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );
    const { mountingPoints, pagePaths } = partialConfig;
    const config = {
        ...partialConfig,
        storeView,
        graphqlEndpoint,
        // Can be GET or POST. When selecting GET, this applies to cache-able GraphQL query requests only. Mutations
        // will always be executed as POST requests.
        graphqlMethod,
        headers
    };
    return (
        <IntlProvider locale={locale} messages={messages}>
            <ConfigContextProvider config={config}>
                <CommerceApp>
                    <Global />
                    <Portal selector={mountingPoints.contactUsMarketo}>
                        <ContactUsMarketo />
                    </Portal>
                    <Portal selector={mountingPoints.locationPage}>
                        <LocationPage />
                    </Portal>
                    <MultiPortal selector={mountingPoints.addToCartTile}>
                        <AddToCartProductTile />
                    </MultiPortal>
                    <Portal selector={mountingPoints.combinedTabs}>
                        <RentalsCombineTab />
                    </Portal>
                    <Portal selector={mountingPoints.punchoutUserSection}>
                        <Punchout />
                    </Portal>
                    <Portal selector={mountingPoints.popularRentals}>
                        <PopularRentals />
                    </Portal>
                    <Portal selector={mountingPoints.wiresCrossed}>
                        <Wires />
                    </Portal>
                    <Portal selector={mountingPoints.capSideHeader}>
                        <CapSignIn />
                    </Portal>
                    <Portal selector={mountingPoints.capHeader}>
                        <CapHeader />
                    </Portal>
                </CommerceApp>
            </ConfigContextProvider>
        </IntlProvider>
    );
};
const renderDOM = async () => {
    const { locale, messages } = await loadLocaleData();
    const root = document.createElement('div');
    document.body.appendChild(root);
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (!isDevelopment) {
        initSentry();
    }
    // TODO revert change once dst map is move from here

    // const rootContainer = createRoot(root);
    // rootContainer.render(
    //     <Router>
    //         <App locale={locale} messages={messages} />
    //     </Router>
    // );
    ReactDOM.render(
        <Router>
            <App locale={locale} messages={messages} />
        </Router>,
        root
    );
};

document?.addEventListener('readystatechange', () => {
    if (document?.readyState === 'complete') {
        renderDOM();
    }
});

export default App;
