import React from 'react';
import { useIntl } from 'react-intl';
import useMedia from '../../../../hooks/useMedia';
import './filters.scss';
import CloseIcon from '../../../../resources/images/close.svg';
import { bool } from 'prop-types';
import { globalCheckboxFiltersDataLocators } from './globalCheckboxFiltersDataLocators';

const FilterHeader = props => {
    const { onCloseHandler, title, filterTitleClass, showCloseButton, ...restProps } = props;
    const mediaType = useMedia();
    const intl = useIntl();
    return (
        <>
            
            <h6 className={`${filterTitleClass}`} tabIndex={'0'}>
                {title}
            </h6>
            {showCloseButton && (
                <button
                    className="mobile-filter__title-close"
                    onClick={onCloseHandler}
                    aria-label={intl.formatMessage({ id: 'common:close-filter' })}
                    data-testid={globalCheckboxFiltersDataLocators.filter_header_expand_filters}
                    tabIndex="0">
                    <CloseIcon aria-hidden="true" tabIndex={'-1'} />
                </button>
            )}
        </>
    );
};
FilterHeader.propTypes = {
    showCloseButton: bool
};

FilterHeader.defaultProps = {
    showCloseButton: false
};
export default FilterHeader;
