import React, { memo } from 'react';
import { func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import Minus from '../../../resources/images/minus.svg';
import Plus from '../../../resources/images/plus.svg';
import Button from '../../global/atoms/button/button';
import { MAX_ZOOM } from '../constants';
import './locationzoomcontrol.scss';

const LocationZoomControl = (props) => {
    const intl = useIntl();
    const { onZoomIn, onZoomOut, zoom, minZoom, wrapperClass, maxZoom } = props;
    return (
        <div className={`zoomcontrol ${wrapperClass}`}>
            <Button
                dataTestid={'zoomInButton'}
                isdisabled={zoom >= maxZoom}
                className={'button zoomcontrol__button'}
                buttonAriaLabel={intl.formatMessage({ id: 'location:zoom-in-text' })}
                onClick={onZoomIn}
                tabIndex={'0'}>
                <Plus tabIndex={'-1'} aria-hidden={true} />
            </Button>
            <Button
                dataTestid={'zoomOutButton'}
                isdisabled={zoom <= minZoom}
                className={'button zoomcontrol__button'}
                buttonAriaLabel={intl.formatMessage({ id: 'location:zoom-out-text' })}
                onClick={onZoomOut}
                tabIndex={'0'}>
                <Minus tabIndex={'-1'} aria-hidden={true} />
            </Button>
        </div>
    )
}

LocationZoomControl.defaultProps = {
    wrapperClass: '',
    onZoomIn: () => { },
    onZoomOut: () => { },
    maxZoom: MAX_ZOOM

};

LocationZoomControl.propTypes = {
    wrapperClass: string,
    onZoomIn: func,
    onZoomOut: func,
    minZoom: number,
    maxZoom: number
};

export default memo(LocationZoomControl)