import React, { memo, useState } from 'react';
import { element, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../constants/envConfig';
import MapMarker from '../../../resources/images/mapmarker.svg';
import MapSpecialtyMarker from '../../../resources/images/mapspecialtymarker.svg';
import Button from '../../global/atoms/button/button';
import './locationlegend.scss';
import LocationLegendModal from '../locationLegendModal/LocationLegendModal';

const legendLoop = [
    {
        icon: <MapSpecialtyMarker tabIndex={-1} aria-hidden={true} />,
        name: ENV_CONFIG.LLP_LEGEND_SPECIALTY_STORE_TITLE
    },
    {
        icon: <MapMarker tabIndex={-1} aria-hidden={true} />,
        name: ENV_CONFIG.LLP_LEGEND_GT_STORE_TITLE
    }
];

const LocationLegend = props => {
    const { title, mapItems = legendLoop } = props;
    const [isLocationInfoModal, setLocationInfoModal] = useState(false);
    const [legendContent, setLegendContent] = useState({});
    const intl = useIntl();
    const handleLocationInfoModal = name => {
        setLocationInfoModal(true);
        handleLegendContent(name);
    };

    const handleLocationInfoModalClose = () => {
        setLocationInfoModal(false);
    };

    const handleLegendContent = name => {
        switch (name) {
            case ENV_CONFIG.LLP_LEGEND_SPECIALTY_STORE_TITLE:
                return setLegendContent({
                    ...legendContent,
                    title: ENV_CONFIG.LLP_LEGEND_SPECIALTY_STORE_TITLE,
                    icon: <MapSpecialtyMarker tabIndex={-1} aria-hidden={true} />,
                    content: ENV_CONFIG?.LLP_LEGEND_SPECIALTY_STORE_CONTENT,
                    link: ENV_CONFIG?.LLP_LEGEND_SPECIALTY_STORE_URL
                });
            default:
                return setLegendContent({
                    ...legendContent,
                    title: ENV_CONFIG?.LLP_LEGEND_GT_STORE_TITLE,
                    icon: <MapMarker tabIndex={-1} aria-hidden={true} />,
                    content: ENV_CONFIG?.LLP_LEGEND_GT_STORE_CONTENT,
                    link: ENV_CONFIG?.LLP_LEGENDGT_GT_STORE_URL
                });
        }
    };

    const legendModaltitle = () => {
        return (
            <>
                {legendContent?.icon}
                {legendContent?.title}
            </>
        );
    };
    const legendModalContent = () => {
        const modalContent = legendContent?.content;
        return (
            <>
                <div
                    dangerouslySetInnerHTML={{ __html: modalContent }}
                    tabIndex={'0'}
                    className="location-legend-modal__content-in"
                />
                <a href={legendContent?.link} className="location-legend-modal__link" tabIndex={'0'}>
                    {intl.formatMessage({ id: 'common:learn-more' })}
                </a>
            </>
        );
    };
    return (
        <>
            <div className="location__legend">
                {title && (
                    <h6 className="location__legend-title" tabIndex={'0'} data-testid={'locationlegend-title'}>
                        {title}
                    </h6>
                )}
                {mapItems?.map(item => (
                    <Button
                        key={item?.name}
                        className="location__legend-item"
                        tabIndex={'0'}
                        onClick={() => handleLocationInfoModal(item?.name)}
                        buttonAriaLabel={`${intl.formatMessage({ id: 'common-open-text' })} ${
                            item?.name
                        } ${intl.formatMessage({ id: 'common-info-modal-text' })} `}>
                        {item?.icon} <small>{item?.name}</small>
                    </Button>
                ))}
            </div>

            <LocationLegendModal
                isOpen={isLocationInfoModal}
                onRequestClose={handleLocationInfoModalClose}
                title={legendModaltitle()}
                content={legendModalContent()}
                handleClose={handleLocationInfoModalClose}
            />
        </>
    );
};

LocationLegend.defaultProps = {
    title: ''
};

LocationLegend.propTypes = {
    title: string,
    mapItems: shape({
        name: string,
        icon: oneOfType([string, element])
    })
};

export default memo(LocationLegend);
