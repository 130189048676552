import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { func, object } from 'prop-types';
import { useMutation } from '@apollo/client';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { useCheckUser } from '../../hooks/useCheckUser';
import { useCookieValue } from '../../aem-core-components/utils/hooks';
import { isValidString, setSentryScopeforSettings } from '../global/utils/logger';
import { getProjectsFromCookie } from '../global/utils/commonUtils';
import initialFilterState from './initialFilterState';
import filterReducer from './filterReducer';
import {
    getJobsitesAddressSuggestionAction,
    getRecommendedJobSitesAction,
    updateCoachmarkAction,
    updateOldEstimatesAction
} from './action';
import MUTATION_GET_ALL_JOBSITES_AND_AUTOSUGGEST from '../../aem-core-components/queries/mutation_get_jobsites_and_autosuggest.graphql';
import MUTATION_UPDATE_OLD_ESTIMATES from '../../aem-core-components/queries/mutation_update_old_estimates.graphql';
import MUTATION_GET_RECOMMENDED_JOBSITES from '../../aem-core-components/queries/mutation_get_recommended_jobsites.graphql';
import MUTATION_UPDATE_COACH_MARK from '../../aem-core-components/queries/mutation_update_coachmark_count.graphql';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

export const FilterContext = createContext();

export const FilterProvider = props => {
    const [getAllJobsitesAndAddress] = useMutation(MUTATION_GET_ALL_JOBSITES_AND_AUTOSUGGEST);
    const [getRecommendedJobSites] = useMutation(MUTATION_GET_RECOMMENDED_JOBSITES);
    const [updateOldEstimatesMutation] = useMutation(MUTATION_UPDATE_OLD_ESTIMATES);
    const [updateCoachmarkMutation] = useMutation(MUTATION_UPDATE_COACH_MARK);
    const userTypeVal = useCheckUser();
    const [settingsCookie, setSettingsCookie] = useCookieValue('Settings');
    const [{ isProfileLoaded }, { dispatch: userDispatch }] = useUserContext();

    useEffect(() => {
        const capHeroShimmer = document?.querySelector('.cap-hero-shimmer');
        const capHeaderShimmer = document?.querySelector('.cap-header-shimmer');
        const capComponent = document?.querySelector('.cap-container');
        if (isProfileLoaded) {
            if (capComponent?.innerHTML) {
                if (capHeroShimmer) {
                    capHeroShimmer.style.display = 'none';
                }
                if (capHeaderShimmer) {
                    capHeaderShimmer.style.display = 'none';
                }
                capComponent.style.display = 'block';
            }
        }
    }, [isProfileLoaded]);

    const ccCookies = [
        'LoginToken',
        'CurrentWynneAccount',
        'CurrentWynneAccountName',
        'CurrentWynneAccountCID',
        'SLLat',
        'SLLong',
        'SLA',
        'SLC',
        'SLS',
        'SLZ',
        'CurrentJobSite',
        'locationPC',
        'projectName',
        'projectAddress2',
        'primaryContactName',
        'phoneNumber',
        'accessNotes',
        'poNumber',
        'CurrentWynneAccountStatus',
        'IsCorpLink',
        'RMJobsiteId'
    ];

    const setSettingsCookieFunc = (accountVal, projectVal) => {
        if (userTypeVal != USER_TYPE.GUEST) {
            let settingsKey = [];
            let settingData = (settingsCookie && settingsCookie.split('&')) || [];
            let settingCookieData = '';
            for (let i in settingData) {
                let cookieKey = settingData[i].split('=');
                if (ccCookies.indexOf(cookieKey[0]) === -1) {
                    settingsKey.push(settingData[i]);
                }
            }
            settingCookieData = setCCCookies(settingsKey, accountVal, projectVal).join('&');
            setSettingsCookie(settingCookieData);
            setSentryScopeforSettings(settingCookieData);
        } else {
            setSettingsCookie('', 0);
        }
        if (projectVal?.projectName || projectVal?.location) {
            userDispatch({ type: 'setIsUserProfileLoading', value: false });
        }
    };
    const setCCCookies = (settings, accountVal, projectVal) => {
        let settingsKeyUpdated = [...settings];
        const NewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        const projectInfoCookies = getProjectsFromCookie();
        let companyId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || 1;
        settingsKeyUpdated.push(
            `IsCorpLink=${encodeURIComponent(
                accountVal?.isCorpLinkAccount || isValidString(projectInfoCookies?.IsCorpLink)
            )}`
        );
        settingsKeyUpdated.push(`LoginToken=${projectInfoCookies?.LoginToken || ''}`);
        settingsKeyUpdated.push(
            `CurrentWynneAccount=${encodeURIComponent(
                accountVal?.accountNumber || projectInfoCookies?.CurrentWynneAccount || ''
            )}`
        );
        settingsKeyUpdated.push(
            `CurrentWynneAccountName=${encodeURIComponent(
                accountVal?.accountName || projectInfoCookies?.CurrentWynneAccountName || ''
            )}`
        );
        settingsKeyUpdated.push(
            `CurrentWynneAccountStatus=${encodeURIComponent(
                accountVal?.accountStatus || projectInfoCookies?.CurrentWynneAccountStatus || ''
            )}`
        );
        settingsKeyUpdated.push(`CurrentWynneAccountCID=${companyId}`);
        if ((projectVal?.selectedProjectJobId || projectInfoCookies?.CurrentJobSite) && !NewAddress) {
            settingsKeyUpdated.push(
                `CurrentJobSite=${encodeURIComponent(
                    projectVal?.selectedProjectJobId || projectInfoCookies?.CurrentJobSite || ''
                )}`
            );
            settingsKeyUpdated.push(
                `locationPC=${encodeURIComponent(projectVal?.locationPC || projectInfoCookies?.locationPC || '')}`
            );
            settingsKeyUpdated.push(
                `projectName=${encodeURIComponent(projectVal?.projectName || projectInfoCookies?.projectName || '')}`
            );
            settingsKeyUpdated.push(
                `projectAddress2=${encodeURIComponent(
                    projectVal?.projectAddress2 || projectInfoCookies?.projectAddress2 || ''
                )}`
            );
            settingsKeyUpdated.push(
                `primaryContactName=${encodeURIComponent(
                    projectVal?.primaryContactName || projectInfoCookies?.primaryContactName || ''
                )}`
            );
            settingsKeyUpdated.push(
                `phoneNumber=${encodeURIComponent(projectVal?.phoneNumber || projectInfoCookies?.phoneNumber || '')}`
            );
            settingsKeyUpdated.push(
                `accessNotes=${encodeURIComponent(projectVal?.accessNotes || projectInfoCookies?.accessNotes || '')}`
            );
            settingsKeyUpdated.push(
                `poNumber=${encodeURIComponent(projectVal?.poNumber || projectInfoCookies?.poNumber || '')}`
            );
        }
        settingsKeyUpdated.push(
            `SLLat=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedProjectLatititude : projectVal?.lat) ||
                    projectInfoCookies?.SLLat ||
                    ''
            )}`
        );
        settingsKeyUpdated.push(
            `SLLong=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedProjectLongitude : projectVal?.long) ||
                    projectInfoCookies?.SLLong ||
                    ''
            )}`
        );
        settingsKeyUpdated.push(
            `SLA=${encodeURIComponent(
                (!NewAddress ? projectVal?.projectAddress1 : projectVal?.location) || projectInfoCookies?.SLA || ''
            )}`
        );
        settingsKeyUpdated.push(
            `SLC=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedProjectCity : projectVal?.jobSiteCity) ||
                    projectInfoCookies?.SLC ||
                    ''
            )}`
        );
        settingsKeyUpdated.push(
            `SLS=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedProjectState : projectVal?.jobSiteState) ||
                    projectInfoCookies?.SLS ||
                    ''
            )}`
        );
        settingsKeyUpdated.push(
            `SLZ=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedProjectZip : projectVal?.jobSiteZip) || projectInfoCookies?.SLZ || ''
            )}`
        );
        settingsKeyUpdated.push(
            `RMJobsiteId=${encodeURIComponent(
                (!NewAddress ? projectVal?.selectedRMJobId : projectVal?.rmJobsiteId) ||
                    projectInfoCookies?.RMJobsiteId ||
                    ''
            )}`
        );
        return settingsKeyUpdated;
    };

    const initialState = props?.initialState || initialFilterState;
    const reducer = props?.reducer || filterReducer;
    const [state, dispatch] = useReducer(reducer(setSettingsCookieFunc), initialState);

    const getJobsitesAddressSuggestion = async (
        accountId,
        search,
        searchString,
        isLocationAvailable = false,
        location,
        radius,
        limit = 6,
        includeActiveEquipment = 'false',
        invalidateCache = false
    ) => {
        await getJobsitesAddressSuggestionAction({
            getAllJobsitesAndAddress,
            accountId,
            search,
            searchString,
            isLocationAvailable,
            location,
            radius,
            limit,
            includeActiveEquipment,
            invalidateCache,
            dispatch
        });
    };

    const getJobSiteRecommendations = async (accountId, recommended = 'true') => {
        await getRecommendedJobSitesAction({ getRecommendedJobSites, accountId, recommended, dispatch });
    };

    const updateOldEstimates = cartId => {
        updateOldEstimatesAction({ updateOldEstimatesMutation, cartId, dispatch });
    };

    const updateCoachmark = ({ pickstore, location, producttile }) => {
        updateCoachmarkAction({ updateCoachmarkMutation, pickstore, location, producttile, dispatch });
    };

    const contextValue = [
        state,
        dispatch,
        {
            getJobsitesAddressSuggestion,
            setSettingsCookieFunc,
            getJobSiteRecommendations,
            updateOldEstimates,
            updateCoachmark
        }
    ];

    return <FilterContext.Provider value={contextValue}>{props?.children}</FilterContext.Provider>;
};

FilterProvider.propTypes = {
    reducer: func,
    initialState: object
};

export const useFilterState = () => useContext(FilterContext);
