import React from 'react';
import { useIntl } from 'react-intl';
import ChevronRight from '../../resources/images/chevron-right-green.svg';
import Button from '../global/atoms/button/button';
import { locationDataLocators } from './dataLocators';
import useLLP from './hooks/useLLP';
import { useFilterState } from '../cap';

const LocationCard = ({
    item,
    setSelectedStoreValue,
    selectedStoreValue,
    cardRef,
    setZoomOutMax,
    setFocusedLocation,
    openHours,
    userAddress,
    handleButtonClick
}) => {
    const { getStoreName } = useLLP();
    const intl = useIntl();

    const onStoreClick = () => {
        setZoomOutMax(false);
        setFocusedLocation({ lat: item?.latitude, long: item?.longitude });
        setSelectedStoreValue(item?.pc);
        handleButtonClick(item);
    };

    const distanceText = () => {
        const companyID = parseInt(localStorage.getItem('companyID')) || 1;
        if (companyID === 2) {
            return intl.formatMessage({ id: 'location:km-text' });
        }
        return intl.formatMessage({ id: 'location:miles-text' });
    };
    const isDistanceVisible = distance => {
        return distance && distance !== -1;
    };
    const getCardClassName = () => {
        let cardClass = 'location__card';
        if (selectedStoreValue === item?.pc) {
            cardClass += ' location__card-selected';
        }
        return cardClass;
    };

    return (
        <>
            <div
                className={`${getCardClassName()}`}
                onClick={onStoreClick}
                ref={cardRef}
                data-testid={locationDataLocators.location_landing_card}>
                <div className="location__card-top">
                    <h3 className="location__card-title" data-testid={locationDataLocators.location_landing_store_name}>
                        {getStoreName(item)}
                    </h3>
                    {isDistanceVisible(item?.distance) && (
                        <small className="location__card-distance">
                            {Number(item?.distance).toFixed()} {distanceText()}
                        </small>
                    )}
                </div>
                <div className="location__card-datetime">
                    <small
                        className={`location__card-date ${
                            openHours ? 'location__card-date-open' : 'location__card-date-closed'
                        }`}
                        data-testid={locationDataLocators.location_landing_static_text}>
                        {openHours
                            ? intl.formatMessage({ id: 'location:open-static-text' })
                            : intl.formatMessage({ id: 'location:closed-static-text' })}
                    </small>
                    {openHours && (
                        <div className="location__card-hours" data-testid="checkout_storeOpenHours_label">
                            {openHours}
                        </div>
                    )}
                </div>
                <div className="location__card-bottom">
                    <address
                        className="location__card-address"
                        data-testid={locationDataLocators.location_landing_address}>
                        <span>{`${intl.formatMessage({ id: 'location-card:branch-text' })}${item?.pc}`}</span>
                        <span className="location__card-street">{`${item?.street?.toLowerCase()},`}</span>
                        <span>{`${item?.city?.toLowerCase()}, ${item?.state} ${item?.zip}`}</span>
                    </address>
                    <Button>
                        <ChevronRight />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LocationCard;
