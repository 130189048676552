import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { isValidString } from '../../global/utils/logger';
import { getStoreDistances } from '../api/getDstTrainerAPIs';

const useDst = () => {
    const intl = useIntl();
    const getTrainerSpecialtyObj = () => {
        let specialtyString = ENV_CONFIG.DST_TAG_NAMES;
        let specialtyArray = [];
        let specialtyObj = {};
        if (specialtyString?.length > 2) {
            specialtyString = specialtyString?.substring(1, specialtyString.length - 1);
            specialtyArray = specialtyString?.split(',');

            specialtyArray.forEach(element => {
                const objArr = element.split('=');
                const specialtyKey = objArr[0]?.trim();
                const specialityValue = objArr[1]?.trim();
                specialtyObj[specialtyKey] = specialityValue;
            });
        }
        return specialtyString?.length > 2 ? specialtyObj : {};
    };

    const getDistance = async (lat, long, isCurrentLocationAllowed, currentLocation, userLatLong) => {
        const data = await getStoreDistances(
            {
                lat: isCurrentLocationAllowed ? currentLocation?.lat : userLatLong?.lat,
                long: isCurrentLocationAllowed ? currentLocation?.long : userLatLong?.long
            },
            { lat: lat, long: long }
        );

        const storeDistance = data?.data?.length > 0 ? data?.data[0]?.distance : '';
        return isValidString(storeDistance) ? storeDistance : '';
    };

    const getDistanceText = () => {
        const companyID = parseInt(localStorage.getItem('companyID')) || 1;
        if (companyID === 2) {
            return intl.formatMessage({ id: 'location:km-text' });
        }
        return intl.formatMessage({ id: 'location:miles-text' });
    };
    return {
        getTrainerSpecialtyObj,
        getDistance,
        getDistanceText
    };
};
export default useDst;
