import React, { memo } from 'react';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import useMedia from '../../../../hooks/useMedia';
import MapPinIcon from '../../../../resources/images/map-pin-general.png';
import MapPinIconSelected from '../../../../resources/images/map-pin-selected-general.png';
import MapPinSpecialtyIconSelected from '../../../../resources/images/map-pins-specialty-location-selected.png';
import MapPinSpecialtyIcon from '../../../../resources/images/map-pins-specialty-location.png';
import MapPinIconMobile from '../../../../resources/images/map-pin-general-mobile.png';
import MapPinIconSelectedMobile from '../../../../resources/images/map-pin-selected-general-mobile.png';
import MapPinSpecialtyIconSelectedMobile from '../../../../resources/images/map-pins-specialty-location-selected-mobile.png';
import MapPinSpecialtyIconMobile from '../../../../resources/images/map-pins-specialty-location-mobile.png';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../constants';
import { locationDataLocators } from '../../dataLocators';
import './../storesLocationMap.scss';

const LocationMapMarker = props => {
    const {
        pc,
        itemData,
        onClickMarker,
        setFocusedLocation,
        selectedStoreValue,
        specialty,
        isLlpModalOpen,
        isSearchByBranch
    } = props;
    const mediaType = useMedia();
    const onMarkerClick = () => {
        onClickMarker(itemData);
        setFocusedLocation({ lat: itemData?.latitude, long: itemData?.longitude });
    };

    const isPinSelected = () => {
        if (pc == selectedStoreValue && (isLlpModalOpen || isSearchByBranch)) {
            return true;
        }
        return false;
    };
    const isGeneralEquip = () => {
        return !specialty || specialty == GENERAL_EQUIPMENT_AND_TOOLS;
    };
    const isMobileView = mediaType == MEDIA_TYPE.MOBILE;

    const getPinType = () => {
        if (isPinSelected()) {
            if (isGeneralEquip()) {
                return isMobileView ? MapPinIconSelectedMobile : MapPinIconSelected;
            }
            return isMobileView ? MapPinSpecialtyIconSelectedMobile : MapPinSpecialtyIconSelected;
        } else {
            if (isGeneralEquip()) {
                return isMobileView ? MapPinIconMobile : MapPinIcon;
            }
            return isMobileView ? MapPinSpecialtyIconMobile : MapPinSpecialtyIcon;
        }
    };

    return (
        <>
            <img
                alt="Pin"
                src={getPinType()}
                className={isPinSelected() ? 'mapIcon selectedMapIcon' : 'mapIcon'}
                onClick={onMarkerClick}
                data-testid={locationDataLocators.location_map_marker}
            />
        </>
    );
};

LocationMapMarker.propTypes = {
    pc: number,
    itemData: object,
    onClickMarker: func,
    setFocusedLocation: func,
    selectedStoreValue: oneOfType([string, number]),
    specialty: string,
    isLlpModalOpen: bool,
    isSearchByBranch: bool
};

LocationMapMarker.defaultProps = {
    pc: null,
    itemData: {},
    onClickMarker: () => {},
    setFocusedLocation: () => {},
    selectedStoreValue: '',
    specialty: '',
    isLlpModalOpen: false,
    isSearchByBranch: false
};
export default memo(LocationMapMarker);
