import { callGtm } from "../../GTM/";
document.addEventListener('readystatechange', event => {
	if (document.readyState === "complete") {
		const FooterDate = document.querySelector("#footer-date");
		if(FooterDate){
		   FooterDate.innerHTML = new Date().getFullYear();
		}
	}
})


 var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
 if(isGTMHidden === "false"){
	var footerApp = document.querySelectorAll('.footer__title .footer__title');
	footerApp?.forEach((footerapp) => {
		footerapp?.addEventListener('click', function (e) {
			var eventAction = e.target.closest('.footer__main-col').querySelector(".footer__title").innerText.toLowerCase();
			var eventLabel = footerapp.innerText.toLowerCase();
			callGtm('uaevent', 'undefined', 'footer', eventAction, eventLabel);
		});
	});

	var footerApp = document.querySelectorAll('.footer__link');
	footerApp?.forEach((footerapp) => {
		footerapp?.addEventListener('click', function (e) {
			var eventAction = e.target.closest('.footer__main-col').querySelector(".footer__title").innerText.toLowerCase();
			var eventLabel = footerapp.innerText.toLowerCase();
			callGtm('uaevent', 'undefined', 'footer', eventAction, eventLabel);
		});
	});

	var footerApp = document.querySelectorAll('.footer__download-link img');
	footerApp?.forEach((footerapp) => {
		footerapp?.addEventListener('click', function (e) {
			var eventLabel = footerapp.getAttribute('alt');
			callGtm('uaevent', 'undefined', 'app download', 'footer click', eventLabel);
		});
	});

	var footerSocial = document.querySelectorAll('.footer__social-link');
	footerSocial?.forEach((footersocial) => {
		footersocial?.addEventListener('click', function (e) {
			var eventAction = e.target.getAttribute('alt');
			var eventLabel = e.target.closest('.footer__social-link').getAttribute('href');
			callGtm('socialInt', 'undefined', 'social', eventAction, eventLabel);
		});
	});

	var footerNumber = document.querySelectorAll('.globalFooter .footer__top-link');
	footerNumber?.forEach((footernumber) => {
		footernumber?.addEventListener('click', function (e) {
			var eventLabel = e.target.closest('.footer__top-link').innerText;
			callGtm('uaevent', 'undefined', 'footer', 'select', eventLabel);
		});
	});
 }