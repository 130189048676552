import { array, bool, func, number } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LoadingIndicator } from '../../../../aem-core-components';
import './LongList.scss';

const LongList = props => {
    const { isLongListLoading, longList, rowRendererContent, defaultHeight, selectedValue } = props;
    const listRef = useRef(null);

    useEffect(() => {
        if (selectedValue && listRef?.current) {
            listRef?.current?.resetAfterIndex(0);
        }
    }, [selectedValue, listRef?.current, longList])

    const rowRendererHandler = ({ index, style }) => {
        return (
            <div key={index} style={style} className="list-row-wrapper">
                {rowRendererContent(longList[index])}
            </div>
        );
    };

    const getSize = index => {
        if (selectedValue === longList[index]?.id) {
            return defaultHeight + 32;
        }
        return defaultHeight;
    };

    return (
        <>
            {isLongListLoading ? (
                <div className="longlist-loader">
                    <LoadingIndicator />
                </div>
            ) : (
                longList?.length > 0 && (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={listRef}
                                width={width}
                                height={height}
                                className="list-container"
                                itemSize={getSize}
                                itemCount={longList?.length}>
                                {rowRendererHandler}
                            </List>
                        )}
                    </AutoSizer>
                )
            )}
        </>
    );
};

LongList.propTypes = {
    isLongListLoading: bool,
    longList: array,
    rowRendererContent: func,
    defaultHeight: number,
    selectedValue: number
};

LongList.defaultProps = {
    isLongListLoading: false,
    longList: [],
    rowRendererContent: () => {},
    defaultHeight: 0,
    selectedValue: null
};

export default React.memo(LongList);
