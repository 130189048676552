import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import ArrowIosLeft from '../../../resources/images/arrow-ios-left.svg';
import PhoneGreen from '../../../resources/images/phonegreen.svg';
import MapMarkerBorderGreen from '../../../resources/images/pin-border-green.svg';
import config from '../../App/config';
import { getDirectionCTA } from '../../checkout/howToGetYourOrder/utils/editModuleFunctions';
import Button from '../../global/atoms/button';
import GlobalModal from '../../global/atoms/globalModal/GlobalModal';
import useLLP from '../hooks/useLLP';
import useMedia from '../../../hooks/useMedia';
import { MEDIA_TYPE } from '../../global/constants';
import './locationdetaildrawer.scss';

import moment from 'moment';
import { locationDetailDrawerDataLocators } from './locationDetailDrawerDataLocators';
import { useLocationState } from '../../../contexts/location/locationContext';

const LocationDetailDrawer = props => {
    const { isOpen, onRequestClose, modalData, handleBackButton, userAddress } = props;
    const dow = moment().day();
    const mediaType = useMedia();
    const {
        formatSavedStoreDetailsData,
        formatUrl,
        getDynamicURL,
        getStoreNameFromFilterState,
        getTwoNearbyStores,
        getStoreName,
        isDistanceVisible,
        distanceText
    } = useLLP();
    const [{ viewCart, userInfo, projectDetails }] = useCartState();
    const [{ storeLocations }] = useLocationState();
    const intl = useIntl();
    const [operatingHours, setOperatingHours] = useState([]);
    let phoneNo = modalData?.phone;
    if (phoneNo) {
        phoneNo = phoneNo.substr(1, 3) + '-' + phoneNo.substr(6, 8);
    }
    const hrefphone = `tel:${phoneNo}`;

    useEffect(() => {
        let operatingHoursData = modalData?.operatingHours;

        setOperatingHours([
            ...operatingHoursData?.map(operatingDay => ({ day: operatingDay?.day, hours: operatingDay?.hours }))
        ]);
    }, []);

    const getInitialPath = () =>{
        if(modalData?.companyId == 4){
            return config?.pagePaths?.storeDetailsFTV
        }
        else if(modalData?.companyId === 2){
            return config?.pagePaths?.storeDetailsCA
        }
        return config?.pagePaths?.storeDetailsUS;
    }
    const createURL = () => {
        let initalPath = getInitialPath();
        let speciality = '';

        if (modalData?.pc >= 5000 && modalData?.pc <= 5099) {
            speciality = getDynamicURL(intl.formatMessage({ id: 'location-card:sunbelt-rentals-tool-rentalText' }));
        } else if (modalData?.specialties && modalData?.specialties?.length > 0) {
            speciality = getDynamicURL(modalData?.specialties?.[0]);
        } else {
            speciality = getDynamicURL(intl.formatMessage({ id: 'location-card:general-equipText' }));
        }
        let details =
            initalPath +
            `${formatUrl(modalData?.state?.toLowerCase())}/${formatUrl(modalData?.city?.toLowerCase())}/${formatUrl(
                speciality
            )}/${modalData?.pc}/`;
        if (!details) {
            return '#';
        }
        return details;
    };
    const onStoreDetailsClick = () => {
        localStorage.setItem('selectedStoreDetails', JSON.stringify(formatSavedStoreDetailsData(modalData, dow)));
        localStorage.setItem(
            'nearbyStores',
            JSON.stringify(getTwoNearbyStores(modalData?.pc, modalData?.distance, storeLocations, dow))
        );
        localStorage.setItem('startingLocationForGetDirections', userAddress);
    };

    const onClickGetDirections = () => {
        getDirectionCTA(modalData, {}, viewCart, userInfo, projectDetails, true, userAddress);
    };

    const locationDetailDrawerTitle = () => {
        return (
            <div className="location-side-drawer__title">
                <Button
                    dataTestid={locationDetailDrawerDataLocators.location_Detail_Drawer_backcta}
                    className={'location-side-drawer__backbutton'}
                    tabIndex={0}
                    onClick={handleBackButton}
                    buttonAriaLabel={intl.formatMessage({ id: 'location:drawer-close-text' })}>
                    <ArrowIosLeft tabIndex={'-1'} aria-hidden={true} />
                    <span>Back</span>
                </Button>
            </div>
        );
    };

    const renderLlpModalContent = item => {
        return (
            <>
                <div className="location-side-drawer__content-top">
                    <h4
                        className="location-side-drawer__content-title"
                        tabIndex={'0'}
                        data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_heading_testid}>
                        {getStoreName(modalData)}
                    </h4>
                    <div className="location-side-drawer__content-address">
                        <address data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_address}>
                            <span
                                data-testid={
                                    locationDetailDrawerDataLocators.location_Detail_Drawer_branchid
                                }>{`${intl.formatMessage({ id: 'location-card:branch-text' })} ${item?.pc}`}</span>
                            <span className="location__card-street">{`${item?.street?.toLowerCase()},`}</span>
                            <span>{`${item?.city?.toLowerCase()}, ${item?.state} ${item?.zip}`}</span>
                        </address>
                        {isDistanceVisible(item?.distance) && (
                            <span
                                className="location-side-drawer__content-distance"
                                data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_distance}>
                                {Number(item?.distance).toFixed()} {distanceText()}
                            </span>
                        )}
                    </div>
                    <div className="location-side-drawer__content-actions">
                        <a
                            href={hrefphone}
                            className="location-side-drawer__content-action"
                            data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_phonenumber}>
                            <PhoneGreen tabIndex={'-1'} aria-hidden={true} /> {phoneNo}
                        </a>
                        <a
                            className="location-side-drawer__content-action"
                            href={`mailto:${item?.email}`}
                            data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_email}
                            target="_blank">
                            {item?.email?.toLowerCase()}
                        </a>
                    </div>
                    <Button
                        className="button button-outline-primary button-block"
                        onClick={onClickGetDirections}
                        buttonAriaLabel={intl.formatMessage({ id: 'common-get-directions-text' })}
                        dataTestid={locationDetailDrawerDataLocators.location_Detail_Drawer_getdirection_cta}>
                        <MapMarkerBorderGreen tabIndex={'-1'} aria-hidden={true} />
                        {intl.formatMessage({ id: 'common-get-directions-text' })}
                    </Button>
                </div>

                <div className="location-side-drawer__openhours">
                    <h4 className="location-side-drawer__openhours-title">
                        {intl.formatMessage({ id: 'common-opening-hours-title' })}
                    </h4>
                    <div className="location-side-drawer__weekdays">
                        {operatingHours?.map(weekDay => {
                            let hours = weekDay?.hours;
                            return (
                                <div
                                    className="location-side-drawer__weekday"
                                    key={`${weekDay}`}
                                    data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_openinghours}>
                                    <span>{weekDay?.day}</span>
                                    <span>{hours || '-'}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="location-side-drawer__footer">
                    <a
                        aria-label={`${intl.formatMessage({
                            id: 'account:ac-common-click-here-msg'
                        })} ${intl.formatMessage({ id: 'common-view-more-details' })}`}
                        className="button button-block"
                        onClick={onStoreDetailsClick}
                        href={createURL()}
                        data-testid={locationDetailDrawerDataLocators.location_Detail_Drawer_storedetails}
                        target="_blank">
                        {intl.formatMessage({ id: 'common-view-more-details' })}
                    </a>
                </div>
            </>
        );
    };

    return (
        <>
            {mediaType === MEDIA_TYPE.desktop ? (
                <>
                    {locationDetailDrawerTitle()}
                    {renderLlpModalContent(modalData)}
                </>
            ) : (
                <GlobalModal
                    settitleFocus={false}
                    titleClasses={''}
                    isOpen={isOpen}
                    sideDrawer={'left'}
                    portalClassName={'location-side-drawer'}
                    customTitle={locationDetailDrawerTitle()}
                    showCloseButton={false}
                    onRequestClose={handleBackButton}
                    content={renderLlpModalContent(modalData)}
                    className="location-side-drawer__content"
                    contentClasses={'location-side-drawer__content-wrap'}
                />
            )}
        </>
    );
};

export default memo(LocationDetailDrawer);
